<template>
  <div>
    <v-text-field
      v-if="items == null"
      ref="text-field"
      v-model="text"
      outlined
      :disabled="disabled"
      hide-details
      height="60"
      validate-on-blur
      :name="name"
      :autofocus="autofocus != null"
      :rules="rules"
      :type="type"
      :label="label"
      :prefix="prefix"
      :placeholder="placeholder"
      :error="errors != null && errors.length > 0"
      :class="{
        'v-text-field--outlined-light-border': lightGreyBorder != null,
        'v-text-field--outlined-rounded': rounded != null,
        'v-text-field--outlined-error-border': errors != null && errors.length > 0
      }"
      @input="setText($event)"
    >
      <template v-slot:append>
        <div v-if="optional != null" class="optional">
          Optional
        </div>
      </template>
    </v-text-field>
    <v-autocomplete
      v-else
      ref="text-field"
      v-model="text"
      outlined
      :disabled="disabled"
      hide-details
      height="58"
      validate-on-blur
      :autofocus="autofocus != null"
      :rules="rules"
      :label="label"
      :prefix="prefix"
      :placeholder="placeholder"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :error="errors != null && errors.length > 0"
      :class="{
        'v-text-field--outlined-light-border': lightGreyBorder != null,
        'v-text-field--outlined-rounded': rounded != null,
      }"
      :filter="getList"
      @input="setText($event)"
    >
      <template v-slot:append>
        <div v-if="optional != null" class="optional">
          Optional
        </div>
      </template>
    </v-autocomplete>
    <div v-if="details != null" class="details">
      {{ details }}
    </div>
    <div v-if="errors != null" class="errors">
      {{ errors.join(", ") }}
    </div>
  </div>
</template>

<script> //~~legacy~~
const moment = require("moment");

export default {
  props: [
    "name",
    "value",
    "label",
    "prefix",
    "details",
    "errors",
    "placeholder",
    "items",
    "itemText",
    "itemValue",
    "autofocus",
    "format",
    "required",
    "optional",
    "disabled",
    "validateDate",
    "type",
    "moreRules",
    "lightGreyBorder",
    "rounded"
  ],
  data() {
    return {
      text: this.value
    };
  },
  computed: {
    rules() {
      if (this.moreRules) {
        var rules = this.moreRules;
      } else {
        var rules = [];
      }

      if (this.required != null) {
        const rule = v => (v || "").length > 0 || `Required`;

        rules.push(rule);
      }

      if (this.validateDate != null) {
        const rule = v =>
          v == null ||
          v == "" ||
          moment(v, "DD.MM.YYYY", true).isValid() ||
          `Invalide date`;

        rules.push(rule);
      }

      return rules;
    }
  },
  mounted() {
    var newVal = this.value;
    if (
      this.format != null &&
      this.format == "date" &&
      newVal != null &&
      newVal.length == 10
    ) {
      try {
        newVal = this.formatDate(newVal);
      } catch {}
    }
    this.setText(newVal);
  },
  methods: {
    getList(item, queryText, itemText) {
      return itemText
        .toLocaleLowerCase()
        .startsWith(queryText.toLocaleLowerCase());
    },
    formatDate(date) {
      if (date == null) {
        return "";
      }

      return moment(date).format("DD.MM.YYYY");
    },
    setText(val) {
      if (val == null) {
        return "";
      }

      switch (this.format) {
        case "date":
          var newVal = val
            .split(".")
            .join("")
            .split("-")
            .join("");
          if (newVal.length > 2) {
            newVal = [newVal.slice(0, 2), ".", newVal.slice(2)].join("");
          }
          if (newVal.length > 5) {
            newVal = [newVal.slice(0, 5), ".", newVal.slice(5)].join("");
          }
          this.text = newVal;
          break;
        case "float":
          var newVal = val.split(".");
          if (newVal.length > 1) {
            this.text = `${newVal[0]}.${newVal[1].substring(0, 2)}`;
          }
          break;
        default:
          break;
      }

      this.$refs["text-field"].lazyValue = this.text;
      this.$emit("input", this.text);
      this.$emit("change", this.text);
    }
  }
};
</script>

<style lang="scss" scoped>
.optional {
  margin-top: 4px;
  font-family: "Helvetica Neue";
  font-weight: 300;
  font-size: 16px;
  color: #444444;
}

.details,
.errors {
  font-size: 15px;
  font-weight: normal;
  margin-top: 12px;
  font-family: "Helvetica Neue";
  font-size: 14px;
}

.errors {
  color: #92000d;
}
</style>
