import { Component } from 'vue';

// in milliseconds
export const DEFAULT_NOTIFICATION_HIDE_DELAY = 5900; // 5000 + animation period

export enum NotificationPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  MODAL = 'modal'
}

export enum NotificationTypes {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error'
}

export interface NotificationsOptions {
  // usually internal as a random number: an id provided for identifying, may be used to operate notification from outside, for example, close it immediately when link inside is clicked
  id?: number;
  message?: string;
  multipleLinesMessage?: boolean;
  separator?: string;
  // Notification can show a custom component as a message. The message string is passed into the default slot.
  // There is no other way to define links (for example, as HTML), because
  // error messages may come from the API, and it brings a security risk to use them straight string as HTML.
  // Also, the component way allows use of router links that are commonly used for links.
  component?: Component;
  componentProps?: Record<string, any>;
  // NotificationTypes.INFO by default
  type?: NotificationTypes;
  // In milliseconds, default 4000 - for how long the toast stays on the screen. 0 means it does not hide until user closes it.
  hideDelay?: number;
  // Position in top or bottom, default NotificationPosition.BOTTOM for INFO and WARNING types, and TOP for ERROR type.
  // For multiple notifications appearing at once, these will have separate queues
  position?: NotificationPosition;
  // default true, whether to show the close button (this option exists for the case when closing implemented by message component)
  showClose?: boolean;
  // show without animation if true
  noAnimation?: boolean;
  // internal field to track hiding timer
  hideTimeoutId?: NodeJS.Timeout;
  // priority in the list, default 1 for normal, 2, for WARNING and 3 for ERROR
  priority?: number;
  // a name to refer the modal popup which this toast is bound to (name property value of the Modal)
  modalName?: string;
}

export const DEFAULT_PRIORITIES = {
  [NotificationTypes.INFO]: 1,
  [NotificationTypes.WARNING]: 2,
  [NotificationTypes.ERROR]: 3
};
