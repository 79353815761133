import { Commit, Dispatch } from 'vuex';
import { NotificationPosition, NotificationsOptions } from '~/utils/notificationsConstants';

interface NotificationsState {
  topNotifications: Record<string, NotificationOptions>;
  bottomNotifications: Record<string, NotificationOptions>;
  modalNotifications: Record<string, NotificationOptions>;
  noAnimation?: boolean;
  muted?: boolean;
}
enum NotificationMutations {
  ADD_NOTIFICATION = 'add_notification',
  HIDE_NOTIFICATION = 'hide_notification',
  HIDE_LAST_NOTIFICATION = 'hide_last_notification',
  SET_MUTED = 'set_muted'
}
interface RequiredIdNotificationsOptions extends NotificationsOptions {
  id: number;
}
const current_state: NotificationsState = {
  topNotifications: {},
  bottomNotifications: {},
  modalNotifications: {},
  noAnimation: false,
  muted: false
};

const mutations = {
  [NotificationMutations.ADD_NOTIFICATION]: (state: NotificationsState, options: RequiredIdNotificationsOptions) => {
    const notificationsSet =
      (options.modalName && 'modalNotifications') ||
      (options.position === NotificationPosition.TOP ? 'topNotifications' : 'bottomNotifications');
    state[notificationsSet] = {
      ...state[notificationsSet],
      [options.id]: options
    };
    state.noAnimation = options.noAnimation;
  },
  [NotificationMutations.HIDE_NOTIFICATION]: (
    state: NotificationsState,
    { id, noAnimation }: RequiredIdNotificationsOptions
  ) => {
    const topNotifications = state.topNotifications;
    delete topNotifications[id];
    // need to do below precisely this way to make it reactive
    state.topNotifications = {
      ...topNotifications
    };
    const bottomNotifications = state.bottomNotifications;
    delete bottomNotifications[id];
    state.bottomNotifications = {
      ...bottomNotifications
    };
    const modalNotifications = state.modalNotifications;
    delete modalNotifications[id];
    state.modalNotifications = {
      ...modalNotifications
    };
    state.noAnimation = noAnimation;
  },
  [NotificationMutations.HIDE_LAST_NOTIFICATION]: (state: NotificationsState) => {
    // currently works only for bottom notifications to suppress specific API errors if they are handled
    const bottomNotifications = state.bottomNotifications;
    const bottomLength = Object.keys(bottomNotifications).length;
    if (bottomLength) {
      const id = Object.keys(bottomNotifications)[bottomLength - 1];
      delete bottomNotifications[id];
      state.bottomNotifications = {
        ...bottomNotifications
      };
    }
  },
  [NotificationMutations.SET_MUTED]: (state: NotificationsState, value: boolean) => (state.muted = value)
};

const actions = {
  addNotification({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, options: NotificationsOptions) {
    if (!options.id) {
      console.error('Error: notifications must have id!');
      return;
    }
    commit(NotificationMutations.ADD_NOTIFICATION, {
      ...options,
      hideTimeoutId: options.hideDelay
        ? setTimeout(() => dispatch('hideNotification', { id: options.id }), options.hideDelay)
        : undefined
    });
  },
  hideNotification({ commit }: { commit: Commit }, params: NotificationsOptions) {
    commit(NotificationMutations.HIDE_NOTIFICATION, params);
  },
  hideLastNotification({ commit }: { commit: Commit }) {
    commit(NotificationMutations.HIDE_LAST_NOTIFICATION);
  }
};

export default {
  namespaced: true,
  state: current_state,
  mutations,
  actions
};
