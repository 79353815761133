//~~legacy~~
import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        accent: '#404040',
        primary: '#002FA7',
        error: '#92000D'
      }
    }
  }
});
