<template>
  <transition-group
    :name="(skipAnimation && 'none') || (isTop ? 'toasts-top' : 'toasts')"
    tag="div"
    class="toast-index pointer-events-none fixed flex w-full flex-col items-center gap-y-2xs"
    :class="{
      'top-toasts': isTop && !modalName,
      'bottom-md': !isTop && !hasFooter,
      'bottom-toasts': !isTop && hasFooter && !modalName
    }"
  >
    <component
      :is="toastComponent"
      v-for="options in notifications"
      :key="options.id"
      :options="options"
      class="margin-bottom"
    />
  </transition-group>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';

import { NotificationsOptions } from '~/utils/notificationsConstants';
import { isWebApplication } from '~/utils/application';
import ToastNotification from './ToastNotifications/ToastNotification.vue';
import WebToastNotification from './ToastNotifications/WebToastNotification.vue';

// Cyclic import dependencies resolving via dynamic import
const useStore = ref<Function>();
import('@/store').then((res) => {
  useStore.value = res.default;
});

const props = defineProps<{
  isTop?: boolean;
  hasFooter?: boolean;
  isModal?: boolean;
  modalName?: string;
}>();

const store = computed(() => (useStore.value ? useStore.value() : {}));
const toastComponent = computed(() => (isWebApplication() ? WebToastNotification : ToastNotification));
const sortFunction = computed(() =>
  props.isTop
    ? (a: NotificationsOptions, b: NotificationsOptions) => (a.priority || 0) - (b.priority || 0)
    : (a: NotificationsOptions, b: NotificationsOptions) => (b.priority || 0) - (a.priority || 0)
);

const notificationsSource = computed(() => {
  if (props.isModal || props.modalName) {
    return Object.values(
      (store.value.state?.notifications.modalNotifications as Record<number, NotificationsOptions>) || {}
    ).filter((notification: NotificationsOptions) => notification.modalName === props.modalName);
  }
  return Object.values(
    (props.isTop
      ? (store.value.state?.notifications.topNotifications as Record<number, NotificationsOptions>)
      : (store.value.state?.notifications.bottomNotifications as Record<number, NotificationsOptions>)) || {}
  );
});

const skipAnimation = computed(() => store.value.state?.notifications.noAnimation);

const notifications = computed(() => notificationsSource.value.concat().sort(sortFunction.value));
</script>
<style lang="scss">
.top-toasts {
  /* $headerHeight + 30px */
  /* TODO: define $headerHeight for POS */
  top: 80px;
}
.bottom-toasts {
  /* $footerHeight + 30px */
  /* TODO: define $footerHeight for POS */
  bottom: 80px;
}
.toasts-move,
.toasts-enter-active,
.toasts-leave-active,
.toasts-top-move,
.toasts-top-enter-active,
.toasts-top-leave-active {
  /* update DEFAULT_NOTIFICATION_HIDE_DELAY when changing animation duration */
  transition: all 0.9s;
}
.toasts-enter,
.toasts-leave-to {
  opacity: 0;
  transform: translateY(22px);
}
.toasts-top-enter,
.toasts-top-leave-to {
  opacity: 0;
  transform: translateY(-22px);
}
.toasts-leave-active,
.toasts-top-leave-active {
  position: absolute;
}
.toasts-leave-active {
  bottom: 8px;
}
.toasts-top-leave-active {
  top: 8px;
}
</style>
