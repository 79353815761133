//~~legacy~~
import variables from '@/styles/element-variables';
import menu from '@/styles/variables';

const state = {
  theme: variables.theme,
  themeText: menu.theme,
  tagsView: false,
  fixedHeader: true,
  sidebarLogo: true,
};

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  },
};

const actions = {
  changeSetting({ commit }, data) {
    commit("CHANGE_SETTING", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
