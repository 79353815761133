<template>
  <v-checkbox
    v-model="checked"
    :readonly="readonly"
    :disabled="disabled"
    :class="grey ? 'checkbox-grey' : ''"
    :ripple="false"
    hide-details
    @change="change"
    @click="click"
  /></v-checkbox>
</template>

<script> //~~legacy~~
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    grey: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: this.value || false
    };
  },
  watch: {
    value(val) {
      this.checked = val;
    }
  },
  methods: {
    change() {
      this.$emit("input", this.checked);
      this.$emit("change");
    },
    click() {
      if (this.readonly) {
        this.$emit("click");
      }
    }
  }
};
</script>
