<template>
  <el-input
    @input="setFormatDate"
    style="width:100%;"
    class="date"
    v-model="text"
    placeholder="dd.mm.yyyy"
    :disabled="disabled"
  >
  </el-input>
</template>

<script> //~~legacy~~
import moment from "moment";

export default {
  props: ["value","disabled"],
  data() {
    return {
      text: this.value,
    };
  },
  mounted() {
    if (this.value.length > 0) {
      this.text = moment(this.value).format("DD.MM.yyyy");
    }
  },
  methods: {
    setFormatDate() {
      var newVal = this.text
        .split(".")
        .join("")
        .split("-")
        .join("");
      if (newVal.length > 2) {
        newVal = [newVal.slice(0, 2), ".", newVal.slice(2)].join("");
      }
      if (newVal.length > 5) {
        newVal = [newVal.slice(0, 5), ".", newVal.slice(5)].join("");
      }

      this.text = newVal;
      this.$emit("input", this.text);
    },
  },
};
</script>

<style lang="scss" scoped></style>
