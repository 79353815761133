<template>
  <a
    v-if="isLink && isExternal"
    class="inline-block text-center"
    :href="url"
    :target="isBlankTarget ? '_blank' : undefined"
    :class="{
      selected,
      disabled: disabled || loading,
      'is-loading': loading && !disabled,
      'group !p-0': slots.top
    }"
    :draggable="disabled || loading ? 'false' : undefined"
    :tabindex="disabled || loading ? -1 : undefined"
    @click.capture="handleDisabled"
    ><span :class="[wrapperClasses, { 'invisible': loading }]">
      <div v-if="slots.top" class="flex h-full flex-col">
        <div class="flex flex-1 items-center overflow-hidden" :class="topWrapperClasses">
          <slot name="top" />
        </div>
        <div
          class="h-[43px] w-full border-t border-solid border-t-black/100 pt-2xs group-focus-visible:border-focus/100"
          :class="{
            '!border-t-grey-dis': disabled
          }"
        >
          <slot />
        </div>
      </div>
      <slot v-else /></span
    ><WebLoadingIndicator
      v-if="loading"
      :is-loading="loading"
      is-web
      :class="{
        'web-loading-black': loadingType === LoadingType.BLACK,
        'web-loading-blue': loadingType === LoadingType.BLUE
      }"
    /><!-- avoid spaces ---></a
  >
  <router-link
    v-else-if="isLink && !isExternal"
    class="inline-block text-center"
    :to="url"
    :target="isBlankTarget ? '_blank' : undefined"
    :class="{
      selected,
      disabled: disabled || loading,
      'is-loading': loading && !disabled,
      'group !p-0': slots.top
    }"
    :draggable="disabled || loading ? 'false' : undefined"
    :tabindex="disabled || loading ? -1 : undefined"
    @click.native.capture="handleDisabled"
    ><span :class="[wrapperClasses, { 'invisible': loading }]">
      <div v-if="slots.top" class="flex h-full flex-col">
        <div class="flex flex-1 items-center overflow-hidden" :class="topWrapperClasses">
          <slot name="top" />
        </div>
        <div
          class="h-[43px] w-full border-t border-solid border-t-black/100 pt-2xs group-focus-visible:border-focus/100"
          :class="{
            '!border-t-grey-dis': disabled
          }"
        >
          <slot />
        </div>
      </div>
      <slot v-else /></span
    ><WebLoadingIndicator
      v-if="loading"
      :is-loading="loading"
      is-web
      :class="{
        'web-loading-black': loadingType === LoadingType.BLACK,
        'web-loading-blue': loadingType === LoadingType.BLUE
      }"
    /><!-- avoid spaces ---></router-link
  >
  <button
    v-else
    :class="{
      selected,
      disabled: disabled || loading,
      'is-loading': loading && !disabled,
      'group !p-0': slots.top
    }"
    :disabled="disabled || loading"
    type="button"
    @click="!loading && !disabled && emit('click')"
  >
    <span :class="[wrapperClasses, { 'invisible': loading }]">
      <div v-if="slots.top" class="flex h-full flex-col">
        <div class="flex flex-1 items-center overflow-hidden" :class="topWrapperClasses">
          <slot name="top" />
        </div>
        <div
          class="h-[43px] w-full border-t border-solid border-t-black/100 pt-2xs group-focus-visible:border-focus/100"
          :class="{
            '!border-t-grey-dis': disabled
          }"
        >
          <slot />
        </div>
      </div>
      <slot v-else />
    </span>
    <WebLoadingIndicator
      v-if="loading"
      :is-loading="loading"
      is-web
      :class="{
        'web-loading-black': loadingType === LoadingType.BLACK,
        'web-loading-blue': loadingType === LoadingType.BLUE
      }"
    />
  </button>
</template>
<script setup lang="ts">
import { useSlots } from 'vue';
import { Location } from 'vue-router';
import WebLoadingIndicator from '../utility/WebLoadingIndicator.vue';
import { CSSClass } from '~/api/schema/common';

enum LoadingType {
  // Grey by default
  BLACK = 'black',
  BLUE = 'blue'
}
const props = defineProps<{
  disabled?: boolean;
  loading?: boolean;
  selected?: boolean;
  isLink?: boolean;
  url?: string | Partial<Location>;
  isExternal?: boolean;
  isBlankTarget?: boolean;
  wrapperClasses?: CSSClass;
  topWrapperClasses?: CSSClass;
  loadingType?: string;
}>();

const emit = defineEmits<{ (e: 'click'): void }>();

const slots = useSlots();

const handleDisabled = (event: Event) => {
  if (props.disabled || props.loading) {
    event.stopPropagation();
    event.preventDefault();
  }
};
</script>
