<template>
  <div class="bb-page-header">
    <bb-back-button @click="click" class="bb-page-header-back-button border" />
    <div class="bb-page-header-title">
      {{ title }}
      <span v-if="updateInfo" class="update-info">
        Updated on {{ updateInfo }}
      </span>
    </div>
  </div>
</template>

<script> //~~legacy~~
export default {
  props: ["title", "updateInfo"],
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss">
.bb-page-header {
  width: 1189px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  .bb-page-header-back-button {
    width: 38px;
    height: 38px !important;
  }
  .bb-page-header-title {
    font-family: "Helvetica Neue";
    font-size: 20px;
    margin-left: 21px;
    .update-info {
      color: #757575;
      font-size: 16px;
    }
  }
}
</style>
