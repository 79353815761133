<template>
  <Teleport v-if="show" to="main">
    <div class="absolute top-0 left-0 right-0 bottom-0 z-[1197] h-screen w-full overflow-hidden bg-blue-dark/80">
      <div
        ref="drawer"
        class="drawer-content flex flex-col border border-solid border-black bg-beige opacity-0 transition-all ease-linear"
        :class="[classes, { '!opacity-100': animationStarted }]"
        :style="{
          transitionDuration: `${closing ? closeDuration : slideDuration}ms`,
          height: [DIRECTIONS.TOP, DIRECTIONS.BOTTOM].includes(from) ? `calc(100% - ${freeAreaWithUnit})` : '100%',
          width: [DIRECTIONS.LEFT, DIRECTIONS.RIGHT].includes(from) ? `calc(100% - ${freeAreaWithUnit})` : '100%',
          transform
        }"
      >
        <div
          class="flex h-12.5 min-h-12.5 w-full items-center justify-between border-b border-solid border-black bg-white px-md"
        >
          <span class="text-heading-xl"><slot name="title" /></span>
          <IconButton v-if="!hideCloseButton" class="px-0" tabindex="-1" @click="close">
            <SmallCross class="h-3.5 w-3.5" />
          </IconButton>
        </div>
        <template v-if="animationDone">
          <slot />
        </template>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import Teleport from 'vue2-teleport';
import IconButton from '~/components/button/IconButton.vue';
import SmallCross from '~/components/icon/SmallCross.vue';

enum DIRECTIONS {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right'
}

const props = withDefaults(
  defineProps<{
    from: DIRECTIONS;
    value: boolean;
    classes?: Array<string | Record<string, boolean>> | string | Record<string, boolean>;
    hideCloseButton?: boolean;
    closeDelay?: number;
    noAnimation?: boolean;
    slideDuration?: number;
    closeDuration?: number;
    freeAreaWithUnit?: string;
    startPositionWithUnit?: string;
  }>(),
  {
    slideDuration: 100,
    closeDuration: 60 * 60 * 1000, //set to 1h so no changes are visible
    freeAreaWithUnit: '0',
    startPositionWithUnit: '50%'
  }
);

const emit = defineEmits<{ (e: 'input', val: boolean): void; (e: 'close'): void }>();

const show = computed({
  set: (value: boolean) => {
    emit('input', value);
  },
  get: () => {
    return props.value;
  }
});

const drawer = ref<HTMLElement | undefined>();
const slideDuration = computed(() => props.slideDuration);
const animationStarted = ref(false);
const animationDone = ref(false);
watch(show, (value) => {
  if (value) {
    if (props.noAnimation) {
      animationStarted.value = true;
      animationDone.value = true;
    } else if (window) {
      window.setTimeout(() => {
        animationStarted.value = true;
      }, 0);
      window.setTimeout(() => {
        animationDone.value = true;
      }, slideDuration.value);
    }
  }
});

const transform = computed(() => {
  const axis = [DIRECTIONS.BOTTOM, DIRECTIONS.TOP].includes(props.from) ? 'Y' : 'X';
  const sign = [DIRECTIONS.LEFT, DIRECTIONS.TOP].includes(props.from) ? '-' : '';
  const value = [DIRECTIONS.RIGHT, DIRECTIONS.BOTTOM].includes(props.from) ? props.freeAreaWithUnit : 0;
  return `translate${axis}(${sign}${animationStarted.value ? value : props.startPositionWithUnit})`;
});

const closing = ref(false);
function close() {
  closing.value = true;
  animationStarted.value = false;
  animationDone.value = false;
  emit('close');
  if (window && props.closeDelay) {
    window.setTimeout(() => {
      show.value = false;
      closing.value = false;
    }, props.closeDelay);
  } else {
    show.value = false;
    closing.value = false;
  }
}
</script>
