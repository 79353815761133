<template>
  <v-btn
    elevation="0"
    :ripple="false"
    class="bb-back-button"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    @click="click"
  >
    <v-img
      :src="require(`../../../assets/${iconSrc}`)"
      :width="16.5"
      :height="14"
      contain
    />
    <slot />
  </v-btn>
</template>

<script> //~~legacy~~
export default {
  data() {
    return {
      hovered: false
    };
  },
  computed: {
    iconSrc() {
      if (this.hovered) {
        return "x-button-hover.svg";
      } else {
        return "x-button.svg";
      }
    }
  },
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>
