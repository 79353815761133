<template>
  <div
    class="text-web-body-m-med pointer-events-auto flex flex-row flex-nowrap items-center rounded-[6px] p-3 text-black"
    :class="typeClasses"
    :style="{ maxWidth: `calc(min(90%, 100vw))` }"
  >
    <WebCheckXS v-if="options.type === NotificationTypes.INFO" class="mr-3 h-4.5 w-4.5 text-black" />
    <WebIconWarning v-else class="mr-3 h-4.5 w-4.5 text-black" />
    <div
      v-if="messages?.length && !options.component"
      class="text-web-body-m-med overflow-hidden break-words"
      :class="{
        'flex flex-col gap-1': messages?.length > 1
      }"
    >
      <span v-for="(message, i) in messages" :key="`${message}-${i}`">{{ message }}</span>
    </div>
    <component :is="options.component" v-if="options.component" v-bind="options.componentProps" :id="options.id">
      <span v-for="(message, i) in messages" :key="`${message}-${i}`">{{ message }}</span>
    </component>
    <WebButton
      v-if="options.showClose"
      class="web-btn-icon ml-4 text-black"
      @click="hideNotification(options.id, true)"
    >
      <WebCrossM />
    </WebButton>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { NotificationsOptions, NotificationTypes } from '~/utils/notificationsConstants';
import WebIconWarning from '~/components/icon/WebIconWarning.vue';
import WebCheckXS from '~/components/icon/WebCheckXS.vue';
import WebButton from '~/components/button/WebButton.vue';
import WebCrossM from '~/components/icon/WebCrossM.vue';
import { hideNotification } from '~/utils/notifications';

const props = defineProps<{
  options: NotificationsOptions;
}>();

const typeClasses = computed(() =>
  props.options.type === NotificationTypes.ERROR ? 'bg-web-red-light/100' : 'bg-web-green-light/100'
);

const messages = computed(() =>
  props.options.multipleLinesMessage
    ? props.options.message?.split(props.options.separator || '\n')
    : [props.options.message]
);
</script>
