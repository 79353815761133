<template>
  <div class="bb-search-text-field">
    <bb-back-button class="bb-search-text-field-btns" @click="$emit('back')" />
    <input
      :placeholder="placeholder"
      class="bb-search-text-field-input"
      v-model="text"
      @input="$emit('input', text)"
    />
  </div>
</template>

<script> //~~legacy~~
export default {
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      text: this.value
    };
  }
};
</script>

<style lang="scss" scoped>
.bb-search-text-field {
  width: 100%;
  height: 60px;
  display: flex;
  flex-shrink: 0;
  background-color: white;
  align-items: center;
  .back-button {
    padding-left: 30px;
    .back-button-img {
      width: 24px;
      height: 18px;
    }
  }
  .bb-search-text-field-title {
    flex: 1;
    text-align: center;
    font-family: "Helvetica Neue";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
  .padding-right {
    padding-right: 40px;
  }
  .padding-left {
    padding-left: 40px;
  }
  .x-button {
    .x-button-img {
      width: 20px;
      height: 20px;
      margin-right: 18px;
    }
  }
}

.close-warning-dialog {
  width: 338px;
  background-color: #ffffff;
  padding: 40px 45px 46px 45px;
  .close-warning-dialog-text {
    font-family: "Goudy Old Style";
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: black;
  }
  .close-warning-dialog-buttons-container {
    display: flex;
    margin-top: 27px;
    justify-content: center;
    .close-warning-dialog-buttons-yes-button {
      width: 79px;
      margin-right: 33px;
    }
    .close-warning-dialog-buttons-no-button {
      width: 79px;
    }
  }
}
.bb-search-text-field-btns {
  background: white !important;
  padding-left: 28px !important;
  padding-right: 13px !important;
}
.bb-search-text-field-input {
  font-family: "Helvetica Neue";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  outline: 2px solid transparent;
  padding-right: 28px;
  outline-offset: 2px;
  height: 100%;
  width: 100%;
}
</style>
