//~~legacy~~
const state = {
  order: null,
  user: null,
};

const actions = {};

const mutations = {
  setOrder: (state, order) => {
    state.order = order;
  },
  setUser: (state, user) => {
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
