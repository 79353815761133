//~~legacy~~
const state = () => ({
    blog: null,
  });
  
  const actions = {};
  
  const mutations = {
    setBlog: (state, blog) => {
      state.blog = blog;
    },
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };
  