<template>
  <div
    class="nav-bar flex w-full flex-nowrap items-center justify-between border-y border-r border-l"
    :class="{
      'border-black/100 bg-black/100': isEditing,
      'border-dark1/100 bg-white/100 pr-[25px]': !isEditing,
      'border-l-0': !isMobile
    }"
  >
    <div class="flex h-full flex-row items-center">
      <a
        href="#"
        class="float-left ml-md mr-sm block rounded border p-[1px]"
        :class="{
          'svg-override-focus-beige !border-black/100': isEditing,
          'svg-override-focus-black !border-white/100': !isEditing,
          'focus:!border-focus/100': showFocus
        }"
        @mousedown="showFocus = false"
        @click.stop.prevent="toggleSideBar"
        @blur="showFocus = true"
      >
        <HamburgerIcon id="hamburger-container" :is-active="sidebar?.opened" :is-editing="isEditing" />
      </a>
      <Breadcrumb v-if="!isEditing && !noBreadcrumbs" id="breadcrumb-container" class="float-left" />
    </div>
    <div v-if="isEditing" id="navBarCenter" class="w-full">
      <!-- teleport placeholder for inserting additional content by pages -->
    </div>
    <div
      ref="userMenu"
      class="relative h-full py-[3px]"
      @click.stop.prevent="showUserMenu = !showUserMenu"
      @focusout="checkClosePopup"
    >
      <div class="focus-inside-rounded h-full p-[1px]">
        <div
          class="flex h-full items-center rounded p-0.5"
          :class="{
            'bg-black/100 hover:bg-dark1/100': isEditing,
            'cursor-pointer bg-white/100 px-[5px] hover:bg-beige/100': !isEditing,
            '!bg-dark1/100': isEditing && showUserMenu,
            '!bg-beige/100': !isEditing && showUserMenu
          }"
        >
          <!-- hidden button for focusing -->
          <IconButton
            class="relative !max-h-0 !min-h-0 max-w-0 !p-0 !outline-0 focus-visible:!outline-0"
            :tabindex="isEditing ? -1 : 0"
            @focus.native="showUserMenu = true"
          />
          <CircledImage v-if="!isEditing" :src="administrator?.avatar || noAvatarImage" />
          <span v-if="!isEditing && administrator" class="text-body-l-reg pl-2xs text-black/100">
            {{
              `${administrator.first_name}${administrator.last_name && administrator.first_name ? ' ' : ''}${
                administrator.last_name
              }`
            }}
          </span>
        </div>
      </div>
      <div
        v-if="showUserMenu"
        class="absolute -left-[1px] w-[calc(100%+2px)] cursor-pointer rounded border !border-black/0 p-[1px] text-body-xs text-black focus-within:!border-focus/100"
        :style="{ top: `${HEADER_HEIGHT}px` }"
      >
        <a
          href="#"
          class="block w-full cursor-pointer rounded border !border-dark1/100 bg-white/100 px-[14px] py-2xs text-body-xs text-black shadow-dropdown hover:bg-beige/100 hover:!text-blue-hover/100 focus:bg-beige/100 focus:!text-blue-hover/100"
          @mousedown.stop.prevent="logout"
          @click="logout"
        >
          Log out
        </a>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { onClickOutside } from '@vueuse/core';
import { clearPermissionState, getAdministrator } from '~/api/utils/auth';
import HamburgerIcon from '~/components/icon/HamburgerIcon.vue';
import Breadcrumb from '@/components/Breadcrumb/index.vue';
import IconButton from '~/components/button/IconButton.vue';
import CircledImage from '~/components/layout/CircledImage.vue';
import { HEADER_HEIGHT } from '@/layout/components/layoutConstants';

defineProps<{ noBreadcrumbs?: boolean }>();

// Cyclic import dependencies resolving via dynamic import
const useStore = ref<Function>();
import('@/store').then((res) => {
  useStore.value = res.default;
});

// to show focus by keyboard only, we set this to false on mouse clicks and restore to true when focus left
const showFocus = ref(true);

const noAvatarImage: string = require('~/assets/Users-User-Male-4-icon.png');

const store = computed(() => useStore.value && useStore.value());
const router = useRouter();
const route = useRoute();

const administrator = computed(() => getAdministrator());

const sidebar = computed(() => store.value?.getters['sidebar']);
const isMobile = computed(() => store.value?.getters['device'] === 'mobile');

const isEditing = computed(() => store.value?.getters['navBarEditing']);

const toggleSideBar = () => {
  store.value?.dispatch('app/toggleSideBar');
};

const showUserMenu = ref(false);
const userMenu = ref<HTMLElement | null>(null);

onClickOutside(userMenu, () => (showUserMenu.value = false));

const logout = async () => {
  showUserMenu.value = false;
  await store.value?.dispatch('user/logout');
  clearPermissionState();
  await router.push(`/login?redirect=${route.fullPath}`);
};

const checkClosePopup = (event: FocusEvent) => {
  // when we lose focus on the main item, and focus is not going to be in the popup, close the popup - we go out
  let current = (event.relatedTarget as HTMLElement)?.parentElement;
  while (current && current !== userMenu.value) {
    current = current.parentElement;
  }
  if (!current) {
    showUserMenu.value = false;
  }
};
</script>
<style lang="scss">
.nav-bar {
  height: $headerHeight;
  min-height: $headerHeight;
}
</style>
