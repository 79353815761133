//~~legacy~~
const state = {
  order: null,
  user: null,
  defaultAddress: null,
  isCollectionAddress: false,
  selectedHomeTrials: [],
  salesChannel: null
};

const actions = {};

const mutations = {
  setOrder: (state, order) => {
    state.order = order;
  },
  setUser: (state, user) => {
    state.user = user;
  },
  setDefaultAddress: (state, defaultAddress) => {
    state.defaultAddress = defaultAddress;
  },
  setSelectedHomeTrials: (state, selectedHomeTrials) => {
    state.selectedHomeTrials = selectedHomeTrials;
  },
  setIsCollectionAddress: (state, status) => {
    state.isCollectionAddress = status;
  },
  removeHomeTrialsItem: (state, index) => {
    state.selectedHomeTrials.splice(index, 1);
  },
  setSalesChannel: (state, salesChannel) => {
    state.salesChannel = salesChannel;
  },
  resetDraft: state => {
    state.user = null;
    state.defaultAddress = null;
    state.order = null;
    state.isCollectionAddress = false;
    state.selectedHomeTrials = [];
    state.salesChannel = null;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
