//~~legacy~~
const state = () => ({
    orderOrder: null,
    orderItem: null,
    selectedOrderItem: null,
  });
  
  const actions = {};
  
  const mutations = {
    setOrder: (state, order) => {
      state.order = order;
    },
    setOrderItem: (state, orderItem) => {
      state.orderItem = orderItem;
    },
    setSelectedOrderItem: (state, selectedOrderItem) => {
      state.selectedOrderItem = selectedOrderItem;
    },
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };