//~~legacy~~
import request from "@/utils/request";

// Orders
export function fetchOrders(query) {
  return request({
    url: `/orders?${query}`,
    method: "get"
  });
}

export function fetchOrder(orderId) {
  return request({
    url: `/orders/${orderId}`,
    method: "get"
  });
}

export function updateOrder(orderId, params) {
  return request({
    url: `/orders/${orderId}`,
    method: "put",
    data: params
  });
}

export function deleteOrder(orderId) {
  return request({
    url: `/orders/${orderId}`,
    method: "delete"
  });
}

export function createOrder(params) {
  return request({
    url: "/orders",
    method: "post",
    data: params
  });
}

export function cancelOrder(orderId, params) {
  return request({
    url: `/orders/${orderId}/cancel`,
    method: "put",
    data: params
  });
}

export function exportPaymentTransactions(query) {
  return request({
    url: `/orders/export_payment_transactions?${query}`,
    method: "GET",
    responseType: "blob"
  });
}

// eye Tests
export function fetchEyeTests(orderId) {
  return request({
    url: `/orders/${orderId}/eye_tests`,
    method: "get"
  });
}

export function fetchEyeTest(orderId, eyeTestId) {
  return request({
    url: `/orders/${orderId}/eye_tests/${eyeTestId}`,
    method: "get"
  });
}

export function updateEyeTest(orderId, eyeTestId, params) {
  return request({
    url: `/orders/${orderId}/eye_tests/${eyeTestId}`,
    method: "put",
    data: params
  });
}

export function deleteEyeTest(orderId, eyeTestId) {
  return request({
    url: `/orders/${orderId}/eye_tests/${eyeTestId}`,
    method: "delete"
  });
}

export function createEyeTest(orderId, params) {
  return request({
    url: `/orders/${orderId}/eye_tests`,
    method: "post",
    data: params
  });
}

export function exportVatInvoice(orderId) {
  return request({
    url: `orders/${orderId}/export_vat_invoice`,
    method: "GET",
    responseType: "blob"
  });
}

// eye Tests Media

export function updateEyeTestMedia(orderId, eyeTestId, mediaId, params) {
  return request({
    url: `/orders/${orderId}/eye_tests/${eyeTestId}/media/${mediaId}`,
    method: "put",
    data: params
  });
}

export function deleteEyeTestMeida(orderId, eyeTestId, mediaId) {
  return request({
    url: `/orders/${orderId}/eye_tests/${eyeTestId}/media/${mediaId}`,
    method: "delete"
  });
}
export function createEyeTestMedia(orderId, eyeTestId, params) {
  return request({
    url: `/orders/${orderId}/eye_tests/${eyeTestId}/media`,
    method: "post",
    data: params
  });
}

export function copyOrderEyeTestMediumFromObject(orderId, eyeTestId, params) {
  return request({
    url: `/orders/${orderId}/eye_tests/${eyeTestId}/media/copy_from_object`,
    method: "post",
    data: params
  });
}

// eye Tests prescription

export function updateEyeTestPrescriptions(
  orderId,
  orderItemId,
  eyeTestId,
  params
) {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}/eye_tests/${eyeTestId}/prescriptions`,
    method: "put",
    data: params
  });
}

export function deleteEyeTestPrescriptions(orderId, eyeTestId) {
  return request({
    url: `/orders/${orderId}/eye_tests/${eyeTestId}/prescriptions`,
    method: "delete"
  });
}

export function createEyeTestPrescriptions(orderId, eyeTestId, params) {
  return request({
    url: `/orders/${orderId}/eye_tests/${eyeTestId}/prescriptions`,
    method: "post",
    data: params
  });
}

// Order Items

export function fetchOrdersItems(orderId) {
  return request({
    url: `/orders/${orderId}/order_items`,
    method: "get"
  });
}

export function fetchOrderItem(orderId, orderItemId) {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}`,
    method: "get"
  });
}

export function updateOrderItem(orderId, orderItemId, params) {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}`,
    method: "put",
    data: params
  });
}

export function deleteOrderItem(orderId, orderItemId) {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}`,
    method: "delete"
  });
}
export function createOrderItemDiscount(orderId, orderItemId, params) {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}/order_item_discounts`,
    method: "post",
    data: params
  });
}

export function updateOrderItemDiscount(
  orderId,
  orderItemId,
  discountId,
  params
) {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}/order_item_discounts/${discountId}`,
    method: "put",
    data: params
  });
}

export function deleteOrderItemDiscount(orderId, orderItemId, discountId) {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}/order_item_discounts/${discountId}`,
    method: "delete"
  });
}
export function createOrderItemStockStatuses(orderId, orderItemId, params) {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}/order_item_stock_statuses`,
    method: "post",
    data: params
  });
}

export function createOrderItem(orderId, params) {
  return request({
    url: `/orders/${orderId}/order_items`,
    method: "post",
    data: params
  });
}

// eye test for order item
export function fetchOrderItemEyeTest(orderId, orderItemId, eyeTestId) {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}/eye_tests/${eyeTestId}`,
    method: "get"
  });
}

export function createOrderItemEyeTestMeduim(
  orderId,
  orderItemId,
  orderItemEyeTestId,
  params
) {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}/eye_tests/${orderItemEyeTestId}/media`,
    method: "post",
    data: params
  });
}

export function copyOrderItemEyeTestMediumFromObject(
  orderId,
  orderItemId,
  orderItemEyeTestId,
  params
) {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}/eye_tests/${orderItemEyeTestId}/media/copy_from_object`,
    method: "post",
    data: params
  });
}

// Fullfillments

export function fetchFulfillments(orderId) {
  return request({
    url: `/orders/${orderId}/fulfillments`,
    method: "get"
  });
}
export function fetchFulfillmentsFilteration(orderId, query) {
  return request({
    url: `/orders/${orderId}/fulfillments?${query}`,
    method: "get"
  });
}

export function fetchFulfillment(orderId, fullFillmentId) {
  return request({
    url: `/orders/${orderId}/fulfillments/${fullFillmentId}`,
    method: "get"
  });
}

export function updateFulfillment(orderId, fullFillmentId, params) {
  return request({
    url: `/orders/${orderId}/fulfillments/${fullFillmentId}`,
    method: "put",
    data: params
  });
}

export function deleteFulfillment(orderId, fullFillmentId) {
  return request({
    url: `/orders/${orderId}/fulfillments/${fullFillmentId}`,
    method: "delete"
  });
}

export function createFulfillment(params) {
  return request({
    url: `/orders/${orderId}/fulfillments`,
    method: "post",
    data: params
  });
}

// Shipment

export function fetchShipment(orderId, fullFillmentId, shipMentId) {
  return request({
    url: `/orders/${orderId}/fulfillments/${fullFillmentId}/shipments/${shipMentId}`,
    method: "get"
  });
}

export function updateShipment(orderId, fullFillmentId, shipMentId, params) {
  return request({
    url: `/orders/${orderId}/fulfillments/${fullFillmentId}/shipments/${shipMentId}`,
    method: "put",
    data: params
  });
}

export function deleteShipment(orderId, fullFillmentId, shipMentId) {
  return request({
    url: `/orders/${orderId}/fulfillments/${fullFillmentId}/shipments/${shipMentId}`,
    method: "delete"
  });
}

export function createShipment(param, fullFillmentId) {
  return request({
    url: `/orders/${orderId}/fulfillments/${fullFillmentId}/shipments`,
    method: "post",
    data: params
  });
}

// Eye Test order items

export function createOrderItemEyeTest(orderId, orderItemId, params) {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}/eye_tests`,
    method: "post",
    data: params
  });
}

/// Timeline

export function fetchTimeLine(orderId) {
  return request({
    url: `/orders/${orderId}/timeline`,
    method: "get"
  });
}

export function createComment(orderId, params) {
  return request({
    url: `/orders/${orderId}/comments`,
    method: "post",
    data: params
  });
}

export function editComment(orderId, commentId, params) {
  return request({
    url: `/orders/${orderId}/comments/${commentId}`,
    method: "put",
    data: params
  });
}

export function deleteComment(orderId, commentId) {
  return request({
    url: `/orders/${orderId}/comments/${commentId}`,
    method: "delete"
  });
}

export function createCommentMedia(orderId, commentId, params) {
  return request({
    url: `/orders/${orderId}/comments/${commentId}/media`,
    method: "post",
    data: params
  });
}

export function deleteCommentMedia(userId, commentId, mediaId) {
  return request({
    url: `/users/${userId}/comments/${commentId}/media/${mediaId}`,
    method: "delete"
  });
}

// refund_requests
export function createRefundRequest(orderId, params) {
  return request({
    url: `/orders/${orderId}/refund_requests`,
    method: "post",
    data: params
  });
}

export function deleteRefundRequest(orderId, refundRequestId) {
  return request({
    url: `/orders/${orderId}/refund_requests/${refundRequestId}`,
    method: "delete"
  });
}

export function updateRefundRequest(orderId, refundRequestId, params) {
  return request({
    url: `/orders/${orderId}/refund_requests/${refundRequestId}`,
    method: "put",
    data: params
  });
}

// Refund Request Order Items

export function createRefundRequestOrderItem(
  orderId,
  refundRequestId,
  orderItemId
) {
  return request({
    url: `orders/${orderId}/refund_requests/${refundRequestId}/order_items/${orderItemId}`,
    method: "post"
  });
}

export function deleteRefundRequestOrderItem(
  orderId,
  refundRequestId,
  orderItemId
) {
  return request({
    url: `orders/${orderId}/refund_requests/${refundRequestId}/order_items/${orderItemId}`,
    method: "delete"
  });
}

//Maximum Allowed Refund Value
export function getMaximumAllowedRefundValue(orderId, refundRequestId) {
  return request({
    url: `/orders/${orderId}/refund_requests/${refundRequestId}/maximum_allowed_refund_value`,
    method: "get"
  });
}

// Payments

export function createPaymentUsingPaymentLink(orderId, params) {
  return request({
    url: `/orders/${orderId}/payments/create_using_payment_link`,
    method: "post",
    data: params
  });
}

export function createPaymentCheckout(orderId, params) {
  return request({
    url: `/orders/${orderId}/payments/checkout`,
    method: "post",
    data: params
  });
}

export function handleAdditionalDetails(orderId, params) {
  return request({
    url: `/orders/${orderId}/payments/submit_details`,
    method: "post",
    data: params
  });
}

export function handleAdditionalRedirectDetails(orderId, params) {
  return request({
    url: `/orders/${orderId}/payments/submit_redirect_details`,
    method: "post",
    data: params
  });
}

export function createPayment(orderId, params) {
  return request({
    url: `/orders/${orderId}/payments`,
    method: "post",
    data: params
  });
}

export function markAsPaid(orderId, params) {
  return request({
    url: `/orders/${orderId}/payments/mark_as_paid`,
    method: "post",
    data: params
  });
}

//initiate_refund

export function initiateRefund(orderId, refundRequestId) {
  return request({
    url: `orders/${orderId}/refund_requests/${refundRequestId}/initiate_refund`,
    method: "post"
  });
}

// refund options

export function getRefundOptions(orderId, refundRequestId) {
  return request({
    url: `/orders/${orderId}/refund_requests/${refundRequestId}/refund_options`,
    method: "get"
  });
}

// create many refund

export function createManyRefundRequestPayments(
  orderId,
  refundRequestId,
  params
) {
  return request({
    url: `orders/${orderId}/refund_requests/${refundRequestId}/payments/create_many`,
    method: "post",
    data: params
  });
}

//send order modified email

export function sendOrderModifiedEmail(orderId) {
  return request({
    url: `/orders/${orderId}/order_modified_email`,
    method: "post"
  });
}

// get available refunds for the order items

export function apiGetRefundScenarios({ orderId, refundRequestId }) {
  return request({
    url: `/orders/${orderId}/refund_requests/${refundRequestId}/refund_scenarios`,
    method: "get"
  });
}
