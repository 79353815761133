//~~legacy~~
const state = () => ({
  glass: null,
  glassVariant: null,
  frameVariant: null,
  selectedLensColour: null,
});

const actions = {};

const mutations = {
  setGlass: (state, glass) => {
    state.glass = glass;
  },
  setGlassVariant: (state, glassVariant) => {
    state.glassVariant = glassVariant;
  },
  setFrameVariant: (state, frameVariant) => {
    state.frameVariant = frameVariant;
  },
  setSelectedLensColour: (state, colour) => {
    state.selectedLensColour = colour;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
