var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-item",class:{
    'list-item-disabled': _vm.disabled != null && _vm.disabled !== false,
    selectable: _vm.selectable == null || _vm.selectable == true,
    'grey-border-bottom': _vm.greyBorder != null && _vm.disabled !== false,
    'light-grey-border-bottom':
      _vm.greyBorder === undefined || _vm.greyBorder === false
  },on:{"mouseover":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false},"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"content-view"},[(_vm.title)?_c('span',{staticClass:"content-view-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._t("default")],2),(_vm.arrow != null)?_c('div',{staticClass:"arrow-container",class:{ 'compact-style': _vm.compactStyle != null }},[_c('img',{attrs:{"src":require(`../../../assets/${_vm.iconSrc}`)}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }