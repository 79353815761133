import { Location } from 'vue-router';

export const SETTINGS_TRANSFORM_VALUE = 49;
export type SettingsItem = {
  id: string | number;
  url: string | Partial<Location>;
  status?: string;
  name: string;
  description?: string;
  right?: {
    name: string;
    description?: string;
  };
};
