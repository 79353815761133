// TODO: add unit tests
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import Cookies from 'js-cookie';
import { isObject } from '@vueuse/core';
// TODO: replace below by something else
import { getRequestHeaderPlatform, isPOSApplication, isWebApplication } from '~/utils/application';
import {
  nullEmptyStringsFromKeyNames,
  nullEmptyStringsFromURLsWith,
  nullifyEmptyStringsFromData
} from '~/utils/request';
import { addError } from '~/components/Debug/RequestErrorListUtils';
import { notificationsMuted, showError } from '~/utils/notifications';
import { isGuestRoute } from '~/utils/auth';
import {
  getToken,
  removeToken,
  removeAdministrator,
  getSalesChannel,
  clearPermissionState,
  PERMISSION_TIMEOUT_HEADER,
  checkForPermissionsUpdate
} from './auth';
const SPECIFIC_ERRORS_TO_IGNORE_NOTIFICATION = [
  'PG::UniqueViolation',
  'Login Failed. Please enter a valid email address and password.'
];

const REQUESTS_TIMEOUT = 0;

export const NETWORK_ERROR_CODE = 'ERR_NETWORK';
//TODO remove const and usages when axios version is updated ^1 for all apps
export const NETWORK_ERROR_MESSAGE = 'Network Error';

export enum TimeToLive {
  ONE_MILLISECOND = 1,
  FOUR_HOURS_IN_MILLISECONDS = 4 * 60 * 60 * 1000,
  ONE_HOUR_IN_MILLISECONDS = 60 * 60 * 1000,
  FIFTEEN_MINUTES_IN_MILLISECONDS = 15 * 60 * 1000
}

// create an axios instance
const service = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API}${process.env.VUE_APP_BASE_API_ADMIN}`,
  timeout: REQUESTS_TIMEOUT // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    /* TODO: figure out if below is needed, there is no "query" field in config
    if (typeof config.query == 'object') {
      config.url = `${config.url}?${objectIntoQuery(config.query)}`;
    }
     */

    // do something before request is sent
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers.platform = getRequestHeaderPlatform();
    const session = getToken();

    if (session) {
      config.headers['access-token'] = session.accessToken;
      config.headers.client = session.client;
      config.headers.uid = session.uid;
    }
    if (isPOSApplication()) {
      const salesChannel = getSalesChannel();
      if (salesChannel) {
        config.headers['sales-channel-id'] = String(salesChannel.id);
      }
      const pinCode = Cookies.get('pincode') || undefined;
      if (pinCode) {
        config.headers['pin-code'] = String(pinCode);
      }
    }
    if (config.data) {
      if (nullEmptyStringsFromURLsWith.some((str) => config.url?.includes(str))) {
        config.data = nullifyEmptyStringsFromData(config.data);
      } else {
        const keysToNullify =
          Object.keys(config.data).filter((key) => nullEmptyStringsFromKeyNames.some((str) => key?.includes(str))) ||
          [];
        if (keysToNullify.length) {
          keysToNullify.forEach((key) => {
            config.data[key] = nullifyEmptyStringsFromData(config.data[key]);
          });
        }
      }
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    if (!isWebApplication() && response.headers[PERMISSION_TIMEOUT_HEADER]) {
      checkForPermissionsUpdate(parseInt(response.headers[PERMISSION_TIMEOUT_HEADER]));
    }
    return response;
  },
  (error) => {
    if (
      error.response != null &&
      error.response.status === StatusCodes.UNAUTHORIZED &&
      !isGuestRoute(window ? window.location.pathname : '')
    ) {
      removeToken();
      removeAdministrator();
      clearPermissionState();
      window.location.href = '/login';
      return null;
    } else if (isPOSApplication() && error?.response?.status === StatusCodes.FORBIDDEN) {
      import('@/store').then((res) => {
        const store = res.default();
        store.dispatch('pinCodes/lockSession');
      });
    }

    if (error.response?.data) {
      const errorDetails = error.response?.data?.error?.details;
      const errorMessage = error.response?.data?.error?.message;
      if (isEmptyObject(errorDetails) && !SPECIFIC_ERRORS_TO_IGNORE_NOTIFICATION.includes(errorMessage)) {
        showError({ message: errorMessage });
      }
      filterError(errorDetails);
      //TODO remove if debug component is not needed
      addError(errorMessage, errorDetails);
      const returningError = { ...error.response?.data, response: { status: error.response.status } };
      return Promise.reject(returningError);
    }
    return Promise.reject(error);
  }
);

function filterError(errorMessage) {
  if (!errorMessage) {
    return;
  }
  for (const [key, value] of Object.entries(errorMessage)) {
    console.warn(key, value);

    if (!notificationsMuted()) {
      setTimeout(function () {
        showError({ message: key + ' : ' + value });
      }, Math.random() * 1000);
    }
  }
}

export function isEmptyObject(obj) {
  return JSON.stringify(obj) === '{}';
}

// helpers
// TODO: add unit tests
export const prepareParams = (params: Object, type?: string) =>
  Object.entries(params)
    .map(([key, value]) => {
      if (type) {
        if (
          (typeof value === 'number' && value > 0) ||
          (typeof value === 'string' && value.length > 0) ||
          typeof value === 'boolean'
        ) {
          return `${type}[` + encodeURIComponent(key) + ']=' + encodeURIComponent(value);
        } else if (Array.isArray(value)) {
          return value
            .reduce((acc: Array<string>, val) => {
              acc.push(`${type}[${encodeURIComponent(key)}][]=` + encodeURIComponent(`${val}`));
              return acc;
            }, [])
            .join('&');
        } else if (value && typeof value === 'object') {
          return prepareParams(value, `${type}[${encodeURIComponent(key)}]`);
        }
        return '';
      } else if (!isObject(value)) {
        if (value === undefined) {
          return '';
        }
        if (Array.isArray(value)) {
          return value.map((val) => `${encodeURIComponent(key)}[]=${encodeURIComponent(String(val))}`).join('&');
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      } else {
        return prepareParams(value, encodeURIComponent(key));
      }
    })
    .filter((value) => value.length > 0)
    .join('&');

// TODO: check if below is used
export function objectIntoQuery(params: Object, type?: string) {
  const queryString: Array<string> = [];

  Object.keys(params).forEach((key) => {
    if (
      (typeof params[key] === 'number' && params[key] > 0) ||
      (typeof params[key] === 'string' && params[key].length > 0)
    ) {
      const result = `${type}[` + encodeURIComponent(key) + ']=' + encodeURIComponent(params[key]);
      queryString.push(result);
    } else if (Array.isArray(params[key])) {
      params[key].forEach((item) => {
        queryString.push(`${type}[${encodeURIComponent(key)}][]=${encodeURIComponent(item)}`);
      });
    } else if (typeof params[key] === 'object') {
      queryString.push(objectIntoQuery(params[key], key));
    }
  });

  return queryString.join('&');
}
interface ParamsObject {
  [key: string]: string | number | File | undefined;
}
export function transformToFormData(params: ParamsObject, typeOfParams?: string, formData = new FormData()) {
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      formData.append(typeOfParams ? `${typeOfParams}[${key}]` : key, String(value));
    }
  });
  return formData;
}

export const transformFromFormData = (formData: FormData) => {
  const result = {};
  formData.forEach((value: FormDataEntryValue, key: string) => (result[key] = value));
  return result;
};
interface CachedRequest {
  result: any;
  timestamp: number;
}

const requestsCache: Record<string, CachedRequest> = {};
const runningRequests: Record<string, Promise<any>> = {};

// Cache results of the request, avoid multiple simultaneous requests
// if request is already running
// Use for GET requests only.
export const cachedRequest = (
  config: AxiosRequestConfig,
  cacheTime = TimeToLive.FOUR_HOURS_IN_MILLISECONDS,
  _service: AxiosInstance = service
) => {
  const requestUrl = config.url;
  if (requestUrl) {
    if (requestsCache[requestUrl]?.result && Date.now() - requestsCache[requestUrl]?.timestamp < cacheTime) {
      return Promise.resolve(requestsCache[requestUrl].result);
    } else if (runningRequests[requestUrl]) {
      return runningRequests[requestUrl];
    }
    const request = _service(config).then((result) => {
      requestsCache[requestUrl] = { result, timestamp: Date.now() };
      if (runningRequests[requestUrl]) {
        delete runningRequests[requestUrl];
      }
      return result;
    });
    runningRequests[requestUrl] = request;
    return request;
  }
  return _service(config);
};

export const requestOrCache = (config: AxiosRequestConfig, cacheTime?: number, _service: AxiosInstance = service) =>
  cacheTime ? cachedRequest(config, cacheTime, _service) : _service(config);

export function getCachedResponse(fullRequestUrl: string, cacheTime = TimeToLive.FIFTEEN_MINUTES_IN_MILLISECONDS) {
  if (requestsCache[fullRequestUrl]?.result && Date.now() - requestsCache[fullRequestUrl]?.timestamp < cacheTime) {
    return requestsCache[fullRequestUrl].result;
  }
  return undefined;
}

export default service;
