//~~legacy~~
const state = () => ({
    payment: null,
  });
  
  const actions = {};
  
  const mutations = {
    setPayment: (state, payment) => {
      state.payment = payment;
    },
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };