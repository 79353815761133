import { isObject } from '@vueuse/core';
import { nullifyEmptyString } from '~/utils/string';

export const nullEmptyStringsFromURLsWith = ['address', 'execute'];
export const nullEmptyStringsFromKeyNames = ['address']; //we can add only the part of keyName that should be compared
export function nullifyEmptyStringsFromData(data) {
  const result = {};
  let values = {};
  const isFormData = data instanceof FormData;
  const isObj = isObject(data);
  if (isFormData) {
    data.forEach((value, key) => (values[key] = value));
  } else if (isObj) {
    values = data;
  }
  Object.keys(values).forEach(
    (key) =>
      (result[key] =
        isObject(data[key]) || data[key] instanceof FormData
          ? nullifyEmptyStringsFromData(data[key])
          : nullifyEmptyString(data[key]))
  );
  if (isFormData) {
    const form = new FormData();
    for (const key in result) {
      form.append(key, result[key]);
    }
    return form;
  } else if (isObj) {
    return result;
  }
  return data;
}
