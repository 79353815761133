<template>
  <div class="d-flex justify-space-between">
    <div class="text-center">
      <v-menu
        open-on-hover
        nudge-bottom="51"
        :disabled="disabled === undefined || disabled === false || !disableText"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            :width="width || '100%'"
            :plain="plain === '' || plain === true"
            :outlined="outlined === '' || outlined === true"
            :ripple="false"
            :color="buttonColor"
            :icon="icon != null"
            :class="{
              'bb-button': true,
              uppercase: uppercase === '' || uppercase === true,
              lowercase: uppercase === undefined || uppercase === false,
              plain: plain === '' || plain === true,
              icon: icon != null,
              disabled: disabled === '' || disabled === true,
              'disabled-outlined':
                (disabled === '' || disabled === true) &&
                (outlined === '' || outlined === true)
            }"
            @mouseover="hovered = true"
            @mouseleave="hovered = false"
            @mousedown="clicked = true"
            @mouseup="clicked = false"
            @click="click"
            v-on="on"
          >
            <v-img
              v-if="icon"
              :src="require(`../../../assets/${iconSrc}`)"
              :width="iconWidth"
              :height="iconHeight"
              contain
            />
            <slot />
          </v-btn>
        </template>
        <div class="disable-text">
          <span>{{ disableText }}</span>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script> //~~legacy~~
export default {
  props: [
    "type",
    "width",
    "plain",
    "outlined",
    "icon",
    "iconHover",
    "iconClick",
    "iconWidth",
    "iconHeight",
    "color",
    "uppercase",
    "disabled",
    "disableText"
  ],
  data() {
    return {
      hovered: false,
      clicked: false
    };
  },
  computed: {
    iconSrc() {
      if (this.clicked && this.iconClick) {
        return this.iconClick;
      } else if (this.hovered && this.iconHover) {
        return this.iconHover;
      } else {
        return this.icon;
      }
    },
    buttonColor() {
      if (
        (this.disabled === "" || this.disabled === true) &&
        this.outlined !== "" &&
        this.outlined !== false
      ) {
        return "#dddddd";
      } else {
        return this.color || "accent";
      }
    }
  },
  methods: {
    click() {
      if (this.disabled) {
        return;
      }
      this.$emit("click");
    }
  }
};
</script>
<style scoped>
.uppercase {
  font-weight: 500 !important;
  text-transform: uppercase !important;
  font-size: 16px !important;
}
</style>
<style lang="scss">
.bb-button {
  font-weight: normal !important;
}

.bb-button.plain {
  text-transform: none !important;
  height: unset !important;
  font-size: 16px !important;
  padding: 0 !important;
}

.bb-button:not(.plain) {
  height: 45px !important;
}

.icon {
  padding: 20px !important;
  min-width: 0 !important;
}

.lowercase:not(.plain) {
  text-transform: none !important;
  font-size: 18px !important;
}

.disabled {
  color: white !important;
  pointer-events: unset;
}

.disabled-outlined {
  opacity: 0.4;
}

.disable-text {
  font-family: "Helvetica Neue";
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 19px;
  margin-bottom: 19px;
  width: 100%;
  height: 100%;
  color: #757575;
  white-space: pre-line;
  text-align: center;
  line-height: 140%;
}
</style>
