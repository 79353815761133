//~~legacy~~
import { deleteOrder } from "@/api/orders";

const initialState = () => ({
  components: [],
  user: null,
  order: null,
  orderItems: [],
  selectedReglazingServices: [],
  newAddedOrderItems: [],
  printReceipt: false,
  voucherUsage: null,
  currentPrescriptionOrderItemIndex: 0,
  remainingItemsDelivery: "pick_up",
  addressToModify: {},
  deliveryAddress: null,
  orderEyeTest: null,
  paymentAmount: 0,
  collectionLocation: () => {
    // vgrynch: commented out to avoid warnigs as this part of the code is currently unused
    /*let salesChannel = getSalesChannel();
    if (salesChannel != null) {
      return salesChannel.sales_channel_location_relations.find(
        item => item.is_default
      ).location;
    } else {
      return null;
    }*/
    return null;
  },
  shippingPrice: 0
});

const state = initialState();

const mutations = {
  setComponents: (state, components) => {
    state.components = components;
  },
  addComponent: (state, component) => {
    state.components.unshift(component);
  },
  removeLastComponent: state => {
    state.components.shift();
  },
  setUser: (state, user) => {
    let isSameUser =
      (!state.user && !user) ||
      (state.user && user && state.user.id == user.id);
    let isAnonymous =
      state.user &&
      state.user.user_type == "anonymous" &&
      user &&
      user.user_type != "anonymous";
    state.user = user;
    if (!isSameUser && !isAnonymous) {
      state.user = user;
    }
  },
  setOrder: (state, order) => {
    state.order = order;
  },
  setshippingPrice: (state, shippingPrice) => {
    state.shippingPrice = shippingPrice;
  },
  deleteOrder: state => {
    if (state.order != null) {
      deleteOrder(state.order.id);
      state.order = null;
      state.components = [];
    }
  },
  setPaymentAmount: (state, paymentAmount) => {
    state.paymentAmount = paymentAmount;
  },
  addOrderAddress: (state, address) => {
    state.order.addresses.push(address);
  },
  removeOrderAddress: (state, addressId) => {
    state.order.addresses = state.order.addresses.filter(
      item => item.id != addressId
    );
  },
  addOrderItem: (state, orderItem) => {
    state.newAddedOrderItems.push(orderItem);
  },
  removeNewAddedOrderItem: (state, index) => {
    const removedItem = state.newAddedOrderItems[index];
    state.newAddedOrderItems = state.newAddedOrderItems.filter(
      (item, idx) => idx != index
    );
    const pwycOptions = {
      40: "Option 1",
      20: "Option 2",
      0: "Option 3"
    };
    let pwycItems = state.order.order_items.filter(
      item => item.object.type == "glass_variant"
    );
    const newPwycItems = state.newAddedOrderItems.filter(
      item => item.objectType == "GlassVariant"
    );
    pwycItems = pwycItems.concat(newPwycItems);
    let orderItems = [];
    pwycItems.forEach(item => {
      let orderItem = {};
      orderItem.new = item.id ? false : true;
      if (orderItem.new) {
        orderItem.discount = item.discount;
        orderItem.price = item.totalPrice;
      } else {
        orderItem.price = item.price;
        orderItem.id = item.id;
        let pwycDiscount = item.order_item_discounts.filter(discount => {
          return (discount.discount_type = "pay_what_you_can");
        });
        orderItem.discount =
          pwycDiscount.length > 0
            ? {
                option: pwycOptions[pwycDiscount[0].amount],
                value: pwycDiscount[0].amount
              }
            : null;
      }
      orderItems.push(orderItem);
    });
    let nonPwycItems = orderItems.filter(item => !item.discount);
    if (removedItem.discount && nonPwycItems.length > 0) {
      nonPwycItems[0].discount = removedItem.discount;
      if (nonPwycItems[0].new) {
        nonPwycItems[0].price -= removedItem.discount.value;
      }
    }
    let mappedPwycItems = orderItems.filter(item => item.discount);
    let pwycCondition = orderItems.length >= mappedPwycItems.length * 2;
    if (!pwycCondition) {
      let minPwycItem = mappedPwycItems.reduce((a, b) => {
        return a.discount.value < b.discount.value ? a : b;
      });
      if (minPwycItem) {
        minPwycItem.price += minPwycItem.discount.value;
        minPwycItem.discount = null;
      }
    }
    orderItems
      .filter(item => {
        return item.new;
      })
      .forEach((item, index) => {
        state.newAddedOrderItems[index].totalPrice = item.price;
        state.newAddedOrderItems[index].discount = item.discount;
      });
    orderItems
      .filter(item => {
        return !item.new;
      })
      .forEach(savedItem => {
        let index = state.order.order_items.findIndex(
          item => item.id == savedItem.id
        );
        if (
          index > -1 &&
          state.order.order_items[index].discount_value !=
            savedItem.discount.value
        ) {
          state.order.order_items[index].discount_value =
            savedItem.discount.value;
          state.order.order_items[index].order_item_discounts.push({
            amount: savedItem.discount.value,
            currency_code: state.order.currency_code,
            discount_type: "pay_what_you_can",
            name: `pay${savedItem.discount.value}`
          });
        }
      });
  },
  removeSavedOrderItem: (state, removedItem) => {
    const index = state.order.order_items.findIndex(e => {
      return e.id == removedItem.id;
    });
    state.order.order_items = state.order.order_items.filter(
      (item, idx) => idx != index
    );
    const pwycOptions = {
      40: "Option 1",
      20: "Option 2",
      0: "Option 3"
    };
    let pwycItems = state.order.order_items.filter(
      item => item.object.type == "glass_variant"
    );
    const newPwycItems = state.newAddedOrderItems.filter(
      item => item.objectType == "GlassVariant"
    );
    pwycItems = pwycItems.concat(newPwycItems);
    let orderItems = [];
    pwycItems.forEach(item => {
      let orderItem = {};
      orderItem.new = item.id ? false : true;
      if (orderItem.new) {
        orderItem.discount = item.discount;
        orderItem.price = item.totalPrice;
      } else {
        orderItem.price = item.price;
        orderItem.id = item.id;
        let pwycDiscount = item.order_item_discounts.filter(discount => {
          return (discount.discount_type = "pay_what_you_can");
        });
        orderItem.discount =
          pwycDiscount.length > 0
            ? {
                option: pwycOptions[pwycDiscount[0].amount],
                value: pwycDiscount[0].amount
              }
            : null;
      }
      orderItems.push(orderItem);
    });
    let nonPwycItems = orderItems.filter(item => !item.discount);
    if (removedItem.discount_value != null && nonPwycItems.length > 0) {
      if (
        orderItems.length == nonPwycItems.length &&
        nonPwycItems.length == 2
      ) {
        nonPwycItems[1].discount = {
          option: pwycOptions[removedItem.discount_value],
          value: removedItem.discount_value
        };
      } else {
        nonPwycItems[0].discount = {
          option: pwycOptions[removedItem.discount_value],
          value: removedItem.discount_value
        };
      }
      if (nonPwycItems[0].new) {
        nonPwycItems[0].price -= removedItem.discount_value;
      }
    }
    let mappedPwycItems = orderItems.filter(item => item.discount);
    let pwycCondition = orderItems.length >= mappedPwycItems.length * 2;
    if (!pwycCondition) {
      let minPwycItem = mappedPwycItems.reduce((a, b) => {
        return a.discount.value < b.discount.value ? a : b;
      });
      if (minPwycItem) {
        if (minPwycItem.new) {
          minPwycItem.price += minPwycItem.discount.value;
        }
        minPwycItem.discount = null;
      }
    }
    // let extraPwyc = mappedPwycItems.length >= nonPwycItems.length;
    orderItems
      .filter(item => {
        return item.new;
      })
      .forEach((item, index) => {
        state.newAddedOrderItems[index].totalPrice = item.price;
        state.newAddedOrderItems[index].discount = item.discount;
      });
    orderItems
      .filter(item => {
        return !item.new;
      })
      .forEach(savedItem => {
        let index = state.order.order_items.findIndex(
          item => item.id == savedItem.id
        );
        if (index > -1) {
          state.order.order_items[index].discount_value =
            savedItem.discount != null ? savedItem.discount.value : null;
          if (state.order.order_items[index].discount_value != null) {
            state.order.order_items[index].order_item_discounts.push({
              amount: savedItem.discount.value,
              currency_code: state.order.currency_code,
              discount_type: "pay_what_you_can",
              name: `pay${savedItem.discount.value}`
            });
          } else {
            const discountToDelete = state.order.order_items[
              index
            ].order_item_discounts.filter(discount => {
              return discount.discount_type == "pay_what_you_can";
            });
            if (discountToDelete.length > 0) {
              state.order.order_items[index].discountToDelete =
                discountToDelete[0];
            }
            state.order.order_items[index].order_item_discounts = [];
          }
        }
      });
  },
  setSelectedReglazingServices: (state, selectedReglazingServices) => {
    state.selectedReglazingServices = selectedReglazingServices;
  },
  addReglazingService: (state, reglazingService) => {
    state.selectedReglazingServices.push(reglazingService);
  },
  removeReglazingItem: (state, index) => {
    state.selectedReglazingServices.splice(index, 1);
  },
  setPrintReceipt: (state, printReceipt) => {
    state.printReceipt = printReceipt;
  },
  setVoucherUsage: (state, voucherUsage) => {
    state.voucherUsage = voucherUsage;
  },
  setOrderEyeTest: (state, orderEyeTest) => {
    if (orderEyeTest) {
      state.orderEyeTest = { ...orderEyeTest };
    } else {
      state.orderEyeTest = null;
    }
  },
  copyOrderEyeTestToOrderItems: state => {
    let eyeTest = state.orderEyeTest;
    state.orderItems.map((orderItem, index) => {
      if (orderItem.selectedGlassVariant.lens_variant.requires_prescription) {
        var vision_type = null;
        if (
          orderItem.lensType != null &&
          orderItem.lensType.configurationName == "reading-glasses"
        ) {
          vision_type = "RR";
        } else if (orderItem.singleVisionType != null) {
          vision_type = orderItem.singleVisionType.configurationName;
        } else if (orderItem.multifocalType != null) {
          vision_type = orderItem.multifocalType.configurationName;
        }

        var sphere = null;
        if (orderItem.readingPower != null) {
          sphere = orderItem.readingPower;
        }

        var lens_coating = null;
        if (
          orderItem.selectedGlassVariant.lens_variant.lens.colour.name ==
          "Clear"
        ) {
          if (orderItem.lens.configurationName == "classic") {
            lens_coating = "SHMC";
          } else if (orderItem.lens.configurationName == "blue-light") {
            lens_coating = "Blue light";
          } else if (orderItem.lens.configurationName == "light-responsive") {
            lens_coating = "Transitions";
          }
        } else {
          if (orderItem.lens.configurationName == "tinted") {
            lens_coating = `Sun-${orderItem.selectedGlassVariant.lens_variant.lens.colour.name}`;
          } else if (orderItem.lens.configurationName == "polarized") {
            lens_coating = `Polarized-${orderItem.selectedGlassVariant.lens_variant.lens.colour.name}`;
          }
        }

        var orderItemEyeTest;
        if (vision_type == "RR") {
          orderItemEyeTest = {
            prescription: {
              eye_measurements: [
                {
                  eye_position: "right",
                  vision_type,
                  sphere,
                  lens_coating
                },
                {
                  eye_position: "left",
                  vision_type,
                  sphere,
                  lens_coating
                }
              ]
            }
          };
        } else if (eyeTest) {
          orderItemEyeTest = {
            media: eyeTest.media,
            exam_date: eyeTest.exam_date,
            patient_date_of_birth: eyeTest.patient_date_of_birth,
            pupillary_distance: eyeTest.pupillary_distance,
            back_vertex_distance: eyeTest.back_vertex_distance,
            optometrist: eyeTest.optometrist,
            is_default: eyeTest.is_default,
            practice_name: eyeTest.practice_name,
            notes: eyeTest.notes,
            prescription: {
              expiry_date: eyeTest.prescription.expiry_date,
              eye_measurements: eyeTest.prescription.eye_measurements.map(
                item => {
                  return {
                    vision_type,
                    lens_coating,
                    eye_position: item.eye_position,
                    sphere: item.sphere,
                    cylinder: item.cylinder,
                    axis: item.axis,
                    near_addition: item.near_addition,
                    intermediate_addition: item.intermediate_addition,
                    horizontal_prism_direction: item.horizontal_prism_direction,
                    horizontal_prism_value: item.horizontal_prism_value,
                    vertical_prism_direction: item.vertical_prism_direction,
                    vertical_prism_value: item.vertical_prism_value
                  };
                }
              )
            }
          };

          if (!orderItemEyeTest.prescription) {
            orderItemEyeTest.prescription = {
              eye_measurements: []
            };
          }

          if (orderItemEyeTest.prescription.eye_measurements.length == 0) {
            orderItemEyeTest.prescription.eye_measurements = [
              {
                eye_position: "right",
                vision_type,
                lens_coating
              },
              {
                eye_position: "left",
                vision_type,
                lens_coating
              }
            ];
          }
        } else {
          orderItemEyeTest = {
            prescription: {
              eye_measurements: [
                {
                  eye_position: "right",
                  vision_type,
                  lens_coating
                },
                {
                  eye_position: "left",
                  vision_type,
                  lens_coating
                }
              ]
            }
          };
        }

        state.orderItems[index].eyeTest = orderItemEyeTest;
      }
    });
  },
  increaseCurrentPrescriptionOrderItemIndex: state => {
    state.currentPrescriptionOrderItemIndex += 1;
  },
  decreaseCurrentPrescriptionOrderItemIndex: state => {
    state.currentPrescriptionOrderItemIndex -= 1;
  },
  setRemainingItemsDelivery: (state, option) => {
    state.remainingItemsDelivery = option;
    state.orderItems.forEach(orderItem => {
      if (orderItem.location_id == null) {
        orderItem.delivery_type = option;
      }
    });
  },
  setAddressToModify: (state, address) => {
    state.addressToModify = address;
  },
  setDeliveryAddress: (state, address) => {
    state.deliveryAddress = address;
  },
  clearCart: state => {
    if (state.order != null) {
      deleteOrder(state.order.id);
    }
    Object.assign(state, { ...initialState(), user: state.user });
  },
  resetData: state => {
    Object.assign(state, { ...initialState() });
  }
};
const getters = {
  prescriptionOrderItems: state => {
    return state.newAddedOrderItems
      .filter(item => {
        return item.selectedGlassVariant.lens_variant.requires_prescription;
      })
      .concat(
        state.selectedReglazingServices.filter(item => {
          return item.requires_prescription;
        })
      );
  },
  readingGlassOrderItems: state => {
    return state.order.order_items.filter(item => {
      return (
        item.object.glass_variant.lens_variant.requires_prescription &&
        (!item.eye_test ||
          !item.eye_test.prescription ||
          item.eye_test.prescription.eye_measurements.length < 2 ||
          (item.eye_test.prescription.eye_measurements[0].vision_type == "RR" &&
            item.eye_test.prescription.eye_measurements[1].vision_type == "RR"))
      );
    });
  },
  currentPrescriptionOrderItem: (state, getters) =>
    getters.prescriptionOrderItems[state.currentPrescriptionOrderItemIndex],
  subtotalPrice: (state, getters) => {
    const newAddedItemsPrice = state.newAddedOrderItems
      .concat(state.selectedReglazingServices)
      .map(item => item.totalPrice + (item.discount?.value || 0))
      .reduce((a, b) => a + b, 0);

    const orderItemsPrice = state.order?.order_items?.length
      ? state.order?.order_items
          .map(item => {
            let orderPrice = item.price;
            if (item?.order_item_additional_charges?.[0]) {
              orderPrice += item.order_item_additional_charges.reduce(function (a,b) { return a + b.price; }, 0);
            }

            return orderPrice;
          })
          .reduce((a, b) => a + b, 0)
      : 0;
    return newAddedItemsPrice + orderItemsPrice - getters.totalPWYCAmount;
  },
  discounts: state => {
    // not including reglazing services discounts
    const newAddedItemsDiscounts = state.newAddedOrderItems
      .filter(item => item.discount != null)
      .map(item => item.discount.value)
      .reduce((a, b) => a + b, 0);
    return (
      state.order?.order_items
        .filter(item => item.discount_value != null)
        .map(item => item.discount_value)
        .reduce((a, b) => a + b, 0) + newAddedItemsDiscounts
    );
  },
  taxes: (state, getters) => {
    const totalPriceExcludingShipping = getters.subtotalPrice - getters.voucherValue;
    const goods = totalPriceExcludingShipping * ((0.049 * 6) / 1.05);
    if (totalPriceExcludingShipping <= 0) {
      return {
        goods: 0,
        dispensing: 0 + state.shippingPrice
      };
    }
    return {
      goods,
      dispensing: totalPriceExcludingShipping - goods + state.shippingPrice
    };
  },
  voucherValue: (state, getters) => {
    let voucherValue = 0;
    if (state.voucherUsage != null) {
      switch (state.voucherUsage.voucher.voucher_type) {
        case "fixed_amount":
          voucherValue = state.voucherUsage.voucher.value;
          break;
        case "percentage":
          voucherValue =
            (state.voucherUsage.voucher.value * getters.subtotalPrice) / 100;
          break;
        default:
          break;
      }
    }

    return voucherValue;
  },
  totalPrice: (state, getters) => {
    return Math.max(getters.subtotalPrice - getters.voucherValue, 0) + state.shippingPrice;
  },
  collectionAddress: state => {
    if (
      state.order.order_items.find(item => item.delivery_type == "pick_up") !=
        null &&
      state.collectionLocation() != null
    ) {
      return state.collectionLocation().addresses[0];
    }
    return null;
  },
  allPwycItems: state => {
    const pwycItems = state.order.order_items.filter(
      item => item.object.type == "glass_variant"
    );
    const newPwycItems = state.newAddedOrderItems.filter(
      item => item.objectType == "GlassVariant"
    );
    return pwycItems.concat(newPwycItems);
  },
  totalPWYCAmount: (state, getters) => {
    const pwycItems = getters.allPwycItems;
    return pwycItems.reduce((sum, item) => {
      if (item.discount) {
        sum += item.discount.value;
      }
      if (item.discount_value) {
        sum += item.order_item_discounts.reduce((sum, discount) => {
          if (discount.discount_type == "pay_what_you_can") {
            sum += discount.amount || 0;
          }
          return sum;
        }, 0);
      }
      return sum;
    }, 0);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
