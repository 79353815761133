import path from 'path';
import { RouteConfig, RouteMeta, Route } from 'vue-router/types/router';
import { isExternal } from '~/utils/string';

import { prepareParams } from '~/api/utils/request';
import OrdersIcon from '~/components/icon/sidebar/OrdersIcon.vue';
import SettingsIcon from '~/components/icon/sidebar/SettingsIcon.vue';
import FulfillmentsIcon from '~/components/icon/sidebar/FulfillmentsIcon.vue';
import LensIcon from '~/components/icon/sidebar/LensIcon.vue';
import ShipmentsIcon from '~/components/icon/sidebar/ShipmentsIcon.vue';
import CustomersIcon from '~/components/icon/sidebar/CustomersIcon.vue';
import ProductsIcon from '~/components/icon/sidebar/ProductsIcon.vue';
import DiscountsIcon from '~/components/icon/sidebar/DiscountsIcon.vue';
import BlogsIcon from '~/components/icon/sidebar/BlogsIcon.vue';
import InventoryIcon from '~/components/icon/sidebar/InventoryIcon.vue';
import AnalyticsIcon from '~/components/icon/sidebar/AnalyticsIcon.vue';
import ConnectivityIcon from '~/components/icon/sidebar/ConnectivityIcon.vue';
import SupportIcon from '~/components/icon/sidebar/SupportIcon.vue';
import GiftCardsIcon from '~/components/icon/sidebar/GiftCardsIcon.vue';
import { PermissionType } from '~/api/schema/accountConstants';
import CashDrawerIcon from '../icon/sidebar/CashDrawerIcon.vue';
import SafeIcon from '../icon/sidebar/SafeIcon.vue';
import PDToolIcon from '../icon/sidebar/PDToolIcon.vue';

export type RouteIcon =
  | string
  | {
      name: string;
      useFill: boolean;
    };

export type RouteLinkType =
  | string
  | {
      path: string;
      query: Record<string, any> | undefined;
    };

export type RouteItem = {
  hidden?: boolean;
  children?: Array<RouteItem>;
  meta?: RouteMeta & {
    title?: string;
    updatedTitle?: string;
    // icon name that must exist in the iconsByName list. Currently used for the topmost items only.
    icon?: RouteIcon;
    // whether to cache the view layout for this route (TODO: deprecated???)
    noCache?: boolean;
    // what path to highlight as currently selected menu item (active menu item)
    activeMenu?: string;
    // whether it is displayed at the bottom always-visible section instead of in the main menu list
    isBottom?: boolean;
    // if show it in setting menu (has to be a child of settings main route)
    menu?: boolean;
    // permissions that you need to gain access to this page
    roles?: Array<PermissionType>;
    // if all roles are required for access set this value to true
    requireAllRoles?: boolean;
    // always show a parent with children in submenu even if there is only one
    childrenAsSubmenu?: boolean;
    // if we add the link as a query parameter, we add name of a queryParam
    queryParam?: string;
    // show only if the path contains an active menu
    showOnlyOnActiveMenu?: boolean;
  };
  query?: Record<string, any>;
} & RouteConfig;

export const sidebarIconByName = {
  orders: OrdersIcon,
  settings: SettingsIcon,
  fulfillments: FulfillmentsIcon,
  lens: LensIcon,
  shipments: ShipmentsIcon,
  customers: CustomersIcon,
  products: ProductsIcon,
  discounts: DiscountsIcon,
  blogs: BlogsIcon,
  storeInventory: InventoryIcon,
  analytics: AnalyticsIcon,
  cashDrawer: CashDrawerIcon,
  safe: SafeIcon,
  connectivity: ConnectivityIcon,
  support: SupportIcon,
  pdtool: PDToolIcon,
  giftCards: GiftCardsIcon
};

export const getTrueRouteItem = (routeItem: RouteItem, baseRoute?: Route) => {
  if (routeItem.hidden) {
    return routeItem;
  }

  const showingChildren = routeItem.children?.filter((item) => !(item as any)?.hidden);

  if (showingChildren && showingChildren.length > 1) {
    return routeItem;
  }

  let onlyChild: RouteItem | undefined;
  if (!routeItem.meta?.childrenAsSubmenu && showingChildren?.length === 1) {
    onlyChild = {
      ...showingChildren[0],
      meta: {
        ...showingChildren[0].meta,
        icon: showingChildren[0].meta?.icon || routeItem.meta?.icon
      }
    };
  }

  return (
    onlyChild ||
    ({
      ...routeItem,
      path: routeItem.meta?.queryParam && baseRoute ? baseRoute.path : '',
      query: {
        ...(routeItem.meta?.queryParam && baseRoute ? addPathToQuery(routeItem, baseRoute) : routeItem.query)
      }
    } as RouteItem)
  );
};

export const resolvePath = (
  routePath: string,
  basePath: string,
  queryParams?: Record<string, string | number | boolean>
) => {
  let resolvedPath: string;

  if (isExternal(routePath)) {
    resolvedPath = routePath;
  } else if (isExternal(basePath)) {
    resolvedPath = basePath;
  } else {
    resolvedPath = path.resolve(basePath, routePath);
  }

  if (queryParams && Object.keys(queryParams).length > 0) {
    resolvedPath += `?${prepareParams(queryParams)}`;
  }

  return resolvedPath;
};

export const addPathToQuery = (routeItem: RouteItem, baseRoute: Route) => {
  const baseRouteQuery = {
    ...baseRoute.query
  };
  if (routeItem.meta?.queryParam) {
    baseRouteQuery[routeItem.meta.queryParam] = routeItem.path;
  }
  return baseRouteQuery;
};
