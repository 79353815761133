<template>
  <div :class="{ [innerClass]: isCategory || isDisabled }">
    <a
      v-if="!isCategory && isLinkExternal && !isDisabled"
      :href="to"
      target="_blank"
      rel="noopener"
      :class="innerClass"
      :tabindex="allowTab ? 0 : -1"
      @blur="emit('blur')"
    >
      <slot />
    </a>
    <router-link
      v-if="!isCategory && !isLinkExternal && !isDisabled"
      :to="to"
      :class="innerClass"
      :tabindex="allowTab ? 0 : -1"
      @blur.native="emit('blur')"
    >
      <slot />
    </router-link>
    <slot v-if="isCategory || isDisabled" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { CSSClass } from '~/api/schema/common';
import { isExternal } from '~/utils/string';

const props = defineProps<{
  to: string | { path: string; query: Record<string, any> };
  isCategory?: boolean;
  isDisabled?: boolean;
  innerClass?: CSSClass;
  allowTab?: boolean;
}>();

const emit = defineEmits<{ (e: 'blur') }>();

const isLinkExternal = computed(() => isExternal(typeof props.to === 'string' ? props.to : props.to.path));
</script>
