var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nav-bar flex w-full flex-nowrap items-center justify-between border-y border-r border-l",class:{
    'border-black/100 bg-black/100': _setup.isEditing,
    'border-dark1/100 bg-white/100 pr-[25px]': !_setup.isEditing,
    'border-l-0': !_setup.isMobile
  }},[_c('div',{staticClass:"flex h-full flex-row items-center"},[_c('a',{staticClass:"float-left ml-md mr-sm block rounded border p-[1px]",class:{
        'svg-override-focus-beige !border-black/100': _setup.isEditing,
        'svg-override-focus-black !border-white/100': !_setup.isEditing,
        'focus:!border-focus/100': _setup.showFocus
      },attrs:{"href":"#"},on:{"mousedown":function($event){_setup.showFocus = false},"click":function($event){$event.stopPropagation();$event.preventDefault();return _setup.toggleSideBar.apply(null, arguments)},"blur":function($event){_setup.showFocus = true}}},[_c(_setup.HamburgerIcon,{attrs:{"id":"hamburger-container","is-active":_setup.sidebar?.opened,"is-editing":_setup.isEditing}})],1),(!_setup.isEditing && !_vm.noBreadcrumbs)?_c(_setup.Breadcrumb,{staticClass:"float-left",attrs:{"id":"breadcrumb-container"}}):_vm._e()],1),(_setup.isEditing)?_c('div',{staticClass:"w-full",attrs:{"id":"navBarCenter"}}):_vm._e(),_c('div',{ref:"userMenu",staticClass:"relative h-full py-[3px]",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_setup.showUserMenu = !_setup.showUserMenu},"focusout":_setup.checkClosePopup}},[_c('div',{staticClass:"focus-inside-rounded h-full p-[1px]"},[_c('div',{staticClass:"flex h-full items-center rounded p-0.5",class:{
          'bg-black/100 hover:bg-dark1/100': _setup.isEditing,
          'cursor-pointer bg-white/100 px-[5px] hover:bg-beige/100': !_setup.isEditing,
          '!bg-dark1/100': _setup.isEditing && _setup.showUserMenu,
          '!bg-beige/100': !_setup.isEditing && _setup.showUserMenu
        }},[_c(_setup.IconButton,{staticClass:"relative !max-h-0 !min-h-0 max-w-0 !p-0 !outline-0 focus-visible:!outline-0",attrs:{"tabindex":_setup.isEditing ? -1 : 0},nativeOn:{"focus":function($event){_setup.showUserMenu = true}}}),(!_setup.isEditing)?_c(_setup.CircledImage,{attrs:{"src":_setup.administrator?.avatar || _setup.noAvatarImage}}):_vm._e(),(!_setup.isEditing && _setup.administrator)?_c('span',{staticClass:"text-body-l-reg pl-2xs text-black/100"},[_vm._v(" "+_vm._s(`${_setup.administrator.first_name}${_setup.administrator.last_name && _setup.administrator.first_name ? ' ' : ''}${ _setup.administrator.last_name }`)+" ")]):_vm._e()],1)]),(_setup.showUserMenu)?_c('div',{staticClass:"absolute -left-[1px] w-[calc(100%+2px)] cursor-pointer rounded border !border-black/0 p-[1px] text-body-xs text-black focus-within:!border-focus/100",style:({ top: `${_setup.HEADER_HEIGHT}px` })},[_c('a',{staticClass:"block w-full cursor-pointer rounded border !border-dark1/100 bg-white/100 px-[14px] py-2xs text-body-xs text-black shadow-dropdown hover:bg-beige/100 hover:!text-blue-hover/100 focus:bg-beige/100 focus:!text-blue-hover/100",attrs:{"href":"#"},on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _setup.logout.apply(null, arguments)},"click":_setup.logout}},[_vm._v(" Log out ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }