import { asyncRoutes, constantRoutes } from '@/router';
import { getPermissions } from '~/api/utils/auth';
import { PermissionValue } from '~/api/schema/accountConstants';

/**
 * Use route.meta.roles to determine if the current user has permission
 * @param permissions
 * @param route
 */
function hasMetaPermission(permissions, route) {
  if (route.meta && route.meta.roles) {
    const permissionValues = Object.values(PermissionValue);
    const roles = route.meta.roles;
    if (Array.isArray(roles)) {
      const check = (role) => {
        return !!permissions[role]?.some((perm) => permissionValues.includes(perm));
      };
      return route.meta?.requireAllRoles ? roles.every(check) : roles.some(check);
    } else if (typeof roles === 'object') {
      const strict = !!route.meta?.requireAllRoles;
      return Object.keys(roles).reduce(
        (result, type) =>
          (strict
            ? roles[type].every((value) => permissions?.[type]?.includes(value))
            : roles[type].some((value) => permissions?.[type]?.includes(value))) ||
          (strict && false) ||
          result,
        false
      );
    }
    return true;
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 */
export function filterAsyncRoutes(routes) {
  const res = [];
  const permissions = getPermissions() || {};
  routes.forEach((route) => {
    const updatedRoute = { ...route };
    if (hasMetaPermission(permissions, updatedRoute)) {
      if (updatedRoute.children) {
        updatedRoute.children = filterAsyncRoutes(updatedRoute.children);
      }
      res.push(updatedRoute);
    }
  });

  return res;
}

const state = {
  routes: [],
  addRoutes: []
};

const mutations = {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};

const actions = {
  generateRoutes({ commit }) {
    return new Promise((resolve) => {
      const accessedRoutes = filterAsyncRoutes(asyncRoutes);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
