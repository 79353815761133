//~~legacy~~
import { Message } from 'element-ui';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import getPageTitle from '@/utils/get-page-title';
import { DeviceTokenStatuses } from '~/api/schema/deviceAuthentication';
import { getDeviceTokenStatus } from '~/api/deviceTokens';
import {
  addPermissionChangeCallback,
  getToken,
  getDeviceToken,
  setDeviceToken,
  removeToken,
  removeAdministrator
} from '~/api/utils/auth';
import { generateFingerPrintToken, isGuestRoute } from '~/utils/auth';
import { NETWORK_ERROR_CODE, NETWORK_ERROR_MESSAGE } from '~/api/utils/request';
import { store } from './store';
import router, { resetRouter } from './router';

NProgress.configure({ showSpinner: false });

router.beforeEach(async (to, from, next) => {
  if (from.name !== to.name) {
    store.commit('routerState/setIsFromNewTab', from.name === null);
  }
  // saving previous path
  store.commit('routerState/setFromRoute', from.fullPath);

  // start progress bar
  NProgress.start();
  if (window) {
    global.NProgress = NProgress;
  }
  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken();

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' });
      NProgress.done();
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0;
      if (hasRoles) {
        next();
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const { roles } = await store.dispatch('user/getInfo');

          const genRoutesFn = async () => {
            resetRouter();
            // generate accessible routes map based on roles
            const accessRoutes = await store.dispatch('permission/generateRoutes', ['admin']);
            // dynamically add accessible routes
            router.addRoutes(accessRoutes);
          };

          await genRoutesFn();
          addPermissionChangeCallback(genRoutesFn);

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ path: to.query?.redirect || to.fullPath, replace: true });
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken');
          Message.error(error || 'Has Error');
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else {
    /* has no token*/
    if (isGuestRoute(to.path)) {
      // in the free login whitelist, go directly
      next();
    } else {
      console.log(to.path);
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

router.afterEach(async (to, _from) => {
  if (!isGuestRoute(to.path)) {
    const params = {
      token: getDeviceToken() || ''
    };
    if (!params.token) {
      generateFingerPrintToken().then((deviceToken) => {
        params.token = deviceToken;
        setDeviceToken(params.token);
      });
    }
    try {
      const { data } = await getDeviceTokenStatus(params);
      if (data.status !== DeviceTokenStatuses.ACTIVE) {
        removeToken();
        removeAdministrator();
        await router.push(`/login?redirect=${to.path}`);
      }
    } catch (error) {
      //TODO remove check for error message after axios version update
      if (!(error?.code === NETWORK_ERROR_CODE || error?.message === NETWORK_ERROR_MESSAGE)) {
        removeToken();
        removeAdministrator();
        await router.push(`/login?redirect=${to.path}`);
      }
      console.error(error);
    }
  }
  // finish progress bar
  NProgress.done();
});
