//~~legacy~~
const state = () => ({
  customers: null,
});

const actions = {};

const mutations = {
  setCustomer: (state, customer) => {
    state.customer = customer;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
