<template>
  <v-dialog v-model="value" width="606" persistent>
    <div class="bb-warning-message border">
      <div class="x-button-container">
        <bb-x-button class="back-button" @click="$emit('close')" />
      </div>
      <div class="warning-message">
        {{ message }}
      </div>
      <div class="action-buttons">
        <bb-button
          outlined
          width="154"
          class="no-button"
          @click="$emit('noAction')"
        >
          {{ noTitle }}
        </bb-button>
        <bb-button
          color="error"
          width="154"
          class="yes-button"
          @click="$emit('yesAction')"
        >
          {{ yesTitle }}
        </bb-button>
      </div>
    </div>
  </v-dialog>
</template>

<script> //~~legacy~~
export default {
  props: ["value", "message", "noTitle", "yesTitle"]
};
</script>

<style scoped lang="scss">
.bb-warning-message {
  background-color: #ffffff;
  .x-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 22px;
    padding-right: 12px;
  }
  .warning-message {
    white-space: pre-wrap;
    padding-top: 2px;
    font-family: "Helvetica Neue";
    font-size: 20px;
    text-align: center;
  }
  .action-buttons {
    padding-top: 34px;
    padding-bottom: 58px;
    display: flex;
    justify-content: center;
    .no-button {
      margin-right: 21px;
    }
  }
}
</style>
