<template>
  <div
    class="text-body-l-reg pointer-events-auto flex flex-row flex-nowrap items-center gap-x-xs rounded px-xs py-2xs text-white/100"
    :class="typeClasses"
    :style="{ maxWidth: `calc(min(90%, 100vw))` }"
  >
    <CheckMark v-if="options.type === NotificationTypes.INFO" class="min-w-[21px] fill-white" />
    <NotificationWarning v-else class="h-[19px] w-[19px] min-w-[19px] text-dark1" />
    <div
      v-if="messages?.length && !options.component"
      class="text-body-l-reg overflow-hidden break-words"
      :class="{
        'flex flex-col gap-1': messages?.length > 1
      }"
    >
      <span v-for="(message, index) in messages" :key="`${message}-${index}`">{{ message }}</span>
    </div>
    <component :is="options.component" v-if="options.component" v-bind="options.componentProps" :id="options.id">
      <span v-for="(message, index) in messages" :key="`${message}-${index}`">{{ message }}</span>
    </component>
    <IconButton v-if="options.showClose" class="!px-0" @click="hideNotification(options.id, true)">
      <SmallCross class="!bg-transparent !text-white/100 hover:!text-white/100" />
    </IconButton>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';

import { NotificationsOptions, NotificationTypes } from '~/utils/notificationsConstants';
import NotificationWarning from '~/components/icon/NotificationWarning.vue';
import CheckMark from '~/components/icon/CheckMark.vue';
import SmallCross from '~/components/icon/SmallCross.vue';
import IconButton from '~/components/button/IconButton.vue';
import { hideNotification } from '~/utils/notifications';

const props = defineProps<{
  options: NotificationsOptions;
}>();

const typeClasses = computed(() => (props.options.type === NotificationTypes.ERROR ? 'bg-red/100' : 'bg-dark1/100'));

const messages = computed(() =>
  props.options.multipleLinesMessage
    ? props.options.message?.split(props.options.separator || '\n')
    : [props.options.message]
);
</script>
