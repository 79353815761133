<template>
  <RouteLink
    v-if="!displayedItem.hidden && (displayedItem.meta?.title || displayedItem.meta?.icon)"
    ref="menuLinkRef"
    :to="resolvePath(displayedItem.path, basePath)"
    :allow-tab="!collapsed"
    class="w-full cursor-pointer"
    :class="{ 'focus-inside-list': showFocus, 'dropdown-octonary-disabled': isDisabled }"
    :inner-class="`whitespace-nowrap ${!isDisabled ? 'dropdown-octonary' : ''}`"
    role="menuitem"
    @mousedown.native="showFocus = false"
    @focusout.native="showFocus = true"
  >
    {{ displayedItem.meta?.updatedTitle || displayedItem.meta?.title }}
  </RouteLink>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import RouteLink from './RouteLink.vue';
import { getTrueRouteItem, resolvePath, RouteItem } from './sidebarUtils';

const props = withDefaults(
  defineProps<{
    item: RouteItem;
    collapsed?: boolean;
    basePath?: string;
    isDisabled?: boolean;
  }>(),
  {
    basePath: ''
  }
);

const displayedItem = computed(() => getTrueRouteItem(props.item));

// to show focus by keyboard only, we set this to false on mouse clicks and restore to true when focus left
const showFocus = ref(true);
</script>
