<template>
  <div id="bb-switch-button">
    <v-switch
      :disabled="disabled"
      v-model="on"
      inset
      :ripple="false"
      hide-details
    ></v-switch>
  </div>
</template>

<script> //~~legacy~~
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      on: this.value || false
    };
  },
  watch: {
    on(val) {
      this.$emit("input", val);
      this.$emit("change", this.on);
    }
  }
};
</script>
