//~~legacy~~
const initialState = () => ({
  resetPasswordEmail: null,
  otp: null,
});

const state = initialState();

const mutations = {
  SET_RESET_PASSWORD_EMAIL: (state, resetPasswordEmail) => {
    state.resetPasswordEmail = resetPasswordEmail;
  },
  SET_OTP: (state, otp) => {
    state.otp = otp;
  },
};

const getters = {
  resetPasswordEmail: (state) => {
    return state.resetPasswordEmail;
  },
  otp: (state) => {
    return state.otp;
  },
};

const actions = {
  setResetPasswordEmail: ({ commit }, resetPasswordEmail) => {
    commit("SET_RESET_PASSWORD_EMAIL", resetPasswordEmail);
  },
  setOtp: ({ commit }, otp) => {
    commit("SET_OTP", otp);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
