//~~legacy~~
import { deleteOrder } from "@/api/orders";

const initialState = () => ({
  components: [],
  user: null,
  order: null,
  orderItems: [],
  selectedReglazingServices: [],
  printReceipt: false,
  voucherUsage: null,
  currentPrescriptionOrderItemIndex: 0,
  remainingItemsDelivery: "pick_up",
  addressToModify: {},
  deliveryAddress: null,
  orderEyeTest: null,
  collectionLocation: () => {
    // vgrynch: commented out to avoid warnigs as this part of the code is currently unused
    /* let salesChannel = getSalesChannel();
    if (salesChannel != null) {
      return salesChannel.sales_channel_location_relations.find(
        item => item.is_default
      ).location;
    } else {
      return null;
    }*/
    return null;
  },
  shippingPrice: 0
});

const state = initialState();

const mutations = {
  setComponents: (state, components) => {
    state.components = components;
  },
  addComponent: (state, component) => {
    state.components.unshift(component);
  },
  removeLastComponent: state => {
    state.components.shift();
  },
  setUser: (state, user) => {
    let isSameUser =
      (!state.user && !user) ||
      (state.user && user && state.user.id == user.id);
    let isAnonymous =
      state.user &&
      state.user.user_type == "anonymous" &&
      user &&
      user.user_type != "anonymous";
    state.user = user;
    if (!isSameUser && !isAnonymous && state.order != null) {
      deleteOrder(state.order.id);
      state.order = null;
      state.components = [];
    }
  },
  setOrder: (state, order) => {
    state.order = order;
  },
  setshippingPrice: (state, shippingPrice) => {
    state.shippingPrice = shippingPrice;
  },
  deleteOrder: state => {
    if (state.order != null) {
      deleteOrder(state.order.id);
      state.order = null;
      state.components = [];
    }
  },
  addOrderAddress: (state, address) => {
    state.order.addresses.push(address);
  },
  removeOrderAddress: (state, addressId) => {
    state.order.addresses = state.order.addresses.filter(
      item => item.id != addressId
    );
  },
  addOrderItem: (state, orderItem) => {
    state.orderItems.push(orderItem);
    if (state.order != null) {
      deleteOrder(state.order.id);
      state.order = null;
      state.components = [];
    }
  },
  removeOrderItem: (state, index) => {
    let item = state.orderItems.splice(index, 1)[0];
    let nonPwycItems = state.orderItems.filter(item => !item.discount);

    if (item.discount && nonPwycItems.length > 0) {
      nonPwycItems[0].discount = item.discount;
      nonPwycItems[0].totalPrice -= item.discount.value;
    }

    let pwycItems = state.orderItems.filter(item => item.discount);
    let pwycCondition = state.orderItems.length >= pwycItems.length * 2;

    if (!pwycCondition) {
      let minPwycItem = pwycItems.reduce((a, b) => {
        return a.discount.value < b.discount.value ? a : b;
      });
      if (minPwycItem) {
        minPwycItem.totalPrice += minPwycItem.discount.value;
        minPwycItem.discount = null;
      }
    }

    /*     if (state.order != null) {
      deleteOrder(state.order.id);
      state.order = null;
      state.components = [];
    } */
  },
  setSelectedReglazingServices: (state, selectedReglazingServices) => {
    state.selectedReglazingServices = selectedReglazingServices;
  },
  addReglazingService: (state, reglazingService) => {
    state.selectedReglazingServices.push(reglazingService);
  },
  removeReglazingItem: (state, index) => {
    state.selectedReglazingServices.splice(index, 1);
  },
  setPrintReceipt: (state, printReceipt) => {
    state.printReceipt = printReceipt;
  },
  setVoucherUsage: (state, voucherUsage) => {
    state.voucherUsage = voucherUsage;
  },
  setOrderEyeTest: (state, orderEyeTest) => {
    if (orderEyeTest) {
      state.orderEyeTest = { ...orderEyeTest };
    } else {
      state.orderEyeTest = null;
    }
  },
  copyOrderEyeTestToOrderItems: state => {
    let eyeTest = state.orderEyeTest;
    state.orderItems.map((orderItem, index) => {
      if (orderItem.selectedGlassVariant.lens_variant.requires_prescription) {
        var vision_type = null;
        if (
          orderItem.lensType != null &&
          orderItem.lensType.configurationName == "reading-glasses"
        ) {
          vision_type = "RR";
        } else if (orderItem.singleVisionType != null) {
          vision_type = orderItem.singleVisionType.configurationName;
        } else if (orderItem.multifocalType != null) {
          vision_type = orderItem.multifocalType.configurationName;
        }

        var sphere = null;
        if (orderItem.readingPower != null) {
          sphere = orderItem.readingPower;
        }

        var lens_coating = null;
        if (
          orderItem.selectedGlassVariant.lens_variant.lens.colour.name ==
          "Clear"
        ) {
          if (orderItem.lens.configurationName == "classic") {
            lens_coating = "SHMC";
          } else if (orderItem.lens.configurationName == "blue-light") {
            lens_coating = "Blue light";
          } else if (orderItem.lens.configurationName == "light-responsive") {
            lens_coating = "Transitions";
          }
        } else {
          if (orderItem.lens.configurationName == "tinted") {
            lens_coating = `Sun-${orderItem.selectedGlassVariant.lens_variant.lens.colour.name}`;
          } else if (orderItem.lens.configurationName == "polarized") {
            lens_coating = `Polarized-${orderItem.selectedGlassVariant.lens_variant.lens.colour.name}`;
          }
        }

        var orderItemEyeTest;
        if (vision_type == "RR") {
          orderItemEyeTest = {
            prescription: {
              eye_measurements: [
                {
                  eye_position: "right",
                  vision_type,
                  sphere,
                  lens_coating
                },
                {
                  eye_position: "left",
                  vision_type,
                  sphere,
                  lens_coating
                }
              ]
            }
          };
        } else if (eyeTest) {
          orderItemEyeTest = {
            media: eyeTest.media,
            exam_date: eyeTest.exam_date,
            patient_date_of_birth: eyeTest.patient_date_of_birth,
            pupillary_distance: eyeTest.pupillary_distance,
            back_vertex_distance: eyeTest.back_vertex_distance,
            optometrist: eyeTest.optometrist,
            is_default: eyeTest.is_default,
            practice_name: eyeTest.practice_name,
            notes: eyeTest.notes,
            prescription: {
              expiry_date: eyeTest.prescription.expiry_date,
              eye_measurements: eyeTest.prescription.eye_measurements.map(
                item => {
                  return {
                    vision_type,
                    lens_coating,
                    eye_position: item.eye_position,
                    sphere: item.sphere,
                    cylinder: item.cylinder,
                    axis: item.axis,
                    near_addition: item.near_addition,
                    intermediate_addition: item.intermediate_addition,
                    horizontal_prism_direction: item.horizontal_prism_direction,
                    horizontal_prism_value: item.horizontal_prism_value,
                    vertical_prism_direction: item.vertical_prism_direction,
                    vertical_prism_value: item.vertical_prism_value
                  };
                }
              )
            }
          };

          if (!orderItemEyeTest.prescription) {
            orderItemEyeTest.prescription = {
              eye_measurements: []
            };
          }

          if (orderItemEyeTest.prescription.eye_measurements.length == 0) {
            orderItemEyeTest.prescription.eye_measurements = [
              {
                eye_position: "right",
                vision_type,
                lens_coating
              },
              {
                eye_position: "left",
                vision_type,
                lens_coating
              }
            ];
          }
        } else {
          orderItemEyeTest = {
            prescription: {
              eye_measurements: [
                {
                  eye_position: "right",
                  vision_type,
                  lens_coating
                },
                {
                  eye_position: "left",
                  vision_type,
                  lens_coating
                }
              ]
            }
          };
        }

        state.orderItems[index].eyeTest = orderItemEyeTest;
      }
    });
  },
  increaseCurrentPrescriptionOrderItemIndex: state => {
    state.currentPrescriptionOrderItemIndex += 1;
  },
  decreaseCurrentPrescriptionOrderItemIndex: state => {
    state.currentPrescriptionOrderItemIndex -= 1;
  },
  setRemainingItemsDelivery: (state, option) => {
    state.remainingItemsDelivery = option;
    state.orderItems.forEach(orderItem => {
      if (orderItem.location_id == null) {
        orderItem.delivery_type = option;
      }
    });
  },
  setAddressToModify: (state, address) => {
    state.addressToModify = address;
  },
  setDeliveryAddress: (state, address) => {
    state.deliveryAddress = address;
  },
  clearCart: state => {
    if (state.order != null) {
      deleteOrder(state.order.id);
    }
    Object.assign(state, { ...initialState(), user: state.user });
  },
  resetData: state => {
    Object.assign(state, { ...initialState() });
  }
};

const getters = {
  prescriptionOrderItems: state => {
    return state.orderItems
      .filter(item => {
        return item.selectedGlassVariant.lens_variant.requires_prescription;
      })
      .concat(
        state.selectedReglazingServices.filter(item => {
          return item.requires_prescription;
        })
      );
  },
  readingGlassOrderItems: state => {
    return state.order.order_items.filter(item => {
      return (
        item.object.glass_variant.lens_variant.requires_prescription &&
        (!item.eye_test ||
          !item.eye_test.prescription ||
          item.eye_test.prescription.eye_measurements.length < 2 ||
          (item.eye_test.prescription.eye_measurements[0].vision_type == "RR" &&
            item.eye_test.prescription.eye_measurements[1].vision_type == "RR"))
      );
    });
  },
  currentPrescriptionOrderItem: (state, getters) =>
    getters.prescriptionOrderItems[state.currentPrescriptionOrderItemIndex],
  subtotalPrice: (state, getters) => {
    const addedItemsPrice = state.orderItems
      .concat(state.selectedReglazingServices)
      .map(item => item.totalPrice + (item.discount?.value || 0))
      .reduce((a, b) => a + b, 0);
    return addedItemsPrice - getters.totalPWYCAmount;
  },
  allPwycItems: state => {
    return state.orderItems.filter(item => item.objectType == "GlassVariant");
  },
  totalPWYCAmount: (state, getters) => {
    const pwycItems = getters.allPwycItems;
    return pwycItems.reduce((sum, item) => {
      if (item.discount) {
        sum += item.discount.value;
      }
      return sum;
    }, 0);
  },
  taxes: (state, getters) => {
    const totalPriceExcludingShipping = getters.subtotalPrice - getters.voucherValue;
    const goods = totalPriceExcludingShipping * ((0.049 * 6) / 1.05);
    if (totalPriceExcludingShipping <= 0) {
      return {
        goods: 0,
        dispensing: 0 + state.shippingPrice
      };
    }
    return {
      goods,
      dispensing: totalPriceExcludingShipping - goods + state.shippingPrice
    };
  },
  voucherValue: (state, getters) => {
    var voucherValue = 0;
    if (state.voucherUsage != null) {
      switch (state.voucherUsage.voucher.voucher_type) {
        case "fixed_amount":
          voucherValue = state.voucherUsage.voucher.value;
          break;
        case "percentage":
          voucherValue =
            (state.voucherUsage.voucher.value * getters.subtotalPrice) / 100;
          break;
        default:
          break;
      }
    }

    return voucherValue;
  },
  totalPrice: (state, getters) => {
    return Math.max(getters.subtotalPrice - getters.voucherValue, 0) + state.shippingPrice;
  },
  collectionAddress: state => {
    if (
      state.order.order_items.find(item => item.delivery_type == "pick_up") !=
        null &&
      state.collectionLocation() != null
    ) {
      return state.collectionLocation().addresses[0];
    }

    return null;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
