<template>
  <div class="card border">
    <div v-if="title != null" class="card-header">
      <span class="card-title">{{ title }}</span>
      <button
        v-if="button"
        @click="$emit('buttonClicked')"
        class="secondary-button card-button"
      >
        {{ button }}
      </button>
    </div>
    <slot />
  </div>
</template>

<script> //~~legacy~~
export default {
  props: ["title", "button"]
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  background-color: white;
  .card-header {
    display: flex;
    margin: 24px 22px 0 22px;
    .card-title {
      flex: 1;
      font-family: "Helvetica Neue";
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      text-align: left;
    }
    .card-button {
      width: auto;
      text-align: right;
    }
  }
}
</style>
