var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.items == null)?_c('v-text-field',{ref:"text-field",class:{
      'v-text-field--outlined-light-border': _vm.lightGreyBorder != null,
      'v-text-field--outlined-rounded': _vm.rounded != null,
      'v-text-field--outlined-error-border': _vm.errors != null && _vm.errors.length > 0
    },attrs:{"outlined":"","disabled":_vm.disabled,"hide-details":"","height":"60","validate-on-blur":"","name":_vm.name,"autofocus":_vm.autofocus != null,"rules":_vm.rules,"type":_vm.type,"label":_vm.label,"prefix":_vm.prefix,"placeholder":_vm.placeholder,"error":_vm.errors != null && _vm.errors.length > 0},on:{"input":function($event){return _vm.setText($event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.optional != null)?_c('div',{staticClass:"optional"},[_vm._v(" Optional ")]):_vm._e()]},proxy:true}],null,false,3467313231),model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}):_c('v-autocomplete',{ref:"text-field",class:{
      'v-text-field--outlined-light-border': _vm.lightGreyBorder != null,
      'v-text-field--outlined-rounded': _vm.rounded != null,
    },attrs:{"outlined":"","disabled":_vm.disabled,"hide-details":"","height":"58","validate-on-blur":"","autofocus":_vm.autofocus != null,"rules":_vm.rules,"label":_vm.label,"prefix":_vm.prefix,"placeholder":_vm.placeholder,"items":_vm.items,"item-text":_vm.itemText,"item-value":_vm.itemValue,"error":_vm.errors != null && _vm.errors.length > 0,"filter":_vm.getList},on:{"input":function($event){return _vm.setText($event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.optional != null)?_c('div',{staticClass:"optional"},[_vm._v(" Optional ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),(_vm.details != null)?_c('div',{staticClass:"details"},[_vm._v(" "+_vm._s(_vm.details)+" ")]):_vm._e(),(_vm.errors != null)?_c('div',{staticClass:"errors"},[_vm._v(" "+_vm._s(_vm.errors.join(", "))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }