<template>
  <v-app>
    <div
      class="bb-menu-main-div d-flex justify-center"
      @mouseover="showMenu = true"
      @mouseleave="showMenu = false"
    >
      <button
        class="bb-menu-main-btn"
        :class="showMenu && items.length > 0 ? 'bb-menu-main-btn-open' : ''"
      >
        {{ title }}
        <img src="../../../icons/svg/dropdown.svg" />
      </button>
      <div v-if="showMenu" class="bb-menu-btns-div">
        <button
          v-for="(item, index) in items"
          @click="itemSelected(index)"
          :key="item.title"
          :disabled="item.disabled"
          class="bb-menu-btns"
        >
          {{ item.title }}
        </button>
      </div>
    </div>
  </v-app>
</template>
<script> //~~legacy~~
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      showMenu: false
    };
  },
  methods: {
    itemSelected(value) {
      this.$emit("select", value);
    }
  }
};
</script>
<style lang="scss" scoped>
.bb-menu-btns {
  color: #444444;
  padding-left: 15px;
  border-left: 1px solid #b3b3b3;
  border-right: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  box-sizing: border-box;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);
  display: flex;
  width: 174px;
  height: 34px;
  align-items: center;
  background: #ffffff;
  font-family: "Helvetica Neue";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.51px;
  letter-spacing: 0em;
  &:nth-last-child(1) {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
.bb-menu-btns:hover {
  background-color: #f7f7f7;
}
.bb-menu-btns:active {
  background-color: white;
}
.bb-menu-btns:disabled {
  color: #b3b3b3;
}
.bb-menu-main-btn {
  color: #000000;
  position: relative;
  padding-left: 15px;
  border: 1px solid #b3b3b3;
  box-sizing: border-box;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);
  display: flex;
  border-radius: 3px;
  width: 174px;
  height: 42px;
  align-items: center;
  background: #ffffff;
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  img {
    position: absolute;
    right: 12px;
  }
}
.bb-menu-main-div {
  width: 174px;
  margin-left: auto;
  position: relative;
  z-index: 1;
}
.bb-menu-btns-div {
  position: absolute;
  margin-top: 42px;
}
.bb-menu-main-btn-open {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
