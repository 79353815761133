<template>
  <v-app>
    <div class="wrapper">
      <button class="option" @click="clickHandler">
        {{ getValue() }}
        <span :class="showMenu && options.length > 0 ? 'arrow open' : 'arrow'">
          <svg
            width="18"
            height="18"
            viewBox="0 0 13 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L6.5 6L12 1"
              stroke="#002fa7"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </button>
      <div v-if="showMenu" class="menu">
        <button
          v-for="item in options"
          @click="itemSelected(item.value)"
          :key="item.value"
          :disabled="item.disabled"
          :class="
            item.disabled ? 'option menu-option disabled' : 'option menu-option'
          "
        >
          {{ item.label }}
        </button>
      </div>
    </div>
  </v-app>
</template>
<script> //~~legacy~~
export default {
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      showMenu: false
    };
  },
  beforeMount() {
    window.addEventListener("click", () => (this.showMenu = false));
  },
  beforeDestroy() {
    window.removeEventListener("click", () => (this.showMenu = false));
  },
  methods: {
    getValue() {
      if (!this.value) {
        return this.placeholder;
      }
      return (
        this.options.find(({ value }) => value === this.value)?.label || ""
      );
    },
    itemSelected(value) {
      this.$emit("onChange", value);
      this.showMenu = false;
    },
    clickHandler(event) {
      event.stopPropagation();
      this.showMenu = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  position: relative;
}
.option {
  color: #000000;
  position: relative;
  padding: 15px;
  border: 1px solid #b3b3b3;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  width: 100%;
  height: 60px;
  align-items: center;
  background: #ffffff;
  font-family: "Helvetica Neue";
  font-size: 16px;
}
.menu-option {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #b3b3b3;
  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-bottom: 1px;
  }
  &:hover {
    background-color: #fafafa;
  }
}
.menu {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 3px;
  border: 1px solid #b3b3b3;
  margin-top: -1px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  z-index: 500;
}
.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}
.open {
  transform: rotate(180deg);
}
.disabled {
  color: grey !important;
  &:hover {
    background-color: #ffffff;
  }
}
</style>
