<template>
  <LegacyStyles>
    <ToastNotifications is-top />
    <div id="app-container" :class="classObj" class="app-wrapper">
      <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
      <Sidebar />
      <div
        :class="{ hasTagsView: needTagsView, mobile: device === 'mobile', 'main-borders': !noBordersPage }"
        class="main-container"
      >
        <div :class="{ 'fixed-header': fixedHeader }">
          <navbar :no-breadcrumbs="showSettingsDrawer" />
          <tags-view v-if="needTagsView" />
        </div>
        <app-main v-if="!showSettingsDrawer" />
      </div>
    </div>
    <SlidingDrawer
      v-model="showSettingsDrawer"
      from="bottom"
      :no-animation="!settingsBackValue?.name"
      :free-area-with-unit="`${SETTINGS_TRANSFORM_VALUE}px`"
      :close-delay="250"
      @close="handleSettingsClose"
    >
      <template #title>Settings</template>
      <SettingsLayout @close-drawer="handleSettingsClose" />
    </SlidingDrawer>
    <ToastNotifications />
  </LegacyStyles>
</template>

<script>
//~~legacy~~
import { mapState } from 'vuex';
import ToastNotifications from '~/components/utility/ToastNotifications.vue';
import { DEFAULT_CURRENCY_CODE } from '~/api/schema/currencies';
import { getDeviceTokenStatus } from '~/api/deviceTokens';
import { getDeviceToken, removeToken, removeAdministrator } from '~/api/utils/auth';
import { DeviceTokenStatuses } from '~/api/schema/deviceAuthentication';
import SlidingDrawer from '~/components/utility/SlidingDrawer.vue';
import { isGuestRoute } from '~/utils/auth';
import { NETWORK_ERROR_CODE, NETWORK_ERROR_MESSAGE } from '~/api/utils/request';
import SettingsLayout from '@/layout/components/SettingsLayout.vue';
import LegacyStyles from '@/components/LegacyStyles.vue';
import { createOrder, updateOrder } from '@/api/orders';
import { createAnonymousUser } from '@/api/users';
import { fetchSalesChannels } from '@/api/salesChannel';
import { SETTINGS_TRANSFORM_VALUE } from '@/components/settingsPage/settingsPageTypes';
import Sidebar from './components/Sidebar.vue';
import { AppMain, Navbar, TagsView } from './components';
import ResizeMixin from './mixin/ResizeHandler';

const NO_BORDER_PAGES = ['/login'];

export default {
  name: 'Layout',
  components: {
    SettingsLayout,
    SlidingDrawer,
    ToastNotifications,
    LegacyStyles,
    AppMain,
    Navbar,
    Sidebar,
    TagsView
  },
  mixins: [ResizeMixin],
  async beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.path.toLowerCase().includes('settings')) {
        vm.showSettingsDrawer = true;
        if (!vm.settingsBackValue?.name && !from.path.toLowerCase().includes('settings')) {
          vm.settingsBackValue = from;
        }
      }
      if (to.query.create_order) {
        vm.isLoading = true;
        vm.onCreateOrder().then((url) => {
          next({ path: url || '/' });
          vm.isLoading = false;
        });
      }
      // access to component public instance via `vm`
    });
  },
  data() {
    return {
      isLoading: false,
      showSettingsDrawer: false,
      noBordersPage: true,
      settingsBackValue: undefined,
      checkDeviceTokenInterval: null,
      SETTINGS_TRANSFORM_VALUE
    };
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      needTagsView: (state) => state.settings.tagsView,
      fixedHeader: (state) => state.settings.fixedHeader
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  },
  mounted() {
    this.showSettingsDrawer = this.$route.path.toLowerCase().includes('settings');
    this.noBordersPage = NO_BORDER_PAGES.includes(this.$route.path);
    this.checkDeviceTokenInterval = setInterval(() => {
      if (!isGuestRoute(this.$route.fullPath)) {
        getDeviceTokenStatus({ token: getDeviceToken() || '' })
          .then(({ data }) => {
            if (data.status !== DeviceTokenStatuses.ACTIVE) {
              removeToken();
              removeAdministrator();
              this.$router.push(`/login?redirect=${this.$route.fullPath}`);
            }
          })
          .catch((error) => {
            //TODO remove check for error message after axios version update
            if (!(error?.code === NETWORK_ERROR_CODE || error?.message === NETWORK_ERROR_MESSAGE)) {
              removeToken();
              removeAdministrator();
              this.$router.push(`/login?redirect=${this.$route.fullPath}`);
            }
            console.error(error);
          });
      }
    }, 30000);
  },
  beforeUnmount() {
    if (this.checkDeviceTokenInterval) {
      clearInterval(this.checkDeviceTokenInterval);
    }
  },
  methods: {
    handleSettingsClose() {
      this.$router.push(this.settingsBackValue || { name: 'orders' }).catch((_e) => {});
      this.showSettingsDrawer = false;
      this.settingsBackValue = undefined;
    },
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false });
    },
    async getAllSalesChannels() {
      const response = await fetchSalesChannels();
      const webChannel = response.data.sales_channels.filter((channel) => channel.configuration_name === 'website');
      return webChannel[0].id;
    },
    async createDraftOrder(sales_channel_id) {
      const { data } = await createAnonymousUser();
      this.$store.commit('orderDraft/setUser', data.user);
      this.anonymousUser = data.user;
      const params = {
        status: 'draft',
        currency_code: DEFAULT_CURRENCY_CODE,
        user_id: this.$store.state.orderDraft.user?.id,
        sales_channel_id,
        discount_value: 0,
        shipping_cost: 0
      };
      const response = await createOrder(params);
      this.$store.commit('orderDraft/setOrder', response.data.order);
      return response;
    },
    async onCreateOrder() {
      try {
        const salesChannelId = await this.getAllSalesChannels();
        const { data } = await this.createDraftOrder(salesChannelId);
        await updateOrder(data.order.id, {
          order: { order_items_attributes: [] }
        });
        return `/orders/${data.order.id}?newOrder=true`;
      } catch (error) {
        console.error(error);
        return '/';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.scss';

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.main-borders {
  border-right: 1px solid black;

  &.mobile {
    border-left: 1px solid black;
  }

  &::after {
    content: ' ';
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: black;
    z-index: 1150; /* above loading indication, below modals and sidebars */
  }

  &::before {
    content: ' ';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: black;
    z-index: 1150; /* above loading indication, below modals and sidebars */
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - #{$sideBarWidthCollapsed});
}

.mobile .fixed-header {
  width: 100%;
}

.confirmation-message-close-button {
  margin-right: 20px;
}
</style>
