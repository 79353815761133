<template>
  <section class="app-main" :class="{ '!opacity-0': $route.fullPath.startsWith('/settings') }">
    <transition :name="settingsTransition ? 'settings-transform' : 'fade-transform'" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
//~~legacy~~
export default {
  name: 'AppMain',
  data() {
    return {
      settingsTransition: false
    };
  },
  watch: {
    key(newVal) {
      if (newVal.startsWith('/settings')) {
        this.settingsTransition = true;
      } else if (!this.settingsTransition && window) {
        window.setTimeout(() => (this.settingsTransition = false), 500);
      }
    }
  },
  mounted() {
    this.settingsTransition = this.key.startsWith('/settings');
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    }
  }
};
</script>

<style lang="scss" scoped>
.app-main {
  min-height: 100dvh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #f6f6f7;
}

.fixed-header + .app-main {
  padding-top: $headerHeight;
}

.hasTagsView {
  .app-main {
    min-height: calc(100dvh - 84px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
