const mutations = {
  setFromRoute: (state, fromRoute) => {
    state.fromRoute = fromRoute;
  },
  setIsFromNewTab: (state, value) => {
    state.isFromNewTab = value;
  }
};

const actions = {};

const getters = {
  fromRoute: (state) => state.fromRoute,
  isFromNewTab: (state) => state.isFromNewTab
};

export default {
  namespaced: true,
  state: {
    fromRoute: null,
    isFromNewTab: true
  },
  mutations,
  actions,
  getters
};
