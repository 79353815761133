<template>
  <svg :class="directionTransformMap[direction]" width="12" height="7" viewBox="0 0 12 7" fill="none">
    <path
      d="M1 1L6 5.28571L11 1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="stroke-current text-dark1/100"
    />
  </svg>
</template>
<script setup lang="ts">
import { Directions } from '../constants';

withDefaults(
  defineProps<{
    direction?: Directions;
  }>(),
  {
    direction: Directions.DOWN
  }
);

const directionTransformMap = {
  [Directions.DOWN]: '',
  [Directions.UP]: 'rotate-180 ',
  [Directions.LEFT]: 'rotate-90 ',
  [Directions.RIGHT]: '-rotate-90 '
};
</script>
