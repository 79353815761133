export const DEFAULT_CURRENCY_CODE = 'GBP';
export const DEFAULT_CURRENCY_SYMBOL = '£';

export type Currency = {
  id?: number;
  name: string;
  configuration_name: string;
  code: string;
  symbol: string;
};

export type CurrencyList = Array<Currency>;

export enum VariablePriceCategoryName {
  ADDITIONAL_CHARGE_OPTIONS = 'additional_charge_options',
  DISCOUNT_OPTIONS = 'discount_options'
}

export enum VariablePriceSubcategoryName {
  SINGLE_VISION_BRANDS = 'single_vision_brands',
  MULTIFOCAL_BRANDS = 'multifocal_brands',
  LENS_INDEX = 'lens_index',
  PRISM_CHARGE = 'prism_charge',
  PWYC = 'pwyc'
}

export const variablePriceSubcategoryParent = {
  [VariablePriceSubcategoryName.SINGLE_VISION_BRANDS]: VariablePriceCategoryName.ADDITIONAL_CHARGE_OPTIONS,
  [VariablePriceSubcategoryName.MULTIFOCAL_BRANDS]: VariablePriceCategoryName.ADDITIONAL_CHARGE_OPTIONS,
  [VariablePriceSubcategoryName.LENS_INDEX]: VariablePriceCategoryName.ADDITIONAL_CHARGE_OPTIONS,
  [VariablePriceSubcategoryName.PRISM_CHARGE]: VariablePriceCategoryName.ADDITIONAL_CHARGE_OPTIONS,
  [VariablePriceSubcategoryName.PWYC]: VariablePriceCategoryName.DISCOUNT_OPTIONS
};

export enum VariablePriceName {
  BLOOBLOOM = 'bloobloom',
  ZEISS = 'zeiss',
  ZEISS_PLUS = 'zeiss_plus',
  ZEISS_PREMIUM = 'zeiss_premium',
  THICKNESS_1_5 = '1.5',
  THICKNESS_1_6 = '1.6',
  THICKNESS_1_67 = '1.67',
  THICKNESS_1_74 = '1.74',
  SPECIALTY = 'specialty',
  NO = 'no',
  YES = 'yes',
  PAY_0 = 'pay0',
  PAY_20 = 'pay20',
  PAY_40 = 'pay40'
}

export type VariablePrice = {
  parameter: VariablePriceName;
  price: number;
  currency_code: string;
};

export type VariablePriceList = Array<VariablePrice>;

export type VariablePriceCategory = {
  category: VariablePriceCategoryName;
  subcategories: Array<{
    subcategory: VariablePriceSubcategoryName;
    parameters: Array<VariablePrice>;
  }>;
};

export type VariablePriceCategoryList = Array<VariablePriceCategory>;
