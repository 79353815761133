//~~legacy~~
const state = () => ({
  lens: null,
  lensVariant: null,
});

const actions = {};

const mutations = {
  setLens: (state, lens) => {
    state.lens = lens;
  },
  setLensVariant: (state, lensVariant) => {
    state.lensVariant = lensVariant;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
