//~~legacy~~

const theme = '#002FA7';

export default {
  'color-blue': '#324157',
  'color-light-blue': '#3a71a8',
  'color-red': '#c03639',
  'color-pink': '#e65d6e',
  'color-green': '#30b08f',
  'color-tiffany': '#4ab7bd',
  'color-yellow': '#fec171',
  theme,
  // sidebar
  menuText: '#464e5f',
  menuActiveText: theme,
  subMenuActiveText: theme,

  menuBg: '#ffffff',
  menuHover: '#f3f6f9',

  subMenuBg: '#ffffff',
  subMenuHover: '#f3f6f9',

  sideBarWidth: '227px',
  sideBarWidthCollapsed: '76px',

  '--color-primary': '#002FA7'
};
