//~~legacy~~
const initialState = () => ({
  components: ["refund-allocation"],
  order: null,
  fulfillments: [],
  selectedOrderItems: [[]],
  refundRequest: null,
  maximumAllowedRefundValue: 0,
  refundOptions: [],
  refundInitiated: false,
  editReason: "",
  smallestPwycDiscount: 0
});

const state = initialState();

const mutations = {
  setComponents: (state, components) => {
    state.components = components;
  },
  addComponent: (state, component) => {
    state.components.unshift(component);
  },
  removeLastComponent: state => {
    state.components.shift();
  },
  setOrder: (state, order) => {
    state.order = order;
  },
  setFulfillments: (state, fulfillments) => {
    state.fulfillments = fulfillments;
    var array = [];
    for (var i = 0; i <= fulfillments.length; i++) {
      array.push([]);
    }
    state.selectedOrderItems = array;
  },
  setRefundRequest: (state, refundRequest) => {
    state.refundRequest = refundRequest;
  },
  setMaximumAllowedRefundValue: (state, maximumAllowedRefundValue) => {
    state.maximumAllowedRefundValue = maximumAllowedRefundValue;
  },
  setRefundOptions: (state, refundOptions) => {
    state.refundOptions = refundOptions;
  },
  setRefundInitiated: (state, refundInitiated) => {
    state.refundInitiated = refundInitiated;
  },
  setEditReason: (state, editReason) => {
    state.editReason = editReason;
  },
  setSelectedOrderItems: (state, selectedOrderItems) => {
    state.selectedOrderItems = selectedOrderItems;
  },
  setSmallestPwycDiscount: (state, smallestPwycDiscount) => {
    state.smallestPwycDiscount = smallestPwycDiscount;
  },
  resetData: state => {
    Object.assign(state, initialState());
  }
};

const getters = {
  canceledOrderItems: state => {
    var orderItems = [];
    state.fulfillments.forEach((fulfillment, fulfillmentIndex) => {
      fulfillment.order_items.forEach((orderItem, index) => {
        if (state.selectedOrderItems[fulfillmentIndex][index]) {
          orderItems.push(orderItem);
        }
      });
    });
    return orderItems;
  },
  currentOrderDeliveryType: state => {
    var option;
    state.fulfillments.forEach(fulfillment => {
      fulfillment.order_items.forEach(orderItem => {
        if (!orderItem.location) {
          option = orderItem.delivery_type;
        }
      });
    });
    return option;
  },
  orderItems: state => {
    var items = [];
    state.fulfillments.forEach(fulfillment => {
      fulfillment.order_items.forEach(orderItem => {
        items.push(orderItem);
      });
    });
    return items;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
