//~~legacy~~
import request from "@/utils/request";

// users

export function fetchUsers(query) {
  return request({
    url: `/users?${query}`,
    method: "get",
  });
}

export function fetchUser(userId) {
  return request({
    url: `/users/${userId}`,
    method: "get",
  });
}

export function updateUser(userId, params) {
  return request({
    url: `/users/${userId}`,
    method: "put",
    data: params,
  });
}

export function createUser(params) {
  return request({
    url: "/users",
    method: "post",
    data: params,
  });
}

export function createAnonymousUser() {
  return request({
    url: "/users/create_anonymous",
    method: "post",
  });
}

export function deleteUser(userId) {
  return request({
    url: `/users/${userId}`,
    method: "delete",
  });
}

// timeline

export function fetchTimelines(userId) {
  return request({
    url: `/users/${userId}/timeline`,
    method: "get",
  });
}

export function createComment(userId, params) {
  return request({
    url: `/users/${userId}/comments`,
    method: "post",
    data: params,
  });
}

export function createCommentMedia(orderId, commentId, params) {
  return request({
    url: `/orders/${orderId}/comments/${commentId}/media`,
    method: "post",
    data: params,
  });
}
export function deleteComment(userId, commentId) {
  return request({
    url: `/users/${userId}/comments/${commentId}`,
    method: "delete",
  });
}
// address

export function fetchAddresses(params, userId) {
  return request({
    url: `/users/${userId}/addresses`,
    method: "get",
    data: params,
  });
}

export function fetchAddress(userId, addressId) {
  return request({
    url: `/users/${userId}/addresses/${addressId}`,
    method: "get",
  });
}

export function updateAddress(userId, addressId, params) {
  return request({
    url: `/users/${userId}/addresses/${addressId}`,
    method: "put",
    data: params,
  });
}

export function createAddress(userId, params) {
  return request({
    url: `/users/${userId}/addresses`,
    method: "post",
    data: params,
  });
}

export function deleteAddress(userId) {
  return request({
    url: `/users/${userId}/addresses/${addressId}`,
    method: "delete",
  });
}
