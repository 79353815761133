//~~legacy~~
import request from "@/utils/request";


export function fetchSalesChannels(query) {
  return request({
    url: `/sales_channels?${query}`,
    method: "get",
    data: query,
  });
}

export function fetchSalesChannel(salesChannelId) {
  return request({
    url: `/sales_channels/${salesChannelId}`,
    method: "get",
  });
}

export function updateSalesChannel(salesChannelId, params) {
  return request({
    url: `/sales_channels/${salesChannelId}`,
    method: "put",
    data: params,
  });
}

export function deleteSalesChannel(salesChannelId) {
  return request({
    url: `/sales_channels/${salesChannelId}`,
    method: "delete",
  });
}

export function createSalesChannels(params) {
  return request({
    url: "/sales_channels",
    method: "post",
    data: params,
  });
}

//  collections

export function deleteCollection(salesChannelId, collectionId) {
  return request({
    url: `/sales_channels/${salesChannelId}/collections/${collectionId}`,
    method: "delete",
  });
}
export function createCollection(salesChannelId, collectionId) {
  return request({
    url: `/sales_channels/${salesChannelId}/collections/${collectionId}`,
    method: "post",
  });
}

// locations

export function deleteLocation(salesChannelId, locationId) {
  return request({
    url: `/sales_channels/${salesChannelId}/locations/${locationId}`,
    method: "delete",
  });
}
export function createLocation(salesChannelId, locationId, params) {
  return request({
    url: `/sales_channels/${salesChannelId}/locations/${locationId}`,    
    method: "post",
    data: params,
  });
}

//glasses

export function fetchHTOGlasses(salesChannelId,query) {
  return request({
    url: `/sales_channels/${salesChannelId}/glasses?${query}`,
    method: "get",
  });
}

export function fetchGlasses(salesChannelId,query) {
  return request({
    url: `/sales_channels/${salesChannelId}/glasses?${query}`,
    method: "get",
  });
}
export function getGlass(salesChannelId, glassId) {
  return request({
    url: `/sales_channels/${salesChannelId}/glasses/${glassId}`,
    method: "get",
  });
}
