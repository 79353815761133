import { AxiosPromise } from 'axios';
import { ResponseMeta } from './schema/common';
import request, { prepareParams, requestOrCache } from './utils/request';
import Fulfillment, { FulfillmentList, FulfillmentNote } from './schema/fulfillment';
import {
  FulfillmentDeliveryTypes,
  FulfillmentMissingElementTypes,
  FulfillmentStatuses,
  FulfillmentTypes,
  FulfilmentsStats
} from './schema/fulfillmentConstants';
import { ShipmentStatuses } from './schema/shipmentConstants';
import { DestockReasons, LocalOrderItemObjectTypes } from './schema/orderItemConstants';
import { ShipmentExt } from './schema/shipment';

export interface FulfillmentsFetchingParams {
  page?: {
    number?: number;
    limit?: number;
  };
  filters?: {
    missing_element?: FulfillmentMissingElementTypes;
    missing_element_not_in?: FulfillmentMissingElementTypes;
    order_item_delivery_type?: FulfillmentDeliveryTypes;
    order_item_object_type?: LocalOrderItemObjectTypes;
    order_reference_like?: string;
    reference_like?: string;
    shipment_status?: ShipmentStatuses;
    shipment_tracking_code_like?: string;
    statuses?: Array<FulfillmentStatuses>;
    tray_number_like?: string;
    order_user_email_like?: string;
    order_user_name_like?: string;
    fulfillment_type?: Array<FulfillmentTypes>;
    is_to_action?: boolean;
    delivery_types?: Array<FulfillmentDeliveryTypes>;
  };
}

export interface ShipmentsFetchingParams {
  page?: {
    number?: number;
    limit?: number;
  };
  filters?: {
    statuses?: Array<ShipmentStatuses>;
    fulfillment_statuses?: Array<FulfillmentStatuses>;
    not_fulfillment_statuses?: Array<FulfillmentStatuses>;
    order_item_delivery_type?: FulfillmentDeliveryTypes;
    order_item_object_type?: LocalOrderItemObjectTypes;
  };
}

export type FulfillmentStatusTotals = Record<
  string,
  {
    total: number;
    missing_elements_breakdown: Record<string, number>;
  }
>;

export type ShipmentStatusTotals = Record<string, number>;

export interface FulfillmentBulkUpdate {
  id: number;
  incoming_shipment_status?: ShipmentStatuses;
  outgoing_shipment_status?: ShipmentStatuses;
  status?: FulfillmentStatuses;
  destock_reason?: DestockReasons;
  missing_element?: FulfillmentMissingElementTypes;
}

export interface FulfillmentBulkUpdates {
  fulfillments_data: Array<FulfillmentBulkUpdate>;
}

export const fetchAllFulfillments = (
  query?: Record<string, any>,
  cacheTime?: number
): AxiosPromise<{
  fulfillments: FulfillmentList;
  meta: ResponseMeta;
}> =>
  requestOrCache(
    {
      url: `/fulfillments${query ? `?${prepareParams(query)}` : ''}`,
      method: 'get'
    },
    cacheTime
  );

export function fetchFulfilmentsStats(): AxiosPromise<{
  stats: FulfilmentsStats;
}> {
  return request({
    url: '/fulfillments/stats',
    method: 'get'
  });
}

export const fetchFulfillments = (
  query: FulfillmentsFetchingParams,
  cacheTime?: number
): AxiosPromise<{ fulfillments: Array<Fulfillment>; meta: ResponseMeta }> =>
  requestOrCache(
    {
      url: `/fulfillments?${prepareParams(query)}`,
      method: 'get'
    },
    cacheTime
  );

export const fetchFulfillmentTotals = (
  query: FulfillmentsFetchingParams
): AxiosPromise<{ status_total_counts: FulfillmentStatusTotals }> =>
  request({
    url: `/fulfillments/status_total_counts?${prepareParams(query)}`,
    method: 'get'
  });

export const fetchShipments = (
  query: ShipmentsFetchingParams,
  cacheTime?: number
): AxiosPromise<{ shipments: Array<ShipmentExt>; meta: ResponseMeta }> =>
  requestOrCache(
    {
      url: `/shipments?${prepareParams(query)}`,
      method: 'get'
    },
    cacheTime
  );

export const fetchShipmentTotals = (
  query: ShipmentsFetchingParams
): AxiosPromise<{ status_total_counts: ShipmentStatusTotals }> =>
  request({
    url: `/shipments/status_total_counts?${prepareParams(query)}`,
    method: 'get'
  });

export const bulkUpdateFulfillments = (
  params: FulfillmentBulkUpdates
): AxiosPromise<{ status_total_counts: ShipmentStatusTotals }> =>
  request({
    url: `/fulfillments/bulk_update`,
    method: 'put',
    data: params
  });

export const fetchFulfillment = (orderId: number | string, fulfillmentId: number | string) =>
  request({
    url: `/orders/${orderId}/fulfillments/${fulfillmentId}`,
    method: 'get'
  });

export const addFulfillmentNote = (
  fulfillmentId: number | string,
  note: { fulfillment_note: Partial<FulfillmentNote> }
) =>
  request({
    url: `/fulfillments/${fulfillmentId}/fulfillment_notes`,
    method: 'post',
    data: note
  });

export const deleteFulfillmentNote = (fulfillmentId: number | string, noteId: number) =>
  request({
    url: `/fulfillments/${fulfillmentId}/fulfillment_notes/${noteId}`,
    method: 'delete'
  });

export const getPrintLabels = (orderId: number, fulfilmentId: number) =>
  request({
    url: `/orders/${orderId}/fulfillments/${fulfilmentId}/shipments/print_labels`,
    method: 'get',
    responseType: 'blob'
  });

export const getNextFulfillmentForProcessing = (
  query: FulfillmentsFetchingParams
): AxiosPromise<{ fulfillment: Fulfillment }> =>
  request({
    url: `/fulfillments/next_to_process?${prepareParams(query)}`,
    method: 'get'
  });
