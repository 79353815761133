export const ROWS_PER_PAGE = 30;

export const FIRST_PAGE = { number: 1, limit: ROWS_PER_PAGE };

export enum RequestSortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export const ISODateFormat = 'YYYY-MM-DD';

export const DRAG_AND_DROP_DURATION_MS = 350;

export const EMPTY_RESULT_MESSAGE = 'Oops. There aren’t any matches for your search.';
export const NO_DATA_AVAILABLE = 'No data available';
