var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isShown && (_setup.displayedItem.meta?.title || _setup.displayedItem.meta?.updatedTitle || _setup.displayedItem.meta?.icon))?_c('li',{ref:"menuItemRef",staticClass:"cursor-pointer",class:{
    'pt-2xs': !_vm.nested,
    'pb-2xs': !_vm.nested && !_setup.showSubmenu,
    'pb-[5px]': !_vm.nested && _setup.showSubmenu,
    'py-[5px] first:pt-2xs': _vm.nested && !_vm.small,
    'mb-px pt-1 first:pt-2xs': _vm.nested && _vm.small,
    'max-w-5': _vm.isCollapse
  },attrs:{"role":"menuitem"}},[_c(_setup.RouteLink,{ref:"menuLinkRef",staticClass:"min-w-5 px-0.5",class:[
      _setup.linkClasses,
      {
        'focus-inside-rounded': _setup.showFocus,
        'mx-2': !_vm.small,
        'mx-px': _vm.small
      }
    ],attrs:{"to":_setup.link,"is-disabled":_setup.isDisabled,"is-category":_setup.isCategory,"allow-tab":(_vm.isOpened || _vm.isCollapse) && (!_vm.nested || !_vm.collapsed),"inner-class":_vm.linkInnerClasses},on:{"blur":function($event){_setup.showFocus = true}},nativeOn:{"!click":function($event){return ((event) => _setup.handleMenuClick(false, event)).apply(null, arguments)},"mouseover":function($event){return _setup.handleMenuPopupDisplay(false)},"mouseout":function($event){return _setup.handleMenuPopupHide.apply(null, arguments)},"mousedown":function($event){_setup.showFocus = false}}},[(!_vm.nested && _setup.displayedItem.meta?.icon)?_c(_setup.SidebarIcon,{attrs:{"icon":_setup.displayedItem.meta?.icon}}):_c('div'),_c(_setup.PostAnimateShowing,{class:{ 'col-span-2 overflow-hidden text-ellipsis': !!_setup.displayedItem.meta?.updatedTitle },attrs:{"show":!_vm.isCollapse || _vm.nested}},[_c('span',{staticClass:"whitespace-nowrap",class:{
          'text-body-l-reg': !_vm.nested,
          'text-body-l-light': _vm.nested
        }},[_vm._v(" "+_vm._s(_setup.displayedItem.meta?.updatedTitle || _setup.displayedItem.meta?.title)+" ")])]),(_setup.isCategory)?_c(_setup.PostAnimateShowing,{staticClass:"flex",attrs:{"show":!_vm.isCollapse || _vm.nested}},[_c(_setup.IconButton,{staticClass:"relative !max-h-0 !min-h-0 max-w-0 !p-0 !outline-0 focus-visible:!outline-0",class:{ '-left-25': _vm.isCollapse },attrs:{"tabindex":"0"},nativeOn:{"focus":function($event){return _setup.handleMenuClick(true)},"focusout":function($event){return _setup.checkClosePopup.apply(null, arguments)},"blur":function($event){_setup.showFocus = true}}}),_c(_setup.SubmenuArrow,{class:{
          'h-[5px] w-2': _vm.small
        },attrs:{"direction":_setup.showSubmenu ? _setup.Directions.UP : _setup.Directions.DOWN}})],1):_vm._e()],1),(_setup.isCategory && !_setup.isCollapseChanging && !_vm.isCollapse)?_c('ul',{staticClass:"sidebar-menu-in overflow-hidden bg-white pl-0",style:({
      maxHeight: _setup.showSubmenu && _setup.children ? `${_setup.children.length * _setup.SUBMENU_ITEM_HEIGHT + 10}px` : '0'
    })},_vm._l((_setup.children),function(child,index){return _c('SidebarItem',{key:child.path,attrs:{"nested":"","collapsed":!_setup.showSubmenu,"item":child,"small":_vm.small,"base-path":_setup.resolvePath(child.path, _vm.basePath),"is-opened":_vm.isOpened},on:{"click":(event, link) => _setup.emit('click', event, link)},nativeOn:{"focusout":function($event){return ((event) => index === _setup.children.length - 1 && _setup.checkClosePopup(event)).apply(null, arguments)}}})}),1):_vm._e(),(_setup.isCategory && !_setup.isCollapseChanging && _vm.isCollapse)?_c('ul',{ref:"popupRef",staticClass:"sidebar-menu-popup absolute left-[81px] z-[2001] min-w-[153px] origin-[left_18px] rounded bg-white shadow-dropdown",class:{
      'scale-100 opacity-100': _setup.showPopup,
      'scale-0 opacity-0': !_setup.showPopup
    },on:{"mouseover":function($event){return _setup.handleMenuPopupDisplay(false)},"mouseout":_setup.handleMenuPopupHide,"click":_setup.handlePopupClick}},_vm._l((_setup.children),function(child,index){return _c(_setup.PopupItem,{key:child.path,attrs:{"collapsed":!_setup.showPopup,"item":child,"base-path":_setup.resolvePath(child.path, _vm.basePath)},nativeOn:{"focusout":function($event){return ((event) => index === _setup.children.length - 1 && _setup.checkClosePopup(event)).apply(null, arguments)}}})}),1):_vm._e(),(!_setup.isCategory && !_setup.props.nested && _vm.isCollapse)?_c('div',{staticClass:"sidebar-menu-toast pointer-events-none absolute left-[81px] z-[2001] min-w-[153px] rounded bg-dark1 py-2xs px-xs text-body-xs text-white/100 opacity-0",class:{ 'opacity-100': _setup.showPopup },style:({ top: `${_setup.popupTop}px` })},[_vm._v(" "+_vm._s(_setup.displayedItem.meta?.title)+" ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }