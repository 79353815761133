<template>
  <div
    class="sidebar-wrapper sidebar-index t-0 b-0 l-0 fixed flex h-full min-h-54 flex-col border-l border-dark1/100 bg-white"
    :class="{
      'sidebar-wrapper-collapsed': isCollapse && !isMobile,
      'sidebar-wrapper-mobile': isMobile && !isOpened,
      'sidebar-wrapper-open': isMobile && isOpened,
      '!transition-none': isMobileSwitching
    }"
  >
    <div
      class="relative flex h-12.5 min-h-12.5 items-center justify-center overflow-hidden border-y"
      :class="{
        'border-black/100 bg-black/100': isEditing,
        'border-dark1/100 bg-white/100': !isEditing,
        'border-r': isMobile
      }"
    >
      <PostAnimateShowing class="absolute" :show="isCollapse" hide-slower>
        <RouteLink
          to="/"
          class="flex justify-center"
          :inverted="isEditing"
          use-link
          is-simple
          :class="`svg-override${isEditing ? '-inverted' : ''}`"
        >
          <BlooBloomLogo />
        </RouteLink>
      </PostAnimateShowing>
      <PostAnimateShowing class="absolute" :show="!isCollapse" hide-slower>
        <RouteLink
          to="/"
          class="flex justify-center"
          :inverted="isEditing"
          use-link
          is-simple
          :class="`svg-override${isEditing ? '-inverted' : ''}`"
        >
          <AdminLogo />
        </RouteLink>
      </PostAnimateShowing>
    </div>
    <ScrollingContainer
      class="flex-1 border-r border-dark1/100 pt-4.5"
      scroller-class="sidebar-scrollbar overflow-x-hidden pb-4.5"
      :css-height="`max(calc(100dvh - ${HEADER_HEIGHT}px - 80px), 86px)`"
    >
      <ul role="menu" class="pr-with-scroll-sm text-body-l-reg flex flex-col pl-sm">
        <SidebarItem
          v-for="route in mainRoutes"
          :key="route.path"
          :item="route"
          :popup-top-shift="HEADER_HEIGHT - 8"
          :base-path="route.path"
          :is-collapse="isCollapse"
          :is-opened="isOpened"
          @toggle="handleItemToggle"
        />
      </ul>
    </ScrollingContainer>
    <div class="min-h-20 overflow-hidden border-r border-b border-dark1/100">
      <ul role="menu" class="text-body-l-reg flex flex-col px-sm pt-xs pb-sm">
        <SidebarItem
          v-for="route in bottomRoutes"
          :key="route.path"
          :item="route"
          :popup-top-shift="-8"
          :base-path="route.path"
          :is-collapse="isCollapse"
          :is-opened="isOpened"
          @toggle="handleItemToggle"
        />
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import ScrollingContainer from '~/components/layout/ScrollingContainer.vue';
import PostAnimateShowing from '~/components/layout/PostAnimateShowing.vue';
import BlooBloomLogo from '~/components/icon/BlooBloomLogo.vue';
import AdminLogo from '~/components/icon/AdminLogo.vue';
import { getTrueRouteItem, RouteItem } from '~/components/sidebar/sidebarUtils';
import SidebarItem from '~/components/sidebar/SidebarItem.vue';
import RouteLink from '~/components/sidebar/RouteLink.vue';
import { fetchFulfilmentsStats } from '~/api/fulfillments';
import { FulfillmentStatuses, FulfillmentTypes, FulfilmentsStats } from '~/api/schema/fulfillmentConstants';
import { HEADER_HEIGHT } from './layoutConstants';

// Cyclic import dependencies resolving via dynamic import
const useStore = ref<Function>();
import('@/store').then((res) => {
  useStore.value = res.default;
});

const ANIMATION_DURATION = 300;

const store = computed(() => useStore.value && useStore.value());

const sidebar = computed(() => store.value?.getters['sidebar']);
const isMobile = computed(() => store.value?.getters['device'] === 'mobile');
const isOpened = computed(() => sidebar.value?.opened);
const isCollapse = computed(() => !isOpened.value && !isMobile.value);
const routes = computed(() => store.value?.getters['permission_routes'] as Array<RouteItem>);
const isEditing = computed(() => store.value?.getters['navBarEditing']);

const isMobileSwitching = ref(false);

// temporary disable animations when switching between mobile/non-mobile to avoid extra screen flickering
watch(isMobile, () => {
  isMobileSwitching.value = true;
  setTimeout(() => (isMobileSwitching.value = false), ANIMATION_DURATION);
});

const mainRoutes = computed(
  (): Array<RouteItem> =>
    routes.value?.reduce((finalRoutes, route) => {
      const routeItem = getTrueRouteItem(route);
      if (route.path === FULFILMENT_ROUTE_VALUE && route?.children?.length && fulfilmentStats.value) {
        const amounts = fulfilmentStats.value[FulfillmentStatuses.PENDING].types_breakdown;
        route.children = route.children.map((childRoute) => {
          const amount = childRoute?.meta?.fulfilmentTypes?.reduce(
            (count: number, type: FulfillmentTypes) => (type in amounts ? count + amounts[type] : count),
            0
          );
          const updatedTitle =
            amount === undefined ? childRoute?.meta?.title || '' : `${childRoute?.meta?.title} (${amount})` || '';
          return {
            ...childRoute,
            meta: {
              ...childRoute?.meta,
              updatedTitle
            }
          };
        });
      }
      if (!routeItem.hidden && !routeItem.meta?.isBottom) {
        return [...finalRoutes, route];
      }
      return finalRoutes;
    }, [] as Array<RouteItem>) || []
);
const bottomRoutes = computed(() => routes.value?.filter((route) => getTrueRouteItem(route).meta?.isBottom));

const openedItems = ref<Array<string>>([]);
watch(isOpened, (val) => {
  if (!val) {
    openedItems.value = [];
  }
});
function handleItemToggle(path: string, isOpen: boolean) {
  if (isOpen) {
    openedItems.value = [...openedItems.value, path];
  } else {
    openedItems.value = openedItems.value.filter((item) => item !== path);
  }
}
const FULFILMENT_ROUTE_VALUE = '/fullfilments';
watch(openedItems, async () => {
  await updateFulfilmentsCount(openedItems.value.includes(FULFILMENT_ROUTE_VALUE));
});

const fulfilmentStats = ref<FulfilmentsStats>();
const fulfilmentStatsInterval = ref();
const FETCH_FULFILMENT_STATS_INTERVAL = 5000;
const loadingFulfilmentStats = ref(false);
async function setFulfilmentStats() {
  try {
    loadingFulfilmentStats.value = true;
    const { data } = await fetchFulfilmentsStats();
    fulfilmentStats.value = data.stats;
    loadingFulfilmentStats.value = false;
  } catch (error) {
    console.log(error);
  }
}
async function updateFulfilmentsCount(enable: boolean) {
  if (window) {
    if (enable && !fulfilmentStatsInterval.value && !loadingFulfilmentStats.value) {
      await setFulfilmentStats();
      fulfilmentStatsInterval.value = window.setInterval(setFulfilmentStats, FETCH_FULFILMENT_STATS_INTERVAL);
    } else if (!enable && fulfilmentStatsInterval.value) {
      window.clearInterval(fulfilmentStatsInterval.value);
      fulfilmentStatsInterval.value = undefined;
    }
  }
}
</script>
<style lang="scss">
.sidebar-wrapper {
  max-width: $sideBarWidth;
  min-width: $sideBarWidth;
  transition: all 0.29s;

  &-collapsed {
    max-width: $sideBarWidthCollapsed;
    min-width: $sideBarWidthCollapsed;
  }

  &-mobile {
    position: fixed;
    pointer-events: none;
    transform: translate3d(-$sideBarWidth, 0, 0);
  }

  &-open {
    position: fixed;
  }
}
/*
Add padding for scroll bar by adding invisible border at the top and bottom of the scrolling thumb
*/
.sidebar-scrollbar {
  &::-webkit-scrollbar-thumb {
    @apply rounded bg-light2 bg-clip-padding;
    border-top: 10px solid rgba(0, 0, 0, 0);
    border-bottom: 28px solid rgba(0, 0, 0, 0);
    min-height: 58px;
  }
}
</style>
