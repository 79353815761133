//~~legacy~~
const state = () => ({
    eyeTest: null,
  });
  
  const actions = {};
  
  const mutations = {
    seteyeTest: (state, eyeTest) => {
      state.eyeTest = eyeTest;
    },
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };