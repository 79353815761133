<template>
  <div v-if="isIconExternal" :style="styleExternalIcon" class="svg-external-icon" />
  <component
    :is="svgComponent"
    v-else
    :class="{
      'fill-current': icon?.useFill,
      'stroke-current': !icon?.useFill
    }"
  />
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { RouteIcon, sidebarIconByName } from './sidebarUtils';
import { isExternal } from '~/utils/string';

const props = defineProps<{
  icon?: RouteIcon;
}>();

const isIconExternal = computed(
  () => props.icon && isExternal(typeof props.icon === 'string' ? props.icon : props.icon.name)
);

const styleExternalIcon = computed(() => ({
  mask: `url(${props.icon}) no-repeat 50% 50%`,
  '-webkit-mask': `url(${props.icon}) no-repeat 50% 50%`
}));

const svgComponent = computed(
  () => (props.icon && sidebarIconByName[typeof props.icon === 'string' ? props.icon : props.icon.name]) || ''
);
</script>
<style lang="scss">
/* TODO: figure out if we still need to support external links in side menu */
/* below came from the legacy code */
.svg-external-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
</style>
