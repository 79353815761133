<template>
  <div class="title-view">
    <bb-back-button
      class="title-view-btns"
      v-if="showBackButton"
      @click="$emit('back')"
    />
    <div
      :class="{
        'padding-right': !showCloseButton,
        'padding-left': !showBackButton
      }"
      class="title-view-title"
    >
      <div>{{ title }}</div>
    </div>
    <bb-x-button
      class="title-view-btns"
      v-if="showCloseButton"
      @click="close"
    />
    <bb-warning-message
      v-model="showCloseWarningDialog"
      :message="
        'Are you sure you want to leave?\nThe changes you made will be lost.'
      "
      noTitle="NO"
      yesTitle="YES"
      @close="showCloseWarningDialog = false"
      @noAction="showCloseWarningDialog = false"
      @yesAction="close"
    />
  </div>
</template>

<script> //~~legacy~~
export default {
  props: {
    showBackButton: {
      type: Boolean,
      default: true
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    showCloseWarning: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showCloseWarningDialog: false
    };
  },
  methods: {
    close() {
      if (this.showCloseWarningDialog) {
        this.showCloseWarningDialog = false;
        this.$emit("close");
      } else if (this.showCloseWarning) {
        this.showCloseWarningDialog = true;
      } else {
        this.$emit("close");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title-view {
  width: 100%;
  height: 82px !important;
  border-bottom: 1px solid #b3b3b3;
  display: flex;
  background-color: white;
  align-items: center;
  .title-view-title {
    color: rgba(0, 0, 0, 1);
    flex: 1;
    text-align: center;
    font-family: "Helvetica Neue";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
  }
  .padding-right {
    padding-right: 72px;
  }
  .padding-left {
    padding-left: 72px;
  }
  .x-button {
    .x-button-img {
      width: 20px;
      height: 20px;
      margin-right: 18px;
    }
  }
}

.close-warning-dialog {
  width: 338px;
  background-color: #ffffff;
  padding: 40px 45px 46px 45px;
  .close-warning-dialog-text {
    font-family: "Goudy Old Style";
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: black;
  }
  .close-warning-dialog-buttons-container {
    display: flex;
    margin-top: 27px;
    justify-content: center;
    .close-warning-dialog-buttons-yes-button {
      width: 79px;
      margin-right: 33px;
    }
    .close-warning-dialog-buttons-no-button {
      width: 79px;
    }
  }
}
.title-view-btns {
  background: white !important;
  padding-left: 28px !important;
  padding-right: 28px !important;
}
</style>
