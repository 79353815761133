//~~legacy~~
const state = () => ({
  newSelectedVariants: [],
  newRemovedVariants: [],
  currentVariants: [],
  showItemsError: true
});
const mutations = {
  setNewSelectedVariants: (state, newSelectedVariants) => {
    state.newSelectedVariants = newSelectedVariants;
  },
  setNewRemovedVariants: (state, newRemovedVariants) => {
    state.newRemovedVariants = newRemovedVariants;
  },
  setCurrentVariants: (state, currentVariants) => {
    state.currentVariants = currentVariants;
  },
  setShowItemsError: (state, value) => {
    state.showItemsError = value;
  },
  resetData: state => {
    state.newSelectedVariants = [];
    state.newRemovedVariants = [];
  }
};
export default {
  namespaced: true,
  state,
  mutations
};
