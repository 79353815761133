<template>
  <div class="flex grow">
    <ul class="w-full max-w-58 border-r border-solid border-black bg-white px-5 pt-7 pb-md">
      <SidebarItem
        v-for="settingsRoute in settingsRoutes"
        :key="settingsRoute.name"
        :item="settingsRoute"
        is-opened
        small
        base-path="settings"
        class="!pt-[3px] !pb-[11px]"
        :open-submenu="
          settingsRoute.children?.some((child) => route.path === child.path || route.path.includes(child.path))
        "
        link-inner-classes="!grid grid-cols-[0px_auto_12px] items-center no-underline hover:no-underline"
      />
    </ul>
    <div
      ref="viewWrapper"
      class="relative grow"
      style="height: calc(100vh - 101px)"
      :class="{
        'overflow-y-hidden': !pageInitialized,
        'overflow-y-auto': pageInitialized
      }"
    >
      <div class="mx-auto min-h-50">
        <router-view @scroll-to-top="scrollWrapperToTop" @initialized="(value) => (pageInitialized = value)" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { Location } from 'vue-router';
import { useRoute, useRouter } from 'vue-router/composables';
import SidebarItem from '~/components/sidebar/SidebarItem.vue';
import { RouteItem } from '~/components/sidebar/sidebarUtils';

// Cyclic import dependencies resolving via dynamic import
const useStore = ref<Function>();
import('@/store').then((res) => {
  useStore.value = res.default;
});

const route = useRoute();
const router = useRouter();

const pageInitialized = ref(false);
const store = computed(() => useStore.value && useStore.value());

const emit = defineEmits<{ (e: 'close-drawer') }>();
const viewWrapper = ref<HTMLDivElement>();

const settingsRoutes = computed<RouteItem[]>(
  (): Array<RouteItem & { query?: Record<string, string | number | boolean> }> =>
    (store.value?.getters['permission_routes'] as Array<RouteItem>)
      ?.find((parent) => parent?.name?.toLowerCase() === 'settings')
      ?.children?.filter((child) => child?.meta?.menu) || []
);

function scrollWrapperToTop() {
  if (viewWrapper.value) {
    viewWrapper.value.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

watch(settingsRoutes, (value) => {
  if (route.name === 'settings' && value.length && value[0].name !== route.name) {
    router.replace({ name: value[0].name, query: value[0]?.query } as Partial<Location>);
  }
});

watch(route, ({ name }) => {
  if (
    settingsRoutes.value?.length &&
    !settingsRoutes.value?.some((item) => name?.startsWith(item.name || 'settings'))
  ) {
    emit('close-drawer');
  }
});
</script>
