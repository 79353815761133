var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sidebar-wrapper sidebar-index t-0 b-0 l-0 fixed flex h-full min-h-54 flex-col border-l border-dark1/100 bg-white",class:{
    'sidebar-wrapper-collapsed': _setup.isCollapse && !_setup.isMobile,
    'sidebar-wrapper-mobile': _setup.isMobile && !_setup.isOpened,
    'sidebar-wrapper-open': _setup.isMobile && _setup.isOpened,
    '!transition-none': _setup.isMobileSwitching
  }},[_c('div',{staticClass:"relative flex h-12.5 min-h-12.5 items-center justify-center overflow-hidden border-y",class:{
      'border-black/100 bg-black/100': _setup.isEditing,
      'border-dark1/100 bg-white/100': !_setup.isEditing,
      'border-r': _setup.isMobile
    }},[_c(_setup.PostAnimateShowing,{staticClass:"absolute",attrs:{"show":_setup.isCollapse,"hide-slower":""}},[_c(_setup.RouteLink,{staticClass:"flex justify-center",class:`svg-override${_setup.isEditing ? '-inverted' : ''}`,attrs:{"to":"/","inverted":_setup.isEditing,"use-link":"","is-simple":""}},[_c(_setup.BlooBloomLogo)],1)],1),_c(_setup.PostAnimateShowing,{staticClass:"absolute",attrs:{"show":!_setup.isCollapse,"hide-slower":""}},[_c(_setup.RouteLink,{staticClass:"flex justify-center",class:`svg-override${_setup.isEditing ? '-inverted' : ''}`,attrs:{"to":"/","inverted":_setup.isEditing,"use-link":"","is-simple":""}},[_c(_setup.AdminLogo)],1)],1)],1),_c(_setup.ScrollingContainer,{staticClass:"flex-1 border-r border-dark1/100 pt-4.5",attrs:{"scroller-class":"sidebar-scrollbar overflow-x-hidden pb-4.5","css-height":`max(calc(100dvh - ${_setup.HEADER_HEIGHT}px - 80px), 86px)`}},[_c('ul',{staticClass:"pr-with-scroll-sm text-body-l-reg flex flex-col pl-sm",attrs:{"role":"menu"}},_vm._l((_setup.mainRoutes),function(route){return _c(_setup.SidebarItem,{key:route.path,attrs:{"item":route,"popup-top-shift":_setup.HEADER_HEIGHT - 8,"base-path":route.path,"is-collapse":_setup.isCollapse,"is-opened":_setup.isOpened},on:{"toggle":_setup.handleItemToggle}})}),1)]),_c('div',{staticClass:"min-h-20 overflow-hidden border-r border-b border-dark1/100"},[_c('ul',{staticClass:"text-body-l-reg flex flex-col px-sm pt-xs pb-sm",attrs:{"role":"menu"}},_vm._l((_setup.bottomRoutes),function(route){return _c(_setup.SidebarItem,{key:route.path,attrs:{"item":route,"popup-top-shift":-8,"base-path":route.path,"is-collapse":_setup.isCollapse,"is-opened":_setup.isOpened},on:{"toggle":_setup.handleItemToggle}})}),1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }