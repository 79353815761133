//~~legacy~~
const state = () => ({
  glassVariant: null,
});

const actions = {};

const mutations = {
  setGlassVariant: (state, glassVariant) => {
    state.glassVariant = glassVariant;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
