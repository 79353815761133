//~~legacy~~
const initialState = () => ({
  page: "",
  lockTimer: 0,
  otpTimer: 0,
  lastOtpCreationDate: null,
  validatedOtp: null,
});

const state = initialState();

const mutations = {
  SET_PAGE: (state, page) => {
    state.page = page;
  },
  SET_LOCK_TIMER: (state, lockTimer) => {
    state.lockTimer = lockTimer;
  },
  SET_OTP_TIMER: (state, otpTimer) => {
    state.otpTimer = otpTimer;
  },
  SET_LAST_OTP_CREATION_DATE: (state, lastOtpCreationDate) => {
    state.lastOtpCreationDate = lastOtpCreationDate;
  },
  SET_VALIDATED_OTP: (state, validatedOtp) => {
    state.validatedOtp = validatedOtp;
  },
};

const getters = {
  page: (state) => {
    return state.page;
  },
  lockTimer: (state) => {
    return state.lockTimer;
  },
  otpTimer: (state) => {
    return state.otpTimer;
  },
  lastOtpCreationDate: (state) => {
    return state.lastOtpCreationDate;
  },
  validatedOtp: (state) => {
    return state.validatedOtp;
  },
};

const actions = {
  setPage: ({ commit }, page) => {
    commit("SET_PAGE", page);
  },
  setLockTimer: ({ commit }, lockTimer) => {
    commit("SET_LOCK_TIMER", lockTimer);
  },
  setOtpTimer: ({ commit }, otpTimer) => {
    commit("SET_OTP_TIMER", otpTimer);
  },
  setLastOtpCreationDate: ({ commit }, lastOtpCreationDate) => {
    commit("SET_LAST_OTP_CREATION_DATE", lastOtpCreationDate);
  },
  setValidatedOtp: ({ commit }, validatedOtp) => {
    commit("SET_VALIDATED_OTP", validatedOtp);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
