var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"open-on-hover":"","nudge-bottom":"51","disabled":_vm.disabled === undefined || _vm.disabled === false || !_vm.disableText},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g({class:{
            'bb-button': true,
            uppercase: _vm.uppercase === '' || _vm.uppercase === true,
            lowercase: _vm.uppercase === undefined || _vm.uppercase === false,
            plain: _vm.plain === '' || _vm.plain === true,
            icon: _vm.icon != null,
            disabled: _vm.disabled === '' || _vm.disabled === true,
            'disabled-outlined':
              (_vm.disabled === '' || _vm.disabled === true) &&
              (_vm.outlined === '' || _vm.outlined === true)
          },attrs:{"elevation":"0","width":_vm.width || '100%',"plain":_vm.plain === '' || _vm.plain === true,"outlined":_vm.outlined === '' || _vm.outlined === true,"ripple":false,"color":_vm.buttonColor,"icon":_vm.icon != null},on:{"mouseover":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false},"mousedown":function($event){_vm.clicked = true},"mouseup":function($event){_vm.clicked = false},"click":_vm.click}},on),[(_vm.icon)?_c('v-img',{attrs:{"src":require(`../../../assets/${_vm.iconSrc}`),"width":_vm.iconWidth,"height":_vm.iconHeight,"contain":""}}):_vm._e(),_vm._t("default")],2)]}}],null,true)},[_c('div',{staticClass:"disable-text"},[_c('span',[_vm._v(_vm._s(_vm.disableText))])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }