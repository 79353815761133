//~~legacy~~
const state = () => ({
  components: ["select-glass"],
  glass: null,
  glassVariants: null,
  selectedGlassVariant: null,
  lensType: null,
  lens: null,
  readingPower: null,
  singleVisionType: null,
  extraCharge: null,
  multifocalType: null,
  discount: null,
  productType: null,
  transitionColour: null,
  lensTint: null,
  brand: null,
});

const mutations = {
  addComponent: (state, component) => {
    state.components.unshift(component);
  },
  removeLastComponent: state => {
    state.components.shift();
  },
  setGlass: (state, glass) => {
    state.glass = glass;
  },
  setGlassVariants: (state, glassVariants) => {
    state.glassVariants = glassVariants;
  },
  setSelectedGlassVariant: (state, selectedGlassVariant) => {
    state.selectedGlassVariant = selectedGlassVariant;
  },
  setTransitionColour: (state, transitionColour) => {
    state.transitionColour = transitionColour;
  },
  setLensType: (state, lensType) => {
    state.lensType = lensType;
  },
  setLens: (state, lens) => {
    state.lens = lens;
  },
  setLensTint: (state, lensTint) => {
    state.lensTint = lensTint;
  },
  setReadingPower: (state, readingPower) => {
    state.readingPower = readingPower;
  },
  setSingleVisionType: (state, singleVisionType) => {
    state.singleVisionType = singleVisionType;
  },
  setExtraCharge: (state, extraCharge) => {
    state.extraCharge = extraCharge;
  },
  setBrand: (state, brand) => {
    state.brand = brand;
  },
  setMultifocalType: (state, multifocalType) => {
    state.multifocalType = multifocalType;
  },
  setDiscount: (state, discount) => {
    state.discount = discount;
  },
  setProductType: (state, productType) => {
    state.productType = productType;
  },
  resetData: state => {
    state.components = ["select-glass"];
    state.glass = null;
    state.glassVariants = null;
    state.selectedGlassVariant = null;
    state.lensType = null;
    state.lens = null;
    state.readingPower = null;
    state.singleVisionType = null;
    state.extraCharge = null;
    state.multifocalType = null;
    state.discount = null;
    state.productType = null;
    state.transitionColour = null;
    state.lensTint = null;
    state.brand = null;
  }
};

const getters = {
  prices: state => {
    var prices = [];
    if (state.lensType != null) {
      prices.push({
        label: "Lens type",
        option: state.lensType.name,
        price: state.lensType.price,
        type: "none"
      });
    }
    if (state.readingPower != null) {
      prices.push({
        label: "Reading power",
        option: state.readingPower,
        price: 0,
        type: "none"
      });
    }
    if (state.singleVisionType != null) {
      prices.push({
        label: "Single Vision type",
        option: state.singleVisionType.name,
        price: null,
        type: "none"
      });
    }
    if (state.extraCharge != null) {
      prices.push({
        label: "Lens Thickness",
        option: state.extraCharge.name,
        price: state.extraCharge.price,
        type: "increment"
      });
    }
    if (state.brand != null) {
      prices.push({
        label: "Lens Brand",
        option: state.brand.configurationName,
        price: state.brand.price,
        type: "increment"
      });
    }    
    if (state.multifocalType != null) {
      prices.push({
        label: "Multifocal Type",
        option: state.multifocalType.name,
        price: null,
        type: "none"
      });
    }
    if (state.lens != null) {
      prices.push({
        label: "Lens option",
        option: state.lens.name,
        price: state.lens.price,
        type: "increment"
      });
    }
    if (!!state.lensTint) {
      prices.push({
        label: "Lens tint",
        option: state.lensTint.name,
        price: null,
        type: "none"
      });
    }
    if (state.discount != null) {
      prices.push({
        label: "Pay What You Can",
        option: state.discount.option,
        price: state.discount.value,
        type: "decrement"
      });
    }
    return prices;
  },
  fullPrice: (_state, getters) => {
    return getters.prices
      .filter(
        i => i.price != null && (i.type == "increment" || i.type == "none")
      )
      .map(i => i.price)
      .reduce((a, b) => a + b, 0);
  },
  fullDiscount: (_state, getters) => {
    return getters.prices
      .filter(i => i.price != null && i.type == "decrement")
      .map(i => i.price)
      .reduce((a, b) => a + b, 0);
  },
  totalPrice: (_state, getters) => getters.fullPrice - getters.fullDiscount,
  transitionColour: (state) => state.transitionColour,
  selectedGlassesType: (state) => {
    return state.glass?.configuration_name?.includes("-sun")
      ? "sunglasses"
      : "spectacles";
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
