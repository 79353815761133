//~~legacy~~
const initialState = () => ({
  components: []
});

const state = initialState();

const mutations = {
  addComponent: (state, component) => {
    state.components.unshift(component);
  },
  removeLastComponent: state => {
    state.components.shift();
  },
  resetData: state => {
    Object.assign(state, { ...initialState() });
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
