//TODO could be recreated as a store in v3 (now would cause cyclic dependency)
import moment from 'moment-mini';
import { DATE_AT_TIME_DISPLAY_FORMAT } from '~/utils/time';

export type ErrorItem = {
  datetime: number;
  messages: Array<string>;
};

let errorListCache: Array<ErrorItem> = [];

export function addError(errorMessage?: string, errorDetails?: Record<string, Array<string>>) {
  const error: ErrorItem = {
    datetime: Date.now(),
    messages: []
  };
  if (errorMessage) {
    error.messages.push(errorMessage);
  }
  if (errorDetails && typeof errorDetails === 'object') {
    Object.keys(errorDetails).forEach((key) =>
      error.messages.push(
        `${key}: ${Array.isArray(errorDetails[key]) ? errorDetails[key].join('|') : errorDetails[key]}`
      )
    );
  }
  errorListCache.push(error);
}

export function stringifyErrorItem(errorItem: ErrorItem) {
  return `[${moment(errorItem.datetime).format(DATE_AT_TIME_DISPLAY_FORMAT)}]: ` + errorItem.messages.join(', ');
}

export function removeErrors() {
  errorListCache = [];
}

export function getRequestErrorList() {
  return errorListCache;
}
