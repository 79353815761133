<template>
  <div
    class="collection-item light-grey-border"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    @click="$emit('click')"
    :class="{
      disabled: disabled != null && disabled !== false,
      selectable: selectable == null || selectable == true,
      selected: selected != null && selected !== false
    }"
  >
    <div class="content-view">
      <span v-if="title" class="content-view-title">
        {{ title }}
      </span>
      <slot v-else />
    </div>
  </div>
</template>

<script> //~~legacy~~
export default {
  props: ["title", "disabled", "selectable", "selected"],
  data() {
    return {
      hovered: false
    };
  }
};
</script>

<style lang="scss" scoped>
.collection-item {
  background-color: #fff;
  border-radius: 5px;
  min-width: 118px;
  min-height: 84px;
  display: flex;
  .content-view {
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .content-view-title {
      font-family: "Helvetica Neue";
      font-size: 24px;
      line-height: 21px;
      letter-spacing: 0.03em;
      color: #000000;
    }
  }
  .compact-style {
    margin-right: 0;
  }
}
.disabled {
  cursor: not-allowed !important;
  pointer-events: none;
  .content-view {
    opacity: 0.4;
  }
}
.selected {
  border-color: $theme;
  .content-view {
    .content-view-title {
      color: $theme;
    }
  }
}
</style>
