//~~legacy~~

const initialState = () => ({
  components: [],
  user: null,
  order: null,
  orderItems: [],
  reglazingServices: [],
  orderEyeTest: null,
  voucherUsage: null,
  currentPrescriptionOrderItemIndex: 0,
  remainingItemsDelivery: "pick_up",
  addressToModify: {},
  deliveryAddress: null,
  collectionLocation: () => {
    // vgrynch: commented out to avoid warnigs as this part of the code is currently unused
    /* let salesChannel = getSalesChannel();
    if (salesChannel != null) {
      return salesChannel.sales_channel_location_relations.find(
        (item) => item.is_default
      ).location;
    } else {
      return null;
    } */
    return null;
  },
});

const state = initialState();

const mutations = {
  setComponents: (state, components) => {
    state.components = components;
  },
  addComponent: (state, component) => {
    state.components.unshift(component);
  },
  removeLastComponent: (state) => {
    state.components.shift();
  },
  setUser: (state, user) => {
    state.user = user;
  },
  setOrder: (state, order) => {
    state.order = order;
    state.orderEyeTest = order.eye_test;
  },
  setOrderEyeTest: (state, orderEyeTest) => {
    if (orderEyeTest) {
      state.orderEyeTest = { ...orderEyeTest };
    } else {
      state.orderEyeTest = null;
    }
  },
  addOrderItem: (state, orderItem) => {
    state.orderItems.push(orderItem);
  },
  removeOrderItem: (state, index) => {
    state.orderItems.splice(index, 1)[0];
  },
  addReglazingService: (state, reglazingService) => {
    state.reglazingServices.push(reglazingService);
  },
  removeReglazingItem: (state, index) => {
    state.reglazingServices.splice(index, 1);
  },
  clearReglazingItems: (state) => {
    state.reglazingServices = [];
  },
  setOrderItemObject: (state, opts) => {
    state.orderItems[opts.index].object = opts.object;
  },
  setOrderItemEyeTest: (state, opts) => {
    state.orderItems[opts.index].eyeTest = opts.eyeTest;
  },
  setOrderItemsEyeTest: (state, eyeTest) => {
    state.orderItems.forEach((orderItem) => {
      if (orderItem.selectedGlassVariant.lens_variant.requires_prescription) {
        orderItem.eyeTest = eyeTest ? { ...eyeTest } : null;
      }
    });
  },
  copyOrderEyeTestToOrderItems: (state) => {
    let eyeTest = state.orderEyeTest;
    state.orderItems.map((orderItem, index) => {
      if (orderItem.selectedGlassVariant.lens_variant.requires_prescription) {
        var vision_type = null;
        if (
          orderItem.lensType != null &&
          orderItem.lensType.configurationName == "reading-glasses"
        ) {
          vision_type = "RR";
        } else if (orderItem.singleVisionType != null) {
          vision_type = orderItem.singleVisionType.configurationName;
        } else if (orderItem.multifocalType != null) {
          vision_type = orderItem.multifocalType.configurationName;
        }

        var sphere = null;
        if (orderItem.readingPower != null) {
          sphere = orderItem.readingPower;
        }

        var lens_coating = null;
        if (
          orderItem.selectedGlassVariant.lens_variant.lens.colour.name ==
          "Clear"
        ) {
          if (orderItem.lens.configurationName == "classic") {
            lens_coating = "SHMC";
          } else if (orderItem.lens.configurationName == "blue-light") {
            lens_coating = "Blue light";
          } else if (orderItem.lens.configurationName == "light-responsive") {
            lens_coating = "Transitions";
          }
        } else {
          if (orderItem.lens.configurationName == "tinted") {
            lens_coating = `Sun-${orderItem.selectedGlassVariant.lens_variant.lens.colour.name}`;
          } else if (orderItem.lens.configurationName == "polarized") {
            lens_coating = `Polarized-${orderItem.selectedGlassVariant.lens_variant.lens.colour.name}`;
          }
        }

        var orderItemEyeTest;
        if (vision_type == "RR") {
          orderItemEyeTest = {
            prescription: {
              eye_measurements: [
                {
                  eye_position: "right",
                  vision_type,
                  sphere,
                  lens_coating,
                },
                {
                  eye_position: "left",
                  vision_type,
                  sphere,
                  lens_coating,
                },
              ],
            },
          };
        } else if (eyeTest) {
          orderItemEyeTest = {
            media: eyeTest.media,
            exam_date: eyeTest.exam_date,
            patient_date_of_birth: eyeTest.patient_date_of_birth,
            pupillary_distance: eyeTest.pupillary_distance,
            back_vertex_distance: eyeTest.back_vertex_distance,
            optometrist: eyeTest.optometrist,
            is_default: eyeTest.is_default,
            practice_name: eyeTest.practice_name,
            notes: eyeTest.notes,
            prescription: {
              expiry_date: eyeTest.prescription.expiry_date,
              eye_measurements: eyeTest.prescription.eye_measurements.map(
                (item) => {
                  return {
                    vision_type,
                    lens_coating,
                    eye_position: item.eye_position,
                    sphere: item.sphere,
                    cylinder: item.cylinder,
                    axis: item.axis,
                    near_addition: item.near_addition,
                    intermediate_addition: item.intermediate_addition,
                    horizontal_prism_direction: item.horizontal_prism_direction,
                    horizontal_prism_value: item.horizontal_prism_value,
                    vertical_prism_direction: item.vertical_prism_direction,
                    vertical_prism_value: item.vertical_prism_value,
                  };
                }
              ),
            },
          };

          if (!orderItemEyeTest.prescription) {
            orderItemEyeTest.prescription = {
              eye_measurements: [],
            };
          }

          if (orderItemEyeTest.prescription.eye_measurements.length == 0) {
            orderItemEyeTest.prescription.eye_measurements = [
              {
                eye_position: "right",
                vision_type,
                lens_coating,
              },
              {
                eye_position: "left",
                vision_type,
                lens_coating,
              },
            ];
          }
        } else {
          orderItemEyeTest = {
            prescription: {
              eye_measurements: [
                {
                  eye_position: "right",
                  vision_type,
                  lens_coating,
                },
                {
                  eye_position: "left",
                  vision_type,
                  lens_coating,
                },
              ],
            },
          };
        }

        state.orderItems[index].eyeTest = orderItemEyeTest;
      }
    });
  },
  setOrderItemLocationId: (state, opts) => {
    state.orderItems[opts.index].locationId = opts.locationId;
  },
  setOrderItemDeliveryType: (state, opts) => {
    state.orderItems[opts.index].deliveryType = opts.deliveryType;
  },
  setVoucherUsage: (state, voucherUsage) => {
    state.voucherUsage = voucherUsage;
  },
  setCurrentPrescriptionOrderItemIndex: (state, index) => {
    state.currentPrescriptionOrderItemIndex = index;
  },
  increaseCurrentPrescriptionOrderItemIndex: (state) => {
    state.currentPrescriptionOrderItemIndex += 1;
  },
  decreaseCurrentPrescriptionOrderItemIndex: (state) => {
    state.currentPrescriptionOrderItemIndex -= 1;
  },
  setRemainingItemsDelivery: (state, option) => {
    state.remainingItemsDelivery = option;
    state.orderItems.forEach((orderItem) => {
      if (orderItem.locationId == null) {
        orderItem.deliveryType = option;
      }
    });
  },
  setOrderItemsLoationId: (state, selectedItems) => {
    let locationId = state.collectionLocation.id;

    state.orderItems.forEach((orderItem, index) => {
      if (selectedItems[index]) {
        orderItem.deliveryType = "pick_up";
        orderItem.locationId = locationId;
      } else {
        orderItem.locationId = null;
      }
    });
  },
  setAddressToModify: (state, address) => {
    state.addressToModify = address;
  },
  setDeliveryAddress: (state, address) => {
    state.deliveryAddress = address;
  },
  resetData: (state) => {
    Object.assign(state, { ...initialState() });
  },
};

const getters = {
  prescriptionOrderItems: (state) => {
    return state.orderItems.filter((item) => {
      return item.selectedGlassVariant.lens_variant.requires_prescription;
    });
  },
  prescriptionReglazingItems: (state) => {
    return state.reglazingServices.filter((item) => item.requires_prescription);
  },
  readingGlassOrderItems: (state) => {
    return state.orderItems.filter((item) => {
      return (
        item.selectedGlassVariant.lens_variant.requires_prescription &&
        item.readingPower
      );
    });
  },
  readingReglazingItems: (state) => {
    return state.reglazingServices.filter((item) => {
      return (
          item.requires_prescription &&
          item.readingPower
      );
    });
  },
  currentPrescriptionOrderItem: (state, getters) =>
    getters.prescriptionOrderItems[state.currentPrescriptionOrderItemIndex],
  subtotalPrice: (state) => {
    return state.orderItems
      .map((item) => item.totalPrice)
      .reduce((a, b) => a + b, 0);
  },
  taxes: (state) => {
    var goods = 0;
    var dispensing = 0;

    state.orderItems.forEach((item) => {
      if (item.selectedGlassVariant.lens_variant.requires_prescription) {
        let good = ((0.049 * 6) / 1.05) * item.totalPrice;
        goods += good;
        dispensing += item.totalPrice - good;
      } else {
        goods += item.totalPrice;
      }
    });

    return {
      goods,
      dispensing,
    };
  },
  voucherValue: (state, getters) => {
    var voucherValue = 0;
    if (state.voucherUsage != null) {
      switch (state.voucherUsage.voucher.voucher_type) {
        case "fixed_amount":
          voucherValue = state.voucherUsage.voucher.value;
          break;
        case "percentage":
          voucherValue =
            (state.voucherUsage.voucher.value * getters.subtotalPrice) / 100;
          break;
        default:
          break;
      }
    }

    return voucherValue;
  },
  totalPrice: (_state, getters) => {
    return Math.max(getters.subtotalPrice - getters.voucherValue, 0);
  },
  collectionAddress: (state) => {
    if (
      state.order.order_items.find((item) => item.delivery_type == "pick_up") !=
        null &&
      state.collectionLocation() != null
    ) {
      return state.collectionLocation().addresses[0];
    }

    return null;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
