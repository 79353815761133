import Vue from 'vue';
import VCalendar from 'v-calendar';
import Cookies from 'js-cookie';
import 'core-js/actual';

import '../bloobloom-components/styles/tailwind.css'; // tailwindcss

import Element from 'element-ui';
import './styles/element-variables.scss';
import enLang from 'element-ui/lib/locale/lang/en';

import tinymce from 'vue-tinymce-editor';

import '@/styles/index.scss'; // global css
import BbSwitchButton from '@/components/Bloobloom/BbSwitchButton/index.vue';
import BbCheckbox from '@/components/Bloobloom/BbCheckbox/index.vue';
import BbButton from '@/components/Bloobloom/BbButton/index.vue';
import BbBackButton from '@/components/Bloobloom/BbBackButton/index.vue';
import BbXButton from '@/components/Bloobloom/BbXButton/index.vue';
import BbCard from '@/components/Bloobloom/BbCard/index.vue';
import BbListItem from '@/components/Bloobloom/BbListItem/index.vue';
import BbCollectionItem from '@/components/Bloobloom/BbCollectionItem/index.vue';
import BbPageHeader from '@/components/Bloobloom/BbPageHeader/index.vue';
import BbMenu from '@/components/Bloobloom/BbMenu/index.vue';
import BbTitleView from '@/components/Bloobloom/BbTitleView/index.vue';
import BbTextField from '@/components/Bloobloom/BbTextField/index.vue';
import BbSearchTextField from '@/components/Bloobloom/BbSearchTextField/index.vue';
import BbWarningMessage from '@/components/Bloobloom/BbWarningMessage/index.vue';
import BbLoading from '@/components/Bloobloom/BbLoading/index.vue';
import BbDropdown from '@/components/Bloobloom/BbDropdown/index.vue';

import App from './App.vue';
import { store } from './store';
import router from './router';
import '@adyen/adyen-web/dist/adyen.css';

import vuetify from './plugins/vuetify';
import './styles/vuetify.scss';

import confirmationMessage from './plugins/confirmationMessage';

import './icons'; // icon
import './permission'; // permission control

import DateField from './components/DateField.vue';

Vue.use(VCalendar, {
  componentPrefix: 'vc'
});

Vue.use(confirmationMessage);

Vue.component('DateField', DateField);
Vue.component('tinymce', tinymce);
Vue.use(Element, {
  size: Cookies.get('size') || 'medium',
  locale: enLang
});

Vue.config.productionTip = false;

Vue.component('BbSwitchButton', BbSwitchButton);
Vue.component('BbCheckbox', BbCheckbox);
Vue.component('BbButton', BbButton);
Vue.component('BbBackButton', BbBackButton);
Vue.component('BbXButton', BbXButton);
Vue.component('BbCard', BbCard);
Vue.component('BbListItem', BbListItem);
Vue.component('BbCollectionItem', BbCollectionItem);
Vue.component('BbPageHeader', BbPageHeader);
Vue.component('BbMenu', BbMenu);
Vue.component('BbTitleView', BbTitleView);
Vue.component('BbTextField', BbTextField);
Vue.component('BbSearchTextField', BbSearchTextField);
Vue.component('BbWarningMessage', BbWarningMessage);
Vue.component('BbLoading', BbLoading);
Vue.component('BbDropdown', BbDropdown);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
