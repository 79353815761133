//~~legacy~~
const state = () => ({
  components: ["add-prescription"],
  glass: null,
});

const mutations = {
  addComponent: (state, component) => {
    state.components.unshift(component);
  },
  removeLastComponent: state => {
    state.components.shift();
  },
  setGlass: (state, glass) => {
    state.glass = glass;
  },
  resetData: state => {
    state.components = ["add-prescription"];
    state.glass = null;
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
