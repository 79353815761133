// eslint-disable-next-line import/named
import { createFocusTrap, FocusTrap } from 'focus-trap';
import { reactive, ref } from 'vue';
import { isPOSApplication, isWebApplication } from '~/utils/application';

const APP_ROOT_CONTAINER = 'app-container';

let preventCount = 0;
let prevScrollPos = 0;
let openedModalsWithFocusListeners: HTMLElement[] = [];

// We need to do this outside of the modal for case one modal opens another
// to track it all in one place. For that, we also track a count
// of how many times show/hide was called
export const preventMainScrolling = (show: boolean) => {
  const isWeb = isWebApplication();
  const element = isWeb ? window.document.body : document.querySelector('.app-wrapper');

  if (show) {
    preventCount++;
    if (preventCount === 1) {
      if (isWeb) {
        const sbWidth = window.innerWidth - window.document.body.offsetWidth;
        document.documentElement.style.setProperty('--sb-width', `${sbWidth}px`);
        window.document.body.classList.add('noScroll');
      } else {
        prevScrollPos = document.documentElement.scrollTop;
        if (element) {
          element.classList.add('!fixed');
        }
        document.documentElement.style.marginTop = `-${prevScrollPos}px`;
      }
    }
  } else if (preventCount > 0) {
    preventCount--;
    if (!preventCount) {
      if (isWeb) {
        document.documentElement.style.setProperty('--sb-width', '0px');
        window.document.body.classList.remove('noScroll');
      } else {
        if (element) {
          element.classList.remove('!fixed');
        }
        document.documentElement.style.marginTop = '';
        document.documentElement.scrollTop = prevScrollPos;
      }
    }
  }
};

// mount modal at the root level and make sure ARIA screen readers skip main screen and
// read only the modal
// NOTE: make sure component controls element removal by v-if!!
let insertedModals: Array<HTMLElement> = [];
const mountForScreenReaders = (show: boolean, modal: HTMLElement) => {
  const rootElem = document.getElementById(APP_ROOT_CONTAINER);
  rootElem?.setAttribute('aria-hidden', `${show}`);
  if (show) {
    rootElem?.parentElement?.appendChild(modal);
    insertedModals.push(modal);
  } else {
    modal.parentNode?.removeChild(modal);
    if (insertedModals.indexOf(modal) > -1) {
      insertedModals.splice(insertedModals.indexOf(modal), 1);
    }
  }
};

// Used to make sure whenever user changes focus, next focusable element is inside the specific container.
// TODO: add focus trapping using the focus-trap library for Admin/POS, like we did for Web
const preventFocusOut = (target: HTMLElement, container: HTMLElement) => {
  let parentElem: HTMLElement | null | undefined = target?.parentElement;
  while (parentElem && parentElem !== container) {
    parentElem = parentElem.parentElement;
  }
  if (!parentElem) {
    container.focus();
  }
};

export const handleModal = (
  show: boolean,
  modal: HTMLElement | null | undefined,
  escapeHandler?: () => void,
  nested: boolean = false
) => {
  if (!window || !modal) {
    // skip if server-side rendering or tests or modal not yet initialized
    return;
  }

  if (!nested) {
    preventMainScrolling(show);
  }

  if (show) {
    if (!nested) {
      // make sure we have focus inside the modal initially
      preventFocusOut(document.activeElement as HTMLElement, modal);
    }

    // handle Escape key to close modal
    // Per feedback in FE-1777, we disallow closing by ESC key or tap/click outside the modals
    // const handleEscape = (event: KeyboardEvent) => event.key === 'Escape' && escapeHandler && escapeHandler();
    if (!nested) {
      // handle focus in/out events to keep focus inside the modal
      const handleFocusOut = (event: FocusEvent) => preventFocusOut(event.relatedTarget as HTMLElement, modal);
      const handleFocusIn = (event: FocusEvent) => preventFocusOut(event.target as HTMLElement, modal);
      // store handles inside the modal component for later removal. Old-fashioned, but works :)
      (<any>modal).handleFocusOut = handleFocusOut;
      (<any>modal).handleFocusIn = handleFocusIn;

      window.addEventListener('focusout', handleFocusOut);
      window.addEventListener('focusin', handleFocusIn);
      if (isPOSApplication()) {
        openedModalsWithFocusListeners.push(modal);
      }
    } else if (isPOSApplication()) {
      // remove all listeners that are open under our main modal
      openedModalsWithFocusListeners.forEach((element) => {
        window.removeEventListener('focusout', (<any>element).handleFocusOut);
        window.removeEventListener('focusin', (<any>element).handleFocusIn);
      });
    }

    // store handles inside the modal component for later removal. Old-fashioned, but works :)
    // (<any>modal).handleEscape = handleEscape;

    // window.addEventListener('keyup', handleEscape);
  } else if ((<any>modal).handleFocusOut) {
    // window.removeEventListener('keyup', (<any>modal).handleEscape);
    if (!nested) {
      window.removeEventListener('focusout', (<any>modal).handleFocusOut);
      window.removeEventListener('focusin', (<any>modal).handleFocusIn);
    } else if (openedModalsWithFocusListeners.length && isPOSApplication()) {
      // add back all listeners for opened modals under our main modal when it closed. Clear array of openedModals
      openedModalsWithFocusListeners.forEach((element) => {
        window.addEventListener('focusout', (<any>element).handleFocusOut);
        window.addEventListener('focusin', (<any>element).handleFocusIn);
      });
      openedModalsWithFocusListeners = [];
    }
  }

  // need timeout for below because sometimes we can have this called before component is actually mounted
  setTimeout(() => mountForScreenReaders(show, modal), 10);
};

let openedModals: Array<HTMLElement> = [];
export const modalOpened = (show: boolean, modal: HTMLElement | null | undefined) => {
  if (modal) {
    if (show) {
      // this is a failsafe for a POS connectivity modal issue (possibly would fix other similar issues if they exist)
      // when we opened and closed the menu the next instance of the modal did not have a parent node and did not appear
      // in such cases we append the modal to <main>
      const main = document.querySelector('main');
      if (!modal.parentNode && main) {
        main.append(modal);
      }
      openedModals.push(modal);
    } else if (openedModals.indexOf(modal) > -1) {
      openedModals.splice(openedModals.indexOf(modal), 1);
    }
  }
};

export const useModals = (modal: HTMLElement | null | undefined) => {
  const state = ref(openedModals);
  return reactive({
    isTopmost: state.value.length && modal === state.value[state.value.length - 1]
  });
};

export const resetModals = () => {
  insertedModals.forEach((modal) => {
    modal.parentNode?.removeChild(modal);

    if ((<any>modal).handleFocusOut) {
      // window.removeEventListener('keyup', (<any>modal).handleEscape);
      window.removeEventListener('focusout', (<any>modal).handleFocusOut);
      window.removeEventListener('focusin', (<any>modal).handleFocusIn);
    }
  });
  insertedModals = [];
  openedModals = [];

  Object.values(focusTraps).forEach((focusTrap) => {
    focusTrap.deactivate();
  });
  modals = {};
  focusTraps = {};
  modalsStack = [];

  preventCount = 1;
  prevScrollPos = 0;
  preventMainScrolling(false);
};

interface WebModalEntry {
  open: (container?: HTMLElement) => void;
  captureFocus: (container?: HTMLElement) => void;
  close: (_?: any, __?: any, next?: () => void) => void;
}

let modals: Record<number, boolean> = {};
let focusTraps: Record<number, FocusTrap> = {};
let modalsStack: number[] = [];

// WEB version, returns object to open and close modal.
// manages duplicate closing, multiple opened modals, focus trapping (of pass modal's container HTML element)
// Focus trapping rules:
// - At least 1 focusable item should be in the HTML DOM at the moment when modal.open(container) is called
// - when opening child modals, they all must use useModal to at least disable focus-trapping of the parent modal
// TODO: Add support of auto-teleporting to the root, like we have for Admin/POS (see mountForScreenReaders)
export const useModal = (): WebModalEntry => {
  const modalId = new Date().getTime();
  return {
    open: (container?: HTMLElement) => {
      if (!modals[modalId]) {
        if (modalsStack.length) {
          const topModal = modalsStack[modalsStack.length - 1];
          if (focusTraps[topModal]) {
            // deactivate focus trap if it was activated for the modal below
            focusTraps[topModal].deactivate();
          }
        }
        preventMainScrolling(true);
        modals[modalId] = true;
        modalsStack.push(modalId);
        if (container) {
          const trap = createFocusTrap(container, { initialFocus: false });
          focusTraps[modalId] = trap;
          trap.activate();
        }
      }
    },
    captureFocus: (container?: HTMLElement) => {
      if (container && !focusTraps[modalId]) {
        const trap = createFocusTrap(container, { initialFocus: false });
        focusTraps[modalId] = trap;
        trap.activate();
      }
    },
    close: (_?: any, __?: any, next?: () => void) => {
      if (modals[modalId]) {
        preventMainScrolling(false);
        delete modals[modalId];
        // do not use "pop", because if multiple modals closed at once, we will risk break things if they close not in the opening order
        modalsStack = modalsStack.filter((id) => id !== modalId);
        if (focusTraps[modalId]) {
          focusTraps[modalId].deactivate();
          delete focusTraps[modalId];
        }
        if (modalsStack.length) {
          const topModal = modalsStack[modalsStack.length - 1];
          if (focusTraps[topModal]) {
            // re-activate focus trap
            focusTraps[topModal].activate();
          }
        }
      }
      if (next) {
        next();
      }
    }
  };
};
