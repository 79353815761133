import moment from 'moment-mini';
import { ISODate, ISODateTime } from '~/api/schema/common';
import { ISODateFormat } from '~/api/schema/commonConstants';

const LONDON_TIMEZONE = '+0100';
const LESS_THAN_MINUTE = 59999;

export const INVALID_DATE = 'Invalid date';
export const MONTH_AND_YEAR_FORMAT = 'MMMM YYYY';
export const FULL_YEAR = 'YYYY';
export const FULL_MONTH_NAME = 'MMMM';
export const DAY_SLASH_MONTH = 'DD/MM';
export const SHORT_DAY_NAME_AND_DAY_NUMBER = 'ddd DD';
export const TIME_DISPLAY_FORMAT = 'HH:mm';
export const DATE_AT_TIME_DISPLAY_FORMAT = 'DD/MM/YYYY [at] HH:mm';
export const DATE_DISPLAY_FORMAT = 'DD/MM/YYYY';
export const CUT_DATE_DISPLAY_FORMAT = 'DD MMM YYYY';
export const FULL_DATE_DISPLAY_FORMAT = 'DD MMMM YYYY';

export const formattedTimeFromNow = (creationDate?: string): string => {
  if (creationDate) {
    return moment().diff(creationDate) <= LESS_THAN_MINUTE ? 'Just Now' : moment(creationDate).fromNow();
  }
  return '';
};

export function formatISODate(date?: ISODateTime | ISODate | Date): string {
  return moment(date).format(ISODateFormat);
}

export function formatDate(date?: ISODateTime | ISODate | Date): string {
  return moment(date).format(DATE_DISPLAY_FORMAT);
}

export function formatDateTime(date: ISODateTime | Date): string {
  return moment(date).format(DATE_AT_TIME_DISPLAY_FORMAT);
}

export function formatDateTimeByZone(date: ISODateTime | Date, timezone = LONDON_TIMEZONE): string {
  return moment(date).zone(timezone).format(DATE_AT_TIME_DISPLAY_FORMAT);
}

export function formatFullDate(date?: ISODateTime | Date): string {
  return moment(date).format(FULL_DATE_DISPLAY_FORMAT);
}

/**
 * @param datetime
 * @param modifier -> ISO 8601 duration format
 */
export function modifyAndFormatDateTime(datetime: ISODateTime | Date, modifier?: string): string {
  const date = moment(datetime);
  if (modifier) {
    date.add(modifier);
  }
  return date.format(DATE_AT_TIME_DISPLAY_FORMAT);
}

export function compareTimes(firstTime: string = '00:00', secondTime: string = '00:00'): boolean {
  const [firstHours, firstMinutes] = firstTime.split(':').map(Number);
  const [secondHours, secondMinutes] = secondTime.split(':').map(Number);
  const firstTotalMinutes = firstHours * 60 + firstMinutes;
  const secondTotalMinutes = secondHours * 60 + secondMinutes;

  return secondTotalMinutes > firstTotalMinutes;
}
