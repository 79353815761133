//~~legacy~~
const state = () => ({
  frame: null,
  frameVariant: null,
});

const actions = {};

const mutations = {
  setFrame: (state, frame) => {
    state.frame = frame;
  },
  setFrameVariant: (state, frameVariant) => {
    state.frameVariant = frameVariant;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
