import { StatusCodes } from 'http-status-codes';
import { ISODateTime, RequestQueryFilters } from '~/api/schema/common';
import { PlatformType } from '~/utils/application';
import { Administrator } from './account';
import { SalesChannel } from './payment';

export const UNIQUE_DEVICE_NAME_ERROR = {
  status: StatusCodes.UNPROCESSABLE_ENTITY,
  message: 'Name must be unique among active tokens'
};

export const REQUIRED_SALES_CHANNEL_ID_ERROR = {
  message: 'Sales Channel ID must be present in headers',
  status: StatusCodes.UNPROCESSABLE_ENTITY
};

export const DEVICE_INACTIVE_ERROR = {
  message: 'Your device is inactive',
  status: StatusCodes.UNPROCESSABLE_ENTITY
};

export const DEVICE_LOCKED_ERROR = {
  message: 'Your device has been locked',
  status: StatusCodes.UNPROCESSABLE_ENTITY
};

export const DEVICE_TOKEN_NOT_FOUND_ERROR = {
  message: 'Device token not found',
  status: StatusCodes.NOT_FOUND
};

export enum DeviceAuthenticationStatuses {
  APPROVED = 'approved',
  EXPIRED = 'expired',
  PENDING = 'pending'
}

export enum DeviceTokenStatuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  EXPIRED = 'expired',
  LOCKED = 'locked'
}

export enum DeviceAuthenticationEventTypes {
  DEVICE_AUTHENTICATION_REQUEST = 'device_authentication_request',
  UNLOCK_REQUEST = 'unlock_request'
}
export interface DeviceAuthenticationResponse {
  request_id: number;
  status: DeviceAuthenticationStatuses;
  device_token_name?: string | null;
  approved_at?: ISODateTime | null;
  approved_by_administrator?: {
    id: number;
    first_name: string;
    last_name: string;
  } | null;
}

export interface DeviceStatusRequest {
  request_token: string;
  admin_token: string;
}

export interface UnlockDeviceRequest extends DeviceStatusRequest {
  device: string;
  device_token_name?: string | null;
}

export interface DeviceToken {
  token: string;
  name: string;
  status: DeviceTokenStatuses;
}

export interface CreateDeviceTokenRequest {
  device_token_request: {
    token: string;
    event_type: DeviceAuthenticationEventTypes;
    details: {
      some_key?: string;
      another_key?: string;
    };
    email?: string;
  };
}

export interface CreateDeviceTokenResponse {
  device_token_request: {
    id: number;
    status: DeviceAuthenticationStatuses;
  };
  device_token: DeviceToken;
}

export interface DeviceMetaInfo {
  total_count: number;
  active_count: number;
  inactive_count: number;
  locked_count: number;
}

export interface DeviceTokenDetails {
  id: number;
  name: string;
  status: DeviceTokenStatuses;
  attempts_counter: number;
  platform: PlatformType;
  browser: string;
  approved_at?: ISODateTime | null;
  created_at: ISODateTime;
  locked_at?: ISODateTime | null;
  inactivated_at?: ISODateTime | null;
  last_used_at?: ISODateTime | null;
  sales_channel?: SalesChannel | null;
  requested_by_admin?: Administrator | null;
  approved_by_admin?: Administrator | null;
}

export type FetchDeviceTokensListParams = RequestQueryFilters<{
  query?: string;
  platforms?: string[];
  included_statuses?: string[];
  excluded_statuses?: string[];
}>;

export interface UpdateDeviceTokenParams {
  device_token: {
    name?: string;
    status?: DeviceTokenStatuses;
  };
}

export interface DeviceTokenList {
  device_tokens: DeviceTokenDetails[];
  meta: DeviceMetaInfo;
}
