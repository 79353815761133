import { AxiosPromise } from 'axios';
import {
  DeviceToken,
  DeviceTokenDetails,
  DeviceTokenList,
  FetchDeviceTokensListParams,
  UpdateDeviceTokenParams
} from './schema/deviceAuthentication';
import request, { prepareParams, requestOrCache } from './utils/request';

export const getDeviceTokenStatus = (params: { token: string }): AxiosPromise<DeviceToken> => {
  return request({
    method: 'get',
    url: `/device_tokens`,
    params
  });
};

export const getDeviceTokensList = (params: FetchDeviceTokensListParams): AxiosPromise<DeviceTokenList> => {
  return requestOrCache({
    method: 'get',
    url: `/device_tokens/list?${prepareParams(params)}`
  });
};

export const updateDeviceToken = (
  tokenId: number | string,
  params: UpdateDeviceTokenParams
): AxiosPromise<{ device_token: DeviceTokenDetails }> => {
  return request({
    method: 'put',
    url: `/device_tokens/${tokenId}`,
    data: params
  });
};
