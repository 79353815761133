//~~legacy~~
const state = () => ({
  fulfillment: null,
  selectedHomeTrialStatus: null,
});

const actions = {};

const mutations = {
  setFulfillment: (state, fulfillment) => {
    state.fulfillment = fulfillment;
  },
  setSelectedHomeTrialStatus: (state, status) => {
    state.selectedHomeTrialStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
