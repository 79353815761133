<template>
  <div
    class="list-item"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    @click="$emit('click')"
    :class="{
      'list-item-disabled': disabled != null && disabled !== false,
      selectable: selectable == null || selectable == true,
      'grey-border-bottom': greyBorder != null && disabled !== false,
      'light-grey-border-bottom':
        greyBorder === undefined || greyBorder === false
    }"
  >
    <div class="content-view">
      <span v-if="title" class="content-view-title">
        {{ title }}
      </span>
      <slot v-else />
    </div>
    <div
      v-if="arrow != null"
      class="arrow-container"
      v-bind:class="{ 'compact-style': compactStyle != null }"
    >
      <img :src="require(`../../../assets/${iconSrc}`)" />
    </div>
  </div>
</template>

<script> //~~legacy~~
export default {
  props: [
    "title",
    "arrow",
    "compactStyle",
    "disabled",
    "selectable",
    "greyBorder"
  ],
  data() {
    return {
      hovered: false
    };
  },
  computed: {
    iconSrc() {
      if (this.arrow == "grey") {
        if (this.hovered) {
          return "list-item-arrow-grey-hover.svg";
        } else {
          return "list-item-arrow-grey.svg";
        }
      } else if (this.arrow == "blue") {
        return "list-item-arrow-blue.svg";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-item {
  background-color: #fff;
  width: 100%;
  min-height: 60px;
  display: flex;
  &:last-child {
    border-bottom: none;
  }
  .content-view {
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    .content-view-title {
      font-family: "Helvetica Neue";
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.03em;
      color: #000000;
      margin-left: 33px;
    }
  }
  .arrow-container {
    align-items: center;
    display: flex;
    margin-left: 31px;
    margin-right: 31px;
    img {
      width: 9px;
      height: 13px;
    }
  }
  .compact-style {
    margin-right: 0;
  }
}
.list-item-disabled {
  cursor: not-allowed !important;
  pointer-events: none;
  .content-view,
  .arrow-container {
    opacity: 0.4;
  }
}
</style>
